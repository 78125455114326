import { MobileContext } from "@/context"
import { LoadingContext } from "@/context/tools/loading"
import { types } from "@/types"
import { handleKeyRandom } from "@/utils/functions"
import IconsSVG from "@/utils/icons/IconsSVG"
import Link from "next/link"
import { useRouter } from "next/navigation"
import {
  useContext, useId,
  useMemo
} from "react"

const MenuItem = ({
  click, iconName, iconPath, h1, colorScheme, iconSize
}) => (
  <div style={{
    backgroundColor: colorScheme?.background ?? `white`,
    color: colorScheme?.text ?? `black`,
  }} className="flex flex-col justify-center items-center border border-neutral-200 p-2 cursor-pointer text-base font-medium"
  onClick={click}>
    <IconsSVG name={iconName} path={iconPath} size={iconSize} fillcolor={colorScheme?.text} />
    <p className="text-center">{h1}</p>
  </div>
)
export const MobileBody = ({ ...props }) => {
  const { handleOpenCategory, menu, texts, hostname: { webPages, styles: { mobileIconSize }}} = props
  const main_layout = useMemo(() => texts.main_layout, [texts])
  const itemId = useId()
  const { toggleOpenSwipMobile } = useContext(MobileContext)
  const router = useRouter()
  const { modules } = useContext(LoadingContext)
  const showStaticMenu = !!webPages.find(x => x.name === `staticMenu`)?.show
  const handleClickGo = (e, { href, type }) => {
    e.preventDefault()
    switch (type) {
    case types.nav.b2b:
      router.push(`/`)
      toggleOpenSwipMobile(false)
      break

    default:
      modules.handleLinkLoading(true)
      router.push(href)
      toggleOpenSwipMobile(false)
      break
    }
  }
  return !!main_layout && (
    <>
      {menu?.map((x, i) => (
        <MenuItem
          key={handleKeyRandom(itemId, i)}
          colorScheme={x.info.colorScheme?.menu}
          iconName={x.info.special ? types.events.special : types.nav.dynamic}
          iconPath={x?.icon ?? ``}
          h1={x.info.h1}
          click={e => x.info.children ? handleOpenCategory(x.info, i) : handleClickGo(e, { href: x.info.url })}
          iconSize = {mobileIconSize}
        />
      ))}
      {showStaticMenu && main_layout?.static_menu.map((staticMenu, i) => (

        // <Link prefetch={false} key={handleKeyRandom(itemId, i)} href={staticMenu.url} passHref>
        <MenuItem
          key={handleKeyRandom(itemId, i)}
          iconPath={staticMenu.icon.replace(`/assets`, ``) || ``}
          h1={staticMenu.text || ``}
          click={async() => {
            await toggleOpenSwipMobile(false)
            await modules.handleLinkLoading(true)
            await router.push(staticMenu.url)
          }}
          iconSize = {mobileIconSize}
        />

        // </Link>
      ))}
    </>
  )
}
