'use client'
import { useReducer } from "react"
import { types } from "@/types"
import { LoadingContext, loadingReducer } from "."
import { initObj } from "./Obj"
import { usePathname } from "next/navigation"

export const LoadingProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(loadingReducer, initObj)
  const pathname = usePathname()

  const handleLinkLoading = props => {
    dispatch({ type: types.tools.linkLoading, payload: props })
  }
  const handleLinkLoadingPath = props => {
    if (pathname !== props) {
      dispatch({ type: types.tools.linkLoading, payload: true })
    }
  }
  return (<LoadingContext.Provider
    value={{
      tools: { ...state },
      modules: {
        handleLinkLoading,
        handleLinkLoadingPath
      },
    }}>
    { children }
  </LoadingContext.Provider>)
}
