/* eslint-disable max-lines */

import { fontQuicksand } from "@/utils/global"
import { colors as muicolor } from "@mui/material"

// export const example = (ownerState, theme, colors, constants) => ()
export const searchBar = (
  ownerState, theme, colors, constants
) => ({
  "& .MuiOutlinedInput-root": {
    // width: ownerState.ismobile === `true` ? `100vw` : `60%`,
    marginLeft: `auto`,
    marginRight: `35px`,
    backgroundColor: colors.white,
    "& .svg-class-search": {
      height: `20px`,
      "& .st0": {
        fill: colors.main,
      },
    },
    borderRadius: ownerState.ismobile === `true` ? 0 : 50,
    borderBottom: ownerState.ismobile === `true` ? `1px solid ${colors.lightGrey2}` : 0,
    "& input": {
      color: colors.secondary,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(14),
      padding: theme.spacing(
        0.8, 1, 0.8, 3.5
      ),
    },
    "& fieldset": {
      border: 0,
    },
    "&:hover fieldset": {
      border: 0,
    },
    "&:hover input": {
      color: colors.secondary,
    },
    "&.Mui-focused fieldset": {
      border: 0,
    },
    "&.Mui-focused input": {
      color: colors.secondary,
    },
  },
})
export const searchBarModal = (
  ownerState, theme, colors, constants
) => ({
  "& .MuiInputBase-root": {
    width: `100%`,
    marginLeft: `auto`,
    marginRight: `auto`,
    backgroundColor: colors.white,
    "& .svg-class-search": {
      height: `20px`,
      "& .st0": {
        fill: colors.main,
      },
    },
    borderRadius: 50,
    borderBottom: ownerState.ismobile === `true` ? `1px solid ${colors.lightGrey2}` : 0,
    "&::after": {
      border: `1px solid ${colors.mainImportant} `,
    },
    "& input": {
      color: colors.secondary,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(14),
      padding: theme.spacing(
        0.8, 1, 0.8, 1
      ),
    },
    "& fieldset": {
      border: `1px solid ${colors.mainImportant} `,
    },
    "&:hover fieldset": {
      border: `1px solid ${colors.mainImportant} `,
    },
    "&:hover input": {
      color: colors.secondary,
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${colors.mainImportant} `,
    },
    "&.Mui-focused input": {
      color: colors.secondary,
    },
  },
  "& .MuiOutlinedInput-root": {
    width: `100%`,
    marginLeft: `auto`,
    marginRight: `35px`,
    backgroundColor: colors.white,
    "& .svg-class-search": {
      height: `20px`,
      "& .st0": {
        fill: colors.main,
      },
    },
    borderRadius: 50,
    borderBottom: ownerState.ismobile === `true` ? `1px solid ${colors.lightGrey2}` : 0,
    "&:after": {
      border: `1px solid ${colors.mainImportant} `,
    },
    "& input": {
      color: colors.secondary,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(14),
      padding: theme.spacing(
        0.8, 1, 0.8, 3.5
      ),
    },
    "& fieldset": {
      border: `1px solid ${colors.mainImportant} `,
    },
    "&:hover fieldset": {
      border: `1px solid ${colors.mainImportant} `,
    },
    "&:hover input": {
      color: colors.secondary,
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${colors.mainImportant} `,
    },
    "&.Mui-focused input": {
      color: colors.secondary,
    },
  },
})
export const searchBarBrand = (
  ownerState, theme, colors, constants
) => ({
  "& .MuiOutlinedInput-root": {
    width: `100%`,
    backgroundColor: colors.white,
    "& .svg-class-search": {
      height: `20px`,
      "& .st0": {
        fill: colors.main,
      },
    },
    borderRadius: ownerState.ismobile === `true` && ownerState.sticky === `true` ? 0 : 50,
    borderBottom: ownerState.ismobile === `true` ? `1px solid ${colors.lightGrey2}` : 0,
    "& input": {
      color: colors.secondary,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(14),
      padding: theme.spacing(
        0.8, 1, 0.8, 3.5
      ),
    },
    "& fieldset": {
      border: `1px solid ${colors.main}`,
    },
    "&:hover fieldset": {
      border: `1px solid ${colors.main}`,
    },
    "&:hover input": {
      color: colors.secondary,
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${colors.main}`,
    },
    "&.Mui-focused input": {
      color: colors.secondary,
    },
  },
})
export const listPopper = (
  ownerState, theme, colors, constants
) => ({
  display: `flex`,
  alignItems: `center`,
  margin: theme.spacing(1.5, 0),
  "& .MuiInputBase-root": {
    width: `95%`,
    fontSize: theme.typography.pxToRem(15),
    "& input": {
      padding: theme.spacing(
        0, 0.5, 0, 0.5
      ),
    },
    "&:hover:before": {
      borderColor: colors.lightGrey3,
      borderBottom: `1px solid !important`,
    },
    "&::before": {
      borderColor: colors.lightGrey3,
      borderBottom: `1px solid`,
    },
    "&::after": {
      borderColor: colors.lightGrey3,
      borderBottom: `1px solid`,
    },
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: `inherit`,
    borderColor: colors.lightGrey3,
    border: `1px solid`,
    borderRadius: ownerState.ismobile === `true` ? 0 : 50,
    width: `145px`,
    "& input": {
      color: colors.color,
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(14),
      padding: theme.spacing(0.25, 1.5),
    },
    "& fieldset": {
      border: 10,
    },
    "&:hover fieldset": {
      border: 10,
    },
    "&:hover input": {
      color: colors.color,
    },
    "&.Mui-focused fieldset": {
      border: 1,
    },
    "&.Mui-focused input": {
      color: colors.color,
    },
  },
})
export const formInputs = (
  ownerState, theme, colors, constants
) => ({
  width: `100%`,
  minHeight: ownerState.mh || 35,
  "&.error .MuiOutlinedInput-root": {
    boxShadow: `0px 0px 6px -1px ${muicolor.red[`A400`]} !important`,
  },
  "&.error svg": {
    color: `${muicolor.red[`A400`]} !important`,
  },
  "& .MuiInputLabel-root": {
    backgroundColor: colors.white,
    padding: theme.spacing(0, 2),
    borderRadius: 50,
    fontSize: theme.typography.pxToRem(14),
    marginLeft: theme.spacing(2),
  },
  "& .MuiOutlinedInput-root": {
    width: `100%`,
    backgroundColor: ownerState.bgc || colors.lightGrey,
    borderRadius: 50,
    boder: ownerState.dispass === `true` ? `1px solid ${muicolor.red[`A400`]}` : 0,
    "& input": {
      color: colors.color,
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(15),
      padding: theme.spacing(0.45, ownerState.mobile === `true` ? 1.5 : 0.5),
      textAlign: ownerState.align || `center`,
      minHeight: 35,
    },
    "& .icon-form": {
      width: 24,
      height: 24,
      color: colors.secondary,
      "&.pass": {
        // color: `${ownerState.dispass === `true` ? muicolor.red[`A400`] : colors.secondary}!important`,
      },
    },
    "& .icon-send": {
      color: colors.white,
    },
    "& fieldset": {
      border: 10,
    },
    "&:hover fieldset": {
      border: 10,
    },
    "&:hover input": {
      color: colors.color,
    },
    "&.Mui-focused fieldset": {
      border: 10,
    },
    "&.Mui-focused input": {
      color: colors.color,
    },
  },
})
export const contactField = (
  ownerState, theme, colors, constants
) => ({
  minHeight: 0,
  marginTop: `0 !important`,
  backgroundColor: colors.white,
  padding: 15,
  borderRadius: 25,
  "& .MuiInput-input": {
    fontFamily: fontQuicksand.style.fontFamily,
    fontWeight: 700,
  },
  "& .MuiFormHelperText-root": {
    fontFamily: fontQuicksand.style.fontFamily,
    fontWeight: 700,
  },
  "& label": {
    fontSize: `14px`,
  },
  "& .MuiInput-underline": {
    marginTop: `0 !important`,
    borderBottom: `none !important`,
  },
  "& .MuiInput-underline:before": {
    borderBottom: `none !important`,
  },
  "& .MuiInput-underline:hover": {
    borderBottom: `none !important`,
  },
  "& .MuiInputLabel-animated": {
    fontFamily: fontQuicksand.style.fontFamily,
    fontWeight: 700,
    color: colors.lightGrey3,
    transform: `translate(15px,20px) scale(1)`,
  },
  "& .MuiInputLabel-shrink": {
    fontFamily: fontQuicksand.style.fontFamily,
    fontWeight: 700,
    color: colors.darkGrey2,
    transform: `translate(15px,4px) scale(0.9)`,
  },
})
export const unitCounter = (
  ownerState, theme, colors, constants
) => ({
  "& .MuiOutlinedInput-root": {
    width: `100%`,
    backgroundColor: `transparent`,
    borderRadius: ownerState.ismobile === `true` ? 0 : 50,
    "& input": {
      color: colors.color,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
      padding: theme.spacing(0.25, 1.5),
      textAlign: `center`,
    },
    "& fieldset": {
      border: 10,
    },
    "&:hover fieldset": {
      border: 10,
    },
    "&:hover input": {
      color: colors.color,
    },
    "&.Mui-focused fieldset": {
      border: 10,
    },
    "&.Mui-focused input": {
      color: colors.color,
    },
  },
})
export const accForm = (
  ownerState, theme, colors, constants
) => ({
  minWidth: ownerState.minwdth || `300px`,
  minHeight: 0,
  "& .MuiOutlinedInput-root": {
    minWidth: ownerState.minwdth || `300px`,
    backgroundColor: colors.lightGrey,
    borderRadius: 50,
    "& input": {
      color: colors.color,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
      padding: theme.spacing(0.45, 1.5),
      textAlign: `left`,
      minHeight: 0,
    },
    "& .icon-form": {
      width: 24,
      height: 24,
      color: colors.secondary,
    },
    "& .icon-send": {
      color: colors.white,
    },
    "& fieldset": {
      border: 10,
    },
    "&:hover fieldset": {
      border: 10,
    },
    "&:hover input": {
      color: colors.color,
    },
    "&.Mui-focused fieldset": {
      border: 10,
    },
    "&.Mui-focused input": {
      color: colors.color,
    },
  },
  "& .MuiFormHelperText-root": {
    fontFamily: `Quicksand`,
  },
  "& label": {
    fontSize: `14px`,
  },
  "& .MuiInput-underline": {
    borderBottom: `none !important`,
  },
  "& .MuiInput-underline:before": {
    borderBottom: `none !important`,
  },
  "& .MuiInput-underline:hover": {
    borderBottom: `none !important`,
  },
  "& .MuiInput-underline:after": {
    // borderBottom: `none !important`,
  },
})
