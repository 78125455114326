'use client'
import { useMediaQuerys } from "@/hooks/utils/useMediaQuerys"
import { handleKeyRandom } from "@/utils/functions"
import parse from "html-react-parser"
import Link from "next/link"
import {
  Suspense,
  useContext,
  useEffect, useId, useMemo, useState
} from "react"
import { DropDownArrows } from "../../../basket/subcomponent/components/subcomponents/DropDownArrows"
import { ToolsContext } from "@/context/tools/tools"
import { LoadingContext } from "@/context/tools/loading"

export const Information = ({ colors, host }) => {
  const { tools: { texts }} = useContext(ToolsContext)
  const { modules } = useContext(LoadingContext)
  const main_layout = useMemo(() => texts.main_layout, [texts])
  const { xsQuery } = useMediaQuerys()
  const [ showInfo, setShowInfo ] = useState(xsQuery)
  const [ list, setList ] = useState([])
  const idKey = useId()

  useEffect(() => {
    setShowInfo(xsQuery)
  }, [xsQuery])
  useEffect(() => {
    if (main_layout) {
      const footerLength = main_layout.footer_info.length
      const cutBy = footerLength > 3 ? footerLength / 2 : footerLength
      const l = [ main_layout.footer_info.slice(0, cutBy), main_layout.footer_info.slice(cutBy) ]
      setList(l)
    }
  }, [main_layout])

  return (
    <Suspense fallback={<div></div>}>
      <div className="mb-2 1200:mb-0 w-52 tablet:w-auto" >
        <span className={`flex items-center cursor-pointer tablet:cursor-default`} onClick={() => setShowInfo(!showInfo)}>
          <p className="tablet:text-base font-bold uppercase py-1 px-0" style={{ color: colors.footer.color }} >
            {parse(main_layout?.footer_info_title || ``)}
          </p>
          <DropDownArrows style={{ color: colors.footer.color }} open={showInfo} className="tablet:hidden" />
        </span>

        <div className={`${showInfo ? `flex` : `hidden`} flex-col 1380:flex-row gap-4 py-0 pl-2 tablet:pl-1 `} >
          {list.map((elem, i) => (
            <div key={handleKeyRandom(idKey, i)}>
              {elem.map((b, e) => (
                <Link onClick={() => modules.handleLinkLoadingPath(b.url.split(`#`)[0])} key={handleKeyRandom(idKey, e)} href={host + b.url} passHref scroll={true}>
                  <p className="hover:underline" style={{ color: colors.footer.color }}>
                    {parse(b.text)}
                  </p>
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Suspense>
  )
}
