export const chatTicket = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: ownerState.closed ? colors.lightGrey2 : `${colors.tild}33`,
  margin: `5px`,
  padding: `10px 15px`,
  maxWidth: `80%`,
  alignSelf: `end`,
  borderRadius: `20px 20px  0  20px`,
  boxShadow: `0 0 1px 1px ${colors.backGrey}`,
})

export const chatResponse = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: ownerState.closed ? colors.lightGrey : colors.white,
  margin: `5px`,
  padding: `10px 15px`,
  maxWidth: `80%`,
  alignSelf: `start`,
  borderRadius: `20px 20px 20px 0 `,
  boxShadow: `0 0 1px 1px ${colors.backGrey}`,
})
