'use client'
import { SwipeableDrawer } from '@mui/material'
import React, { useContext } from 'react'
import { SearchData } from '../search'
import { SearchContext } from '@/context/search'

const SearchModal = ({ ...props }) => {
  const { open, toggleSearchOpen, toggleSearchClose } = useContext(SearchContext)
  return (

    <SwipeableDrawer
      id="search-drawer"
      anchor={`top`}
      open={open}
      onOpen={() => toggleSearchOpen()}
      onClose={() => toggleSearchClose()}
      sx={{
        background: `transparent`,
        height: `100dvh`,
        width: `100dvw`,
        zIndex: 1601,
        "& .MuiPaper-root": {
          // width: `100dvw`,
        }
      }}
    >
      <SearchData {...props} {...{ open, handleCloseSearchModal: toggleSearchClose }}/>
    </SwipeableDrawer >
  )
}

export default SearchModal
