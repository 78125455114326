import { LoadingContext } from "@/context/tools/loading"
import { cleanPath } from "@/utils/functions"
import IconsSVG from "@/utils/icons/IconsSVG"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { useEffect, useState, memo, useContext } from "react"

export const DesktopMenu = memo(({
  icon,
  linkText,
  iconName,
  iconPath = ``,
  open,
  expandElem,
  handleChange,
  linkPath = ``,
  colors,
  colorScheme,
  host,
  nativeLogo = false,
  children,
  childs
}) => {
  const path = usePathname()
  const [ selected, setSelected ] = useState(false)
  useEffect(() => {
    setSelected(path === cleanPath(linkPath))
  }, [ path, linkPath ])
  const backgroundColor = colorScheme?.background ?? (selected || childs ? colors.lightGrey : colors.white)
  const textColor = selected || childs ? colors.tildMenu : colorScheme?.text ?? colors.color
  const url = linkPath.includes(`https`) ? cleanPath(linkPath) : host + cleanPath(linkPath)

  const { modules } = useContext(LoadingContext)
  return (
    <div className="home-menu">
      <div className="flex justify-between items-center" style={{ backgroundColor }}>
        <Link
          href={url}
          prefetch={false}
          onClick={async() => {
            await modules.handleLinkLoading(true)
            await handleChange(null, !expandElem)
          }}
          style={{
            textDecoration: `none`,
            width: icon ? `90%` : `100%`
          }}
        >
          <div
            className="home-menu-elements"
            style={{
              "--hvr": colors.tildMenu,
              "--bg": backgroundColor,
              "--color": textColor
            }}
          >
            <IconsSVG
              name={iconName}
              path={iconPath}
              fillcolor={textColor}
              abbr={nativeLogo ? `native` : ``}
            />
            {open && (
              <p className="w-full px-2">
                {linkText}
              </p>
            )}
          </div>
        </Link>
        {icon && (
          <div
            className="w-10 flex cursor-pointer justify-end px-3"
            style={{
            }}
            onClick={() => handleChange(null, !expandElem)}
          >
            {icon}
          </div>
        )}
      </div>
      {children}
    </div>
  )
})
