'use client'
import { TWButton } from '@/components/tailwind/Button'
import { LoadingContext } from '@/context/tools/loading'
import { fixTwoDecimal } from '@/utils/functions'
import Link from 'next/link'
import { useContext, useState } from 'react'
import { ShipCost } from '../../products/subcomponents/ShipCost'

const CartDrawerFooter = ({ cart, currency, hostname, totalLines, toggleCartDrawer }) => {
  const { basket } = cart
  const { total_price, country_id, sede_id, discountCodeId, codeOb, couponOb, shipping_cost, warrext_total } = basket
  const { colors, texts } = hostname
  const { generic: globalTexts, cart_page: cartTexts } = texts
  const [ shipCost, setShipCost ] = useState(0)
  const total = !totalLines ? 0 : fixTwoDecimal(total_price + (country_id === null && sede_id === null && shipCost > 0 ? shipCost : 0))
  const { modules } = useContext(LoadingContext)

  return (
    <div id="sidecart-footer" className="divide-y" >
      <p className="mx-4 mt-2 capitalize text-right">
        {`${globalTexts.subtotal}: `}
        {!totalLines ? `0` : discountCodeId !== null ?
          `${codeOb?.type === 2 ?
            fixTwoDecimal((total_price ?? 0 - shipping_cost ?? 0 - (warrext_total ?? 0)) / ((100 - codeOb?.value ?? 0) / 100) * currency.exchange) :
            codeOb?.type === 1 ?
              fixTwoDecimal((total_price ?? 0 + codeOb?.value ?? 0 - shipping_cost ?? 0 - (warrext_total ?? 0)) * currency.exchange) :
              fixTwoDecimal((total_price ?? 0 - shipping_cost ?? 0 - (warrext_total ?? 0)) * currency.exchange)
          } ` :
          `${couponOb?.couponType === 1 && couponOb?.promo !== 1 ?
            fixTwoDecimal((total_price ?? 0 / ((100 - couponOb?.coupon ?? 0) / 100) - shipping_cost ?? 0 - (warrext_total ?? 0)) * currency.exchange) :
            couponOb?.couponType === 0 && couponOb?.promo !== 1 ?
              fixTwoDecimal((total_price ?? 0 + couponOb?.coupon ?? 0 - shipping_cost ?? 0 - (warrext_total ?? 0)) * currency.exchange) :
              fixTwoDecimal((total_price ?? 0 - shipping_cost ?? 0 - (warrext_total ?? 0)) * currency.exchange)
          } `
        }
        {currency.symbol}
      </p>
      <span className={`justify-end mx-4 ${totalLines ? `flex` : `hidden`}`}>
        <ShipCost {...{ pageTexts: globalTexts, hostname, totalPrice: total_price, currency, setShipCost }} />
      </span>
      {currency.id !== 1 &&
        <p id="total-currency" className="capitalize text-right text-base mx-4"> {`${!totalLines ? `0` : fixTwoDecimal(total * currency.exchange)} ${currency.symbol}`}</p>
      }
      <p id="total-euro" className="capitalize text-right text-lg font-bold mx-4"> {`${globalTexts.total}: ${!totalLines ? `0` : total} ${globalTexts.euro}`}</p>

      <Link prefetch={false} href={cartTexts.cartButton.url} passHref onClick={() => modules.handleLinkLoadingPath(cartTexts.cartButton.url)}>
        <div className="p-2">
          <TWButton {...{ colors }} className="w-full mt-2" onClick={() => toggleCartDrawer(false)}>
            {cartTexts.cartButton.text}
          </TWButton>
        </div>
      </Link>
    </div>
  )
}

export default CartDrawerFooter
