'use client'
import { getControlledSections } from '@/utils/functions'
import IconsSVG from '@/utils/icons/IconsSVG'

const FooterLogo = ({ hostname, colors }) => {
  const { webPages } = hostname
  const footerState = getControlledSections(webPages, `home`)
  return (
    <IconsSVG name="logo" path={hostname?.business?.logo} fillcolor={colors?.logo?.logoFooter} {...{ colors }} wdth={250} hght={85} abbr={footerState.nativeLogo ? `native` : ``}/>
  )
}

export default FooterLogo
