import { createTheme } from "@mui/material/styles"
import { colors, constants } from "@/utils/global"
import { cmp, types } from "@/types"
import { config } from "@/middleware/config/config"
import { func } from "."
const theme = createTheme()
export const drawerTheme = createTheme({
  components: {
    MuiDrawer: {
      defaultProps: {
        variant: `permanent`,
        anchor: `left`,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...ownerState.cmptype === cmp.menuDrawer && func.drawer.menuDrawer(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.drawer && {
            zIndex: theme.zIndex.drawer + 1,
          },
          ...ownerState.cmptype === cmp.drawerSwipeable && {
            zIndex: theme.zIndex.drawer + 105,
            backgroundColor: `${colors.dark}cc`,
            "& .MuiPaper-root": {
              borderRadius: theme.spacing(
                5, 5, 0, 0
              ),
              backgroundColor: ownerState.bgcolor || colors.lightGrey,
              padding: ownerState.padding || `inherit`,
            },
          },
        }),
      },
    },
    MuiSwipeableDrawer: {
      defaultProps: {
        disableBackdropTransition: config.iOS,
        disableDiscovery: config.iOS,
        swipeAreaWidth: constants.SWIPED_WIDTH,
        disableSwipeToOpen: true,
        anchor: `left`,
      },
    },
  },
})
