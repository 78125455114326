import { apiCallElk } from "@/middleware/api"
import { languageIDHelper } from "@/utils/functions"
import { useState, useEffect } from "react"

export const useSynonyms = props => {
  const { hostname } = props
  const { business } = hostname
  const [ synonyms, setSynonyms ] = useState([])

  const getSynonyms = async prop => {
    const obj = {
      ...prop,
      abbrElastic: business.abbrElastic
    }
    const { result } = await apiCallElk({
      url: `/search-synonyms`,
      type: `POST`,
      encrypt: obj
    })
    await setSynonyms(result)
  }

  useEffect(() => {
    hostname?.lang && getSynonyms({ languageId: languageIDHelper(hostname?.lang.toUpperCase()) })
  }, [hostname])

  return {
    synonyms
  }
}
