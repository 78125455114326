// export const example = (ownerState, theme, colors, constants) => ()
export const menuList = (
  ownerState, theme, colors, constants
) => ({
  height: `${constants.MENU_HEIGHT}vh`,
  padding: 0,
})
export const list = (
  ownerState, theme, colors, constants
) => ({
  padding: theme.spacing(0),
  overflowX: `auto`,
  maxHeight: constants.LANG_HEIGHT,
})
export const listPopper = (
  ownerState, theme, colors, constants
) => ({
  padding: theme.spacing(0),
  overflowX: `auto`,
  maxHeight: ownerState?.category === `true` ? 80 : constants.POPPER_HEIGHT,
})
export const accButton = (
  ownerState, theme, colors, constants
) => ({
  padding: theme.spacing(2.5, 3),
})
export const pickupList = (
  ownerState, theme, colors, constants
) => ({
  border: `1px solid ${colors.backGrey}`,
  borderRadius: `10px`,
  flexDirection: `column`,
  overflow: `auto`,
  padding: 0,
  marginLeft: ownerState.tablet === `true` && `10px`,
  marginTop: ownerState.tablet === `false` && `10px`,
  height: ownerState.tablet === `true` ? `400px` : `35vh`,
  width: ownerState.tablet === `true` ? `400px` : `100%`,
})
