'use client'
import { TWButton } from "@/components/tailwind/Button"
import { CookiesContext } from "@/context"
import { ToolsContext } from "@/context/tools/tools"
import { config } from "@/middleware/config/config"
import { types } from "@/types"
import { handleKeyRandom } from "@/utils/functions"
import { googleCookies } from "@/utils/functions/google"
import { CookieModal } from "@web/_components/modals/cookie"
import { hasCookie } from "cookies-next"
import parse from "html-react-parser"
import Cookies from "js-cookie"
import Link from "next/link"
import {
  useContext, useEffect,
  useMemo,
  useState
} from "react"

const {
  COOKIES: { COOKIES_POLICY, COOKIES_AD_USER_DATA, COOKIES_AD_PERSONALIZATION },
} = config
export const MsgCookies = ({ hostname }) => {
  const { mobile, colors } = hostname
  const { [types.cookies.preference.preferences]: viewPreferences, toggleCookiePreferences, toggleViewMsgCookie } = useContext(CookiesContext)
  const { [types.cookies.msg.preferences]: viewCookies } = useContext(CookiesContext)
  const { tools: { texts }} = useContext(ToolsContext)
  const cookiesTexts = useMemo(() => texts.cookies_policy, [texts])
  const [ viewMsgCookies, setViewMsgCookies ] = useState(true)
  const time = 3
  const [ styles, setStyles ] = useState({
    position: `fixed`,
    bottom: `-60dvh`,
    width: mobile ? `100%` : `auto`,
    marginLeft: `0%`,
    marginRight: `0%`,
    padding: `15px`,
    zIndex: 2000,
    transition: `bottom 1s cubic-bezier(0.4, 0, 0.6, 1)`,
    pointerEvents: `auto`
  })
  const backdropStyles = {
    zIndex: 1999,
    pointerEvents: `none`
  }

  useEffect(() => {
    setViewMsgCookies(hasCookie(config.COOKIES.COOKIES_POLICY))
  }, [viewCookies])

  const handleSubmit = async e => {
    await e.preventDefault()
    await Cookies.set(COOKIES_POLICY, 1, { expires: 90 })
    await Cookies.set(COOKIES_AD_USER_DATA, 1, { expires: 90 })
    await Cookies.set(COOKIES_AD_PERSONALIZATION, 1, { expires: 90 })
    await googleCookies()
    await toggleViewMsgCookie()
  }
  const configStyle = () => {
    setStyles(x => ({
      ...x,
      bottom: `0`,
    }))
  }
  useEffect(() => {
    const handleClickOrScroll = () => {
      if (!viewMsgCookies) {
        configStyle()
      }
    }

    window.addEventListener(`scroll`, handleClickOrScroll, { passive: true })
    window.addEventListener(`click`, handleClickOrScroll)

    const timeoutId = setTimeout(() => {
      if (!viewMsgCookies) {
        configStyle()
      }
    }, time * 1000)

    return () => {
      window.removeEventListener(`scroll`, handleClickOrScroll)
      window.removeEventListener(`click`, handleClickOrScroll)
      clearTimeout(timeoutId)
    }
  }, [viewMsgCookies])
  const textCookie = `text-left font-[400] text-[${mobile ? 14 : 16}px] mb-2`
  return !!cookiesTexts && !viewMsgCookies &&
  <div onClick={() => configStyle()} className="backdropModal fixed inset-0 flex items-center justify-center bg-neutral-900 bg-opacity-0"
    style={backdropStyles}
  >
    <div className="paperCookie w-full" style={{ ...styles,
      color: colors.cookies.title,
      backgroundColor: colors.white }}>
      <div className={`w-full ${styles?.font ?? `font-quicksand`} `}>
        <div className="grid grid-cols-1"
          style={{
            height: `40dvh`,
            margin: `40px 10px 20px 10px`,
            textAlign: `justify`
          }}>
          <h5 className="text-left font-[700] text-[20px] mb-0">
            {parse(cookiesTexts?.info?.title || ``)}
          </h5>
          <div style={{ height: `90%`, overflowY: `scroll`, paddingRight: !mobile ? `5px` : `30px` }}>
            {[
              0, 4, 5, 6
            ].map(i => {
              let p = null
              if (i === 0) {
                p = <p key={handleKeyRandom(`cookiesText`, i)} className={textCookie} >
                  {parse(cookiesTexts?.info?.message[0] || ``)}&nbsp;
                  <Link href={cookiesTexts?.cookie?.link?.href || ``} prefetch={false} passHref style={{ color: colors.tild }}>
                    {parse(`&#8223;${cookiesTexts?.info?.link_message.toLowerCase()}&#8221;`)}
                  </Link>
              &nbsp;{parse(cookiesTexts?.info?.message[1] || ``)}
                </p>
              } else {
                p = <p key={handleKeyRandom(`cookiesText`, i)} className={textCookie}>
                  {parse(cookiesTexts?.info?.message[i] || ``)}
                </p>
              }
              return p
            })}
          </div>
        </div>
        <div className="grid xs:grid-cols-1 tablet:grid-cols-2 1200:grid-cols-4 1540:grid-cols-6" style={{ marginBottom: `10px`, justifyContent: `end` }}>
          <div className="col-span-auto xs:py-1 tablet:px-2 1200:col-start-3  1540:col-start-5">
            <TWButton {...{ colors, color: colors.cookies.deactivated.color, bg: colors.cookies.deactivated.background, hvr: `${colors.cookies.active.background}25` }} style={{ width: `-webkit-fill-available` }} onClick={toggleCookiePreferences}>
              {parse(cookiesTexts?.info?.config_cookies.toUpperCase() || ``)}
            </TWButton>
          </div>
          <div className="col-span-auto xs:py-1 tablet:px-2">
            <TWButton {...{ colors, color: colors.cookies.active.color, bg: colors.main, hvr: colors.secondary }} style={{ width: `-webkit-fill-available` }} onClick={handleSubmit}>
              {parse(cookiesTexts?.info?.accept_continue.toUpperCase() || ``)}
            </TWButton>
          </div>
        </div>
      </div>
      {viewPreferences && <CookieModal {...{ cookiesTexts, hostname }} />}
    </div>
  </div>
}
