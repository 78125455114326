import { types } from "@/types"

// export const example = (ownerState, theme, colors, constants) => ()
export const gridMenuContainer = (
  ownerState, theme, colors, constants
) => ({
  height: `60vh`,
  overflowX: `hidden`,
})
export const gridSubMenuBody = (
  ownerState, theme, colors, constants
) => ({
  height: constants.GRID_MENU,
  backgroundColor: `${colors.white}90`,
})
export const gridMenuBody = (
  ownerState, theme, colors, constants
) => ({
  height: constants.GRID_MENU,
  padding: theme.spacing(1, 0.5),
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `center`,
  backgroundColor: `${colors.white}90`,
  border: `1px solid ${colors.lightGrey2}80`,
  "& p": {
    marginTop: theme.spacing(1),
    textTransform: `uppercase`,
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 600,
    color: colors.color,
  },
  [`& .svg-class-${types.nav.blackFriday}, 
	 .svg-class-${types.nav.outlet}, 
	 .svg-class-${types.nav.brand}, 
	 .svg-class-${types.events.special},
	 .svg-class-${types.nav.b2b}, 
	 .svg-class-${types.nav.dynamic}`]: {
    width: `${constants.ICON_SIZE_MOBILE}px !important`,
  },
  [`& .svg-class-${types.nav.outlet}, 
	 .svg-class-${types.nav.brand}, 
	 .svg-class-${types.nav.b2b},
	 .svg-class-${types.nav.dynamic}`]: {
    "& .st0": {
      fill: `${colors.color}!important`,
    },
  },
  [`&.${types.nav.blackFriday}`]: {
    "& p": {
      color: colors.white,
    },
    backgroundColor: colors.dark,
    [`&.${types.nav.blueDay}`]: {
      background: `${colors.main}!important`,
    },
  },
  [`&.${types.events.special}`]: {
    backgroundColor: ownerState.specialcolor,
    "& p": { color: `${colors.white} !important` },
    [`& .svg-class-${types.events.special}`]: {
      "& .st0": {
        fill: `${colors.white} !important`,
      },
    },
  },
})
export const accordionSumModCookie = (
  ownerState, theme, colors, constants
) => ({
  color: colors.color,
  textTransform: `uppercase`,
  fontWeight: `500`,
  padding: theme.spacing(1),
  fontSize: theme.typography.pxToRem(13),
})
export const gridHome = (
  ownerState, theme, colors, constants
) => ({
  height: `fit-content`,
  margin: `auto`,
  padding: ownerState.mobile === `true` ? 0 : 2
})
export const gridContainerHome = (
  ownerState, theme, colors, constants
) => ({
  margin: `auto`,
  marginTop: 0,
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  "& .svg-class-home-icon": {
    height: 35,
    "& .st0": {
      fill: colors.color,
    },
  },
})
export const gridContainerLogin = (
  ownerState, theme, colors, constants
) => ({
  margin: `auto`,
  minHeight: `45vh`,
  alignItems: `flex-start`,
  justifyContent: `space-evenly`,
  padding: theme.spacing(2, 1.5),
  marginTop: ownerState.mobile ? theme.spacing(ownerState.mobile === `false` ? 5 : 2) : 0,
  marginBottom: ownerState.mobile ? theme.spacing(ownerState.mobile === `false` ? 5 : 2) : 0,
})
export const gridContainerItemHome = (
  ownerState, theme, colors, constants
) => ({
  background: colors.infoHomeBack,
  display: `flex`,
  alignItems: `flex-start`,
  width: `100%`,
  borderRadius: 5,
  padding: theme.spacing(5, 2),
})
export const gridItemHome = (
  ownerState, theme, colors, constants
) => ({
  margin: `auto`,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  flexDirection: `column`,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
})
export const homeFooter = (
  ownerState, theme, colors, constants
) => ({
  margin: theme.spacing(
    2.5, 0, 10, 0
  ),
  marginLeft: `auto`,
  marginRight: `auto`,
  alignItems: `flex-start`,
  justifyContent: `space-between`,
})
export const motoBoxSearch = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: ownerState.mobile === `false` ? colors.white : `${colors.secondary}25`,
  padding: theme.spacing(2),
  boxShadow: ownerState.mobile === `false` ? `0px 0px 1px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)` : `inherit`,
  borderRadius: ownerState.mobile === `true` ? 0 : 50,
})
export const gridItemFlex = (
  ownerState, theme, colors, constants
) => ({
  display: `flex`,
  justifyContent: `space-evenly`,
  alignItems: `center`,
})
export const gridItemLogin = (
  ownerState, theme, colors, constants
) => ({
  minHeight: 150,
})
export const headerTips = (
  ownerState, theme, colors, constants
) => ({
  padding: theme.spacing(0),
  marginLeft: `auto`,
  marginRight: `auto`,
  maxWidth: `100vw`,
})
export const gridAccMenu = (
  ownerState, theme, colors, constants
) => ({
  position: `sticky`,
  top: constants.DESKTOP_MENU.TOP + constants.DESKTOP_MENU.BOTTOM + 12,
  padding: `10px`,
  borderRadius: ownerState.small === `true` ? 0 : 10,
  maxHeight: ownerState.small === `false` ? `85vh` : 180,
  minHeight: `0`,
  display: ownerState.small === `true` && `block`,
  backgroundColor: ownerState.small === `true` ? colors.backGrey : `inherit`,
  zIndex: 99,
})
export const gridAccItem = (
  ownerState, theme, colors, constants
) => ({
  padding: theme.spacing(2.5, 3),
})
export const gridLegal = (
  ownerState, theme, colors, constants
) => ({
  padding: theme.spacing(4, 4),
  marginLeft: `auto`,
  marginRight: `auto`,
  maxWidth: `100vw`,
})
export const gridsItem = (
  ownerState, theme, colors, constants
) => ({
  padding: ownerState.padding || `20px`,
  backgroundColor: colors.backGrey,
  margin: 0,
  marginBottom: theme.spacing(2),
  borderRadius: 10,
})
export const gridCookie = (
  ownerState, theme, colors, constants
) => ({
  [theme.breakpoints.up(`md`)]: {
    padding: theme.spacing(4, 6),
  },
  padding: theme.spacing(4, 4),
  marginLeft: `auto`,
  marginRight: `auto`,
  maxWidth: `100vw`,
})
export const gridFilter = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: colors.white,
  position: ownerState.sticky === `true` ? `fixed` : `sticky`,
  top: constants.DESKTOP_MENU.TOP + constants.DESKTOP_MENU.BOTTOM + (ownerState.mobile === `true` && ownerState.mob === `true` ? -26 : ownerState.params?.s ? 4.5 : 42),
  zIndex: theme.zIndex.drawer,
  alignItems: `center`,
  padding: `${ownerState.mobile === `false` ? 0 : 6}px ${ownerState.mobile === `false` ? 40 : ownerState.sticky === `true` ? 0 : 20}px`,
  width: `100%`,
  "& .sticky": {
    position: ownerState.sticky === `true` ? `fixed` : `sticky`,
    minWidth: ownerState.mob === `true` ? `100%` : ownerState.width || 125,
    right: ownerState.mob === `true` ? `0%` : `2%`,
  },
})
