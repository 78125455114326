'use client'
import { constants } from "@/utils/global"
import { useEffect, useState } from "react"
import { useMediaQuerys } from "./useMediaQuerys"

const { INNER_WIDTH } = constants

export const useDimension = (prop, dim = INNER_WIDTH, window = globalThis?.window) => {
  const [ dimensions, setDimensions ] = useState(globalThis.window?.innerWidth)
  const handleDimension = () => {
    function handleResize() {
      setDimensions(globalThis.window.innerWidth)
    }
    globalThis.window.addEventListener(`resize`, handleResize)
  }
  const CheckDimension = async() => {
    function handleResize() {
      setDimensions(globalThis.window.innerWidth)
    }
    await globalThis.window.addEventListener(`resize`, handleResize)
    return dimensions
  }

  return {
    MOBILE: !(!prop && dimensions > dim),
    handleDimension,
    CheckDimension
  }
}
export const useCallMobile = (
  name, mobile, vp = INNER_WIDTH, menu = false
) => {
  const mdQ = useMediaQuerys()
  const { MOBILE, handleDimension } = useDimension(mobile, vp)
  useEffect(() => {
    handleDimension(x => x)
  }, [mdQ[name]])
  return { MOBILE }
}
