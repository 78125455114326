import { useCallMobile } from "@/hooks/utils/useDimension"
import { cmp } from "@/types"
import { handleKeyRandom } from "@/utils/functions"
import { Chip, Grid2, Paper } from "@mui/material"
import { useId, useRef } from "react"
import { useDraggable } from "react-use-draggable-scroll"

export const BoxChips = ({ ...props }) => {
  const {
    chips, mobile, desk, open, width, handleClear, colors
  } = props
  const keyId = useId()
  const ref = useRef(null)
  const { events } = useDraggable(ref)
  const { MOBILE } = useCallMobile(`tablet`, mobile, 900)

  return (
    <Paper cmptype={cmp.boxChips} elevation={0}
      {...{
        mobile: MOBILE.toString(),
        desk: desk.toString(),
        open: open.toString(),
        width
      }} {...events}
      ref={ref} sx={{
        height: `auto`,
        width: `inherit !important`
      }}>
      {chips?.length > 0 && chips?.map((x, i) => (<Grid2

        key={handleKeyRandom(keyId, i)}
        sx={{
          marginTop: 0.5,
          marginRight: 0.5,
          height: 28,
        }}
      >
        <Chip cmptype={cmp.chipBoxSearch} label={x.label}
          sx={{ border: `1px solid ${x.idx !== null ? colors.FadedBlack : colors.greenBtn}` }}
          fill={600} hgt={26}
          onDelete={() => handleClear(x.referer, x.idx === 0, chips?.length - 1 === i)}

        // onClick={() => chips?.length === 2 ? handleReplaceAll(x.referer, x.label) : x.label !== x.referer && handleReplace(
        //   x.referer, x.label, x.idx === 0, chips?.length - 1 === i
        // )}
        />
      </Grid2>))
      }
    </Paper>
  )
}
