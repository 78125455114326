import TWDrawer from '@/components/tailwind/Drawer'
import { CartContext } from '@/context'
import { useCart } from '@/hooks/cart/useCart'
import { editBasketWeb, getLinesTotalAmount } from '@/utils/functions/cart'
import LinearProgress from '@mui/material/LinearProgress'
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import CartDrawerFooter from './subcomponent/CartDrawerFooter'
import CartLine from './subcomponent/CartLine'

const CartDrawer = props => {
  const { hostname, cartDrawerOpen, toggleCartDrawer } = props
  const { mobile, webPages, colors, texts } = hostname
  const { main_layout, generic: globalTexts } = texts
  const { ipInfo, login } = hostname
  const { addRemoveItem } = useCart()
  const { cart } = useContext(CartContext)
  const { basket, currency } = cart
  const { lines: basketLines } = basket
  const lines = useMemo(() => basketLines?.lines ?? [], [basketLines])
  const packLines = useMemo(() => basketLines?.pack_lines ?? [], [basketLines])
  const totalAmount = useMemo(() => getLinesTotalAmount(basketLines) ?? [], [basketLines])
  const sidecartBodyRef = useRef(null)
  const [ loadingLine, setLoadingLine ] = useState(false)

  useEffect(() => {
    if (cartDrawerOpen && sidecartBodyRef.current) {
      setTimeout(() => {
        sidecartBodyRef.current.scrollTo({
          top: sidecartBodyRef.current.scrollHeight,
          behavior: `smooth`
        })
      }, 300)
    }
  }, [cartDrawerOpen])

  const handleDelete = async item => {
    await setLoadingLine(true)
    const result = await editBasketWeb(
      cart, { id: item.product_id }, item.pack || item, `-${item.amount}`, login, hostname, ipInfo
    )
    await addRemoveItem({ data: result, msg: true, codeLang: hostname?.lang.toLowerCase() })
    await setLoadingLine(false)

    // await handleGoogle(types.gtag.eventType.removeFromCart, item, item.amount)
  }

  return (
    <TWDrawer {...props} className={`divide-y-2 w-80 tablet:w-96`} width={null}>
      <span>
        <div id="sidecart-header" className={`bg-slate-200 p-4 flex items-center justify-between`}>
          <div className="flex items-center">
            <p className="mr-2 text-base font-bold" >{main_layout.cart_menu}</p>
            <p className="bg-white rounded px-4 text-sm text-neutral-500">
              {`${totalAmount} ${main_layout.cart_articles}`}
            </p>
          </div>
          <button onClick={() => toggleCartDrawer(false)}>
            <IoClose className="size-6 text-neutral-500" />
          </button>
        </div>
        {loadingLine &&
        <div className="w-full h-1">
          <LinearProgress />
        </div>
        }
        <div id="sidecart-body" ref={sidecartBodyRef} style={{ maxHeight: `calc(100dvh - 230px)` }} className="h-auto overflow-y-auto divide-y" >
          {!lines?.length && !packLines.length ?
            <div className="flex items-center justify-center py-3"> <p className="text-base font-bold text-slate-400">{main_layout.cart_empty}</p></div> :
            <>
              {!!lines.length && lines.map((line, i) => (<CartLine key={`cart_variant_${i}`} {...{ toggleCartDrawer, hostname, currency, globalTexts, handleDelete, index: i, line, loadingLine, length: lines.length + packLines?.length }} />))}

              {!!packLines?.length && packLines.map((packLine, i) => (<CartLine key={`cart_pack_variant_${i}`} {...{ toggleCartDrawer, hostname, currency, globalTexts, handleDelete, index: i, line: packLine, loadingLine, length: packLines.length }} />))}
            </>
          }
        </div>
      </span>
      {(!!lines?.length || !!packLines.length) && cartDrawerOpen && <CartDrawerFooter {...{ cart, currency, hostname, texts, totalLines: lines.length + packLines.length, toggleCartDrawer }} />}
    </TWDrawer>
  )
}

export default memo(CartDrawer)
