'use client'
import { types } from "@/types"
import { useReducer } from "react"
import { SearchContext } from "./SearchContext"
import { searchInitialState, searchReducer } from "./searchReducer"

export const SearchProvider = ({ children, ...prop }) => {
  const [ state, dispatch ] = useReducer(searchReducer, searchInitialState)

  const toggleSearch = () => {
    dispatch({ type: types.searchCatalogue.toggle })
  }
  const toggleSearchOpen = () => {
    dispatch({ type: types.searchCatalogue.open })
  }
  const toggleSearchClose = () => {
    dispatch({ type: types.searchCatalogue.close })
  }

  return (
    <SearchContext.Provider
      value={{
        ...state,
        toggleSearch,
        toggleSearchOpen,
        toggleSearchClose,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}
