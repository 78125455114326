'use client'
import { CartContext, CartStepsContext } from '@/context'
import { useCart } from '@/hooks/cart/useCart'
import { types } from '@/types'
import { logOut } from '@/utils/functions/login-password'
import IconsSVG from '@/utils/icons/IconsSVG'
import { useRouter } from 'next/navigation'
import { useContext } from 'react'

export const Logout = ({ colors, text = `logout`, small = undefined, className }) => {
  const { handleBasketId } = useCart()
  const { module } = useContext(CartStepsContext)
  const { handleRestartCart } = useContext(CartContext)
  const router = useRouter()

  const handleLogOut = async() => {
    await handleBasketId({}, `null`)
    await handleRestartCart()
    await module.handleRestartSteps()
    await logOut(router)
  }
  return (
    <div
      style={{ color: colors.RedAv }}
      className={`${className} flex items-center cursor-pointer  ${!small ? `p-2 hover:bg-neutral-50` : ``}`}
      onClick={handleLogOut}
    >
      <IconsSVG name={`logout`} path={`${types.profileIcons}logout.svg`} fillcolor={colors.RedAv} size={small ? 20 : 23}/>
      <p className={`${!small ? `ml-1 text-base` : `text-sm`} font-semibold text-inherit whitespace-nowrap `}>{text}</p>
    </div>
  )
}
