import { createTheme } from "@mui/material/styles"
import { colors, constants, fontQuicksand } from "@/utils/global"
import { cmp, types } from "@/types"
import { func } from "."
const theme = createTheme()
export const listTheme = createTheme({
  components: {
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          fontWeight: 500,
          color: colors.color,
          padding: theme.spacing(
            0.5, 2.5, 0.5, 1
          ),
          fontSize: theme.typography.pxToRem(13),
          backgroundColor: `transparent`,
          ...ownerState.cmptype === cmp.selectItem && func.menuItems.selectItem(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accButton && func.menuItems.accButton(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.filterBtn && func.menuItems.filterBtn(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...ownerState.cmptype === cmp.menuList && func.list.menuList(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.list && func.list.list(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.listPopper && func.list.listPopper(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accButton && func.list.accButton(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.pickupList && func.list.pickupList(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...ownerState.cmptype === cmp.listPopper && func.listItem.listPopper(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.pickupListItem && func.listItem.pickupListItem(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
  },
})
