// export const example = (ownerState, theme, colors, constants) => ()
import { colors as muicolor } from "@mui/material"
export const selectLang = (
  ownerState, theme, colors, constants
) => ({
  fontSize: theme.typography.pxToRem(ownerState?.ft || 13),
  fontWeight: 500,
  textTransform: `capitalize`,
  boxShadow: 0,
  border: 0,
  padding: theme.spacing(0, ownerState.ismobile === `true` ? 0 : 0.5),
  "&:hover": {
    backgroundColor: `transparent`,
  },
  color: ownerState.textcolor,
  minWidth: ownerState.wd
})
export const metaMotoButton = (
  ownerState, theme, colors, constants
) => ({
  marginBottom: ownerState.ismobile === `true` ? theme.spacing(0.8) : 0,
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 500,
  textTransform: `capitalize`,
  backgroundColor: ownerState.ismobile === `true` ? colors.white : `transparent`,
  color: colors.color,
  boxShadow: 0,
  border: 0,
  borderRadius: ownerState.ismobile === `true` ? 25 : 0,
  WebkitJustifyContent: `space-between`,
  justifyContent: `space-between`,
  padding: theme.spacing(ownerState.ismobile === `true` ? 1 : 0, ownerState.ismobile === `true` ? 1 : 0),
  width: `-webkit-fill-available`,
  flexDirection: `column`,
  "&:hover": {
    backgroundColor: ownerState.ismobile === `true` ? colors.white : `transparent`,
  },
})
export const msgCookies = (
  ownerState, theme, colors, constants
) => ({
  fontSize: theme.typography.pxToRem(ownerState.font),
  textTransform: `inherit`,
  borderWidth: `1px`,
  borderStyle: `solid`,
  borderRadius: 50,
  width: 250,
  backgroundColor: ownerState.background,
  color: ownerState.fontcolor,
  borderColor: ownerState.border,
  marginLeft: theme.spacing(ownerState.ml),
  marginRight: theme.spacing(ownerState.mr),
  "&:hover": {
    backgroundColor: ownerState.hover,
  },
})
export const dialogButtonCookie = (
  ownerState, theme, colors, constants
) => ({
  width: ownerState.wdth ?? `40%`,
  borderRadius: 50,
  backgroundColor: colors.main,
  color: colors.mainContrast,
  fontSize: theme.typography.pxToRem(ownerState.font),
  "&:hover": {
    backgroundColor: colors.secondary,
  },
})
export const metaMotoLink = (
  ownerState, theme, colors, constants
) => ({
  width: ownerState.mobile === `false` ? 60 : `90%`,
  height: 60,
  borderRadius: 100,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  color: colors.mainContrast,
  textDecoration: `none`,
  backgroundColor: colors.main,
  "&.Mui-disabled": {
    backgroundColor: colors.lightGrey3,
  },
  "& div": {
    display: `flex`,
  },
  "& .svg-class-search-meta-moto": {
    height: 25,
    "& .st0": {
      fill: colors.white,
    },
  },
  "&:hover": {
    backgroundColor: colors.main,
  },
})
export const defButton = (
  ownerState, theme, colors, constants
) => ({
  fontWeight: 600,
  display: `inline-flex`,
  justifyContent: `center`,
  alignItems: `center`,
  lineHeight: 1.25,
  padding: ownerState.padding ?? theme.spacing(0.5, 1),
  borderRadius: 25,
  fontSize: theme.typography.pxToRem(ownerState.font || 16),
  border: `${ownerState.border || 0}px solid ${ownerState.textcolor}`,
  transition: theme.transitions.create([`backgroundColor`], {
    easing: theme.transitions.easing.sharp,
    duration: 100,
  }),
  minWidth: ownerState.minw ?? `auto`,
  width: ownerState.w,
  backgroundColor: ownerState.background,
  color: ownerState.textcolor,
  marginTop: ownerState?.mt ? theme.spacing(ownerState.mt) : `inherit`,
  marginBottom: ownerState?.mb ? theme.spacing(ownerState.mb) : `inherit`,
  marginLeft: ownerState.ml || `inherit`,
  marginRight: ownerState.mr || `inherit`,
  height: ownerState.h || 35,
  textTransform: ownerState.cpz || `uppercase`,
  "&:hover": {
    backgroundColor: ownerState.backgroundhover,
    color: `${ownerState.textcolorhover || ownerState.textcolor}!important`,
    transition: theme.transitions.create([`backgroundColor`], {
      easing: theme.transitions.easing.sharp,
      duration: 100,
    }),
  },
})
export const accButton = (
  ownerState, theme, colors, constants
) => ({
  minHeight: 32,
  borderRadius: 50,
  backgroundColor: colors.white,
  width: `100%`,
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 700,
  textTransform: `uppercase`,
  display: `flex`,
  border: `2px solid ${colors.secondary}`,
  "&:hover": {
    backgroundColor: colors.white,
  },
  [`.svg-class-${ownerState.svgclass}`]: {
    width: constants.ICON_SIZE + 6,
    marginRight: theme.spacing(1.5),
    display: `flex`,
    "& .st0": {
      fill: ownerState.svgclass === `logout` ? muicolor.red[400] : ownerState.selected ? colors.secondary : colors.color,
    },
  },
})
export const accDfltBtn = (
  ownerState, theme, colors, constants
) => ({
  whiteSpace: `nowrap`,
  fontWeight: `700`,
  marginTop: ownerState.mt || 10,
  fontSize: theme.typography.pxToRem(ownerState.font || 17),
  color: ownerState.clr || colors.lightGrey3,
  textTransform: ownerState.cptlz || `lowercase`,
  height: 35,
  "&:hover": {
    textDecoration: `underline`,
    backgroundColor: `transparent`,
    textUnderlineOffset: `3px`,

    color: ownerState.hvr,
    "& .MuiButton-startIcon": {
      [`.svg-class-${ownerState.svgclass}`]: {
        width: `20px`,
        display: `flex`,
        "& .st0": {
          transition: theme.transitions.create([`fill`], {
            easing: theme.transitions.easing.sharp,
            duration: 300,
          }),
          fill: ownerState.hvr,
        },
      },
    },
  },
  borderRadius: 50,
  "& .MuiButton-startIcon": {
    [`.svg-class-${ownerState.svgclass}`]: {
      width: `20px`,
      display: `flex`,
      "& .st0": {
        fill: ownerState.iclr || colors.lightGrey3,
      },
    },
  },
})
export const selectBtn = (
  ownerState, theme, colors, constants
) => ({
  fontSize: theme.typography.pxToRem(ownerState.font || 14),
  fontWeight: 500,
  textTransform: ownerState.cap || `capitalize`,
  backgroundColor: colors.lightGrey,
  color: ownerState?.fill ? colors.color : ownerState.open === `false` ? colors.darkGrey2 : ownerState.ismobile === `false` ? colors.color : colors.darkGrey2,
  boxShadow: 0,
  borderRadius: ownerState.ismobile === `true` ? ownerState.left === `false` ? theme.spacing(
    0, 50, 50, 0
  ) : theme.spacing(
    50, 0, 0, 50
  ) : 50,
  border: `${ownerState?.fill ? `0` : `1`}px solid ${
    ownerState.open === `false` ? `${colors.darkGrey2}${ownerState.ismobile === `true` ? `55` : ``}` : ownerState.ismobile === `false` ? colors.color : `${colors.darkGrey2}55`
  }`,
  minWidth: ownerState.ismobile === `true` ? `100%` : ownerState.width || 125,
  padding: theme.spacing(0, ownerState.ismobile === `true` ? 0 : 0.5),
  height: ownerState.ismobile === `true` ? ownerState?.fill ? 40 : 32 : 25,
  justifyContent: ownerState.ismobile === `true` ? ownerState?.fill ? `space-between` : `center` : `space-between`,
  "&.is-pinned": {
    borderRadius: `0px !important`,
    height: 40,
  },
  "&:hover": {
    backgroundColor: colors.lightGrey,
    border: `${ownerState?.fill ? `0` : `1`}px solid ${colors.color}`,
    color: colors.color,
  },
})
export const filterBtn = (
  ownerState, theme, colors, constants
) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  textTransform: ownerState.cap || `capitalize`,
  backgroundColor: colors.lightGrey,
  color: ownerState.fill === `true` ? colors.color : ownerState.open === `false` ? colors.darkGrey2 : ownerState.ismobile === `false` ? colors.color : colors.darkGrey2,
  boxShadow: 0,
  borderRadius: ownerState.ismobile === `true` ? ownerState.left === `false` ? theme.spacing(
    0, 50, 50, 0
  ) : theme.spacing(
    50, 0, 0, 50
  ) : 50,
  border: `${ownerState.fill === `true` ? `0` : `1`}px solid ${
    !ownerState.open ? `${colors.darkGrey2}${ownerState.ismobile === `true` ? `55` : ``}` : ownerState.ismobile === `false` ? colors.color : `${colors.darkGrey2}55`
  }`,
  minWidth: ownerState.ismobile === `true` || ownerState.MOBILE ? `100%` : ownerState.width || 125,
  padding: theme.spacing(0, 0.5),
  height: ownerState.ismobile === `true` ? ownerState.fill === `true` ? 40 : 32 : 25,
  justifyContent: ownerState.ismobile === `true` ? ownerState.fill === `true` ? `space-between` : `center` : `space-between`,
  "&.is-pinned": {
    borderRadius: ownerState.ismobile === `false` ? `0px !important` : `inherit`,
    height: 40,

    // position: `fixed`,
    // top: 150,
    // minWidth: ownerState.ismobile === `true` ? `100%` : ownerState.width || 125,
    // right: `1%`,
  },
  "&:hover": {
    backgroundColor: colors.lightGrey,
    border: `${ownerState.fill === `true` ? `0` : `1`}px solid ${colors.color}`,
    color: colors.color,
  },
})
export const filterClr = (
  ownerState, theme, colors, constants
) => ({
  "&.MuiButtonGroup-grouped": {
    minWidth: 25,
  },
  minHeight: 25,
  margin: theme.spacing(0.5),
  borderRadius: 5,
  padding: 0,
  border: `1px solid ${colors.darkGrey2}55`,
  backgroundColor: ownerState.bckcolor,
  "&.active": {
    border: `2px solid ${colors.secondary}`,
    "&:hover": {
      border: `2px solid ${colors.secondary}`,
    },
  },
  "&:hover": {
    border: `2px solid ${colors.color}`,
    backgroundColor: ownerState.bckcolor,
  },
})
