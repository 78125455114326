'use client'
import { LoadingContext } from '@/context/tools/loading'
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress'
import { useContext } from 'react'

export const Loader = ({ colors }) => {
  const { tools: { linkLoading }} = useContext(LoadingContext)
  const Progress = props => {
    return (
      <div style={{ position: `relative` }}>
        <CircularProgress
          variant="indeterminate"
          disableShrink
          thickness={8}
          sx={theme => ({
            color: colors.tildMenu,

            animationDuration: `550ms`,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: `round`,
            },
            ...theme.applyStyles(`dark`, {
              color: `#308fe8`,
            }),
          })}
          size={30}
          {...props}
        />
      </div>
    )
  }
  return linkLoading && (
    <div id="loader" style={{ position: `fixed`, bottom: `1dvh`, right: `1dvw`, zIndex: 9999 }}>
      <Progress />
    </div>
  )
}
