'use client'
import { CartContext } from '@/context'
import { types } from '@/types'
import { useContext } from 'react'
import NavLinker from './NavLinker'

const HomeLink = ({
  ipInfo, texts, show, isBasket, login, showSection, ...props
}) => {
  const { cart } = useContext(CartContext)
  return (
    <>
      {!show && !!showSection?.login && (
        <NavLinker
          href={login?.user ? texts?.profile?.url || `` : texts?.login?.url || ``}
          name={login?.user ? types._menu.login : types._menu.logout}
          label={login?.user ? texts?.profile?.text : texts?.login?.text }
          {...props}
        />
      )}
      {isBasket && !!showSection?.cart && <NavLinker
        href={texts?.cart_button?.url}
        name={types._menu.cart}
        label={!show ? texts?.cart : ``}
        cart={cart}
        {...props} />}
    </>
  )
}
export default HomeLink
