'use client'
import { DropDownArrows } from "@/app/(web)/_components/basket/subcomponent/components/subcomponents/DropDownArrows"
import { MenuContext, MobileContext } from "@/context"
import { useMediaQuerys } from "@/hooks/utils/useMediaQuerys"
import { types } from "@/types"
import { getControlledSections, handleKeyRandom } from "@/utils/functions"
import {
  Fragment,
  memo,
  useCallback,
  useContext, useEffect, useId,
  useMemo
} from "react"
import SubMenu from "../global/SubMenu"
import { DesktopMenu } from "./DesktopMenu"
import { ToolsContext } from "@/context/tools/tools"

export const Menu = memo(({ hostname, menu, ...props }) => {
  const { webPages, host, colors } = hostname
  const { tools: { texts }} = useContext(ToolsContext)
  const mainLayout = useMemo(() => texts?.main_layout, [texts])

  const keyId = useId()
  const { xsQuery } = useMediaQuerys()
  const { toggleOpenSwip } = useContext(MobileContext)
  const showStaticMenu = !!webPages.find(x => x.name === `staticMenu`)?.show
  const nativeLogo = !!getControlledSections(webPages, `home`)?.nativeLogo
  const {
    [types.menuExpanded.openMenu]: open, toggleMenu, level2, level3, expanded, expandedCategory, expandedSubCategory, toggleExpanded, toggleExpandedCategory, toggleExpandedSubCategory
  } = useContext(MenuContext)

  const handleDrawerOpen = useCallback(e => {
    xsQuery ? toggleMenu() : toggleOpenSwip()
  }, [ xsQuery, toggleMenu, toggleOpenSwip ])

  const handleChangeParent = useCallback(panel => (e, isExpanded) => {
    toggleExpanded(isExpanded ? panel : false)
    !open && handleDrawerOpen()
  }, [ open, toggleExpanded, handleDrawerOpen ])

  const handleChangeCategory = (panel, l, isExpanded) => {
    console.info(``)
    switch (l) {
    case 2:
      toggleExpandedCategory(!isExpanded ? panel : 0)
      toggleExpandedSubCategory(0)
      break
    case 3:
      toggleExpandedSubCategory(!isExpanded ? panel : 0)
      break
    default:
      break
    }
  }

  const menuItems = useMemo(() => menu.map((x, i) => (
    <Fragment key={handleKeyRandom(keyId, i)}>
      <DesktopMenu
        icon={x.info.children && open ? <DropDownArrows open={expanded === x.info.id} /> : null}
        linkText={x.info.h1}
        iconName={x.info.children ? types.nav.dynamic : x.info.special ? types.events.special : types.nav.outlet}
        iconPath={x?.icon ?? ``}
        expandElem={expanded === x.info.id}
        handleChange={handleChangeParent(x.info.id)}
        linkPath={x.path}
        colorScheme={x.info.colorScheme?.menu}
        childs = {expanded === x.info.id}
        {...{ open, colors, host, nativeLogo }}
      >
        {level2?.length > 0 && expanded === x.info.id && (
          <SubMenu
            menu={level2}
            lev={2}
            {...{
              level3,
              colors,
              mobile: !xsQuery,
              expand: expandedCategory,
              expandedCategory,
              expandedSubCategory,
              handleChange: handleChangeCategory,
              host
            }} />
        )
        }
      </DesktopMenu>
    </Fragment>
  )), [ menu, open, expanded ])

  // menu, open, expanded, colors, handleChangeParent

  const staticItems = useMemo(() => mainLayout?.static_menu.map((staticMenu, i) => (
    <Fragment key={handleKeyRandom(keyId, i)}>
      <DesktopMenu
        linkText={staticMenu.text}
        iconName={staticMenu.text}
        iconPath={staticMenu.icon.replace(`/assets`, ``) || ``}
        linkPath={staticMenu.url}
        {...{ open, colors, host, nativeLogo }}
      >
      </DesktopMenu>
    </Fragment>
  )), [ open, expanded, mainLayout ])

  useEffect(() => {
    !open && toggleExpanded(0)
  }, [ expanded, open ])

  return (
    <div
      id="desktop-menu"
      className={`shrink-0 whitespace-nowrap box-border border-r bg-white h-[100dvh] pt-[66px] overflow-y-auto hidden tablet:block`}
      style={{
        transition: `all 0.3s ease-in-out`,
        width: open ? `260px` : `60px`
      }}
    >
      {menuItems}
      {showStaticMenu && staticItems}
    </div>
  )
})

export default Menu
