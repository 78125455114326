'use client'
import { SessionProvider } from 'next-auth/react'

export const AuthProvider = ({ children, ...rest }) => {
  return (
    <SessionProvider {...rest} refetchInterval={0} refetchWhenOffline={false} refetchOnWindowFocus={false}>
      {children}
    </SessionProvider>
  )
}
