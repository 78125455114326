// export const example = (ownerState, theme, colors, constants) => ()
export const paperSlider = (
  ownerState, theme, colors, constants
) => ({
  width: `80%`,
  position: `absolute`,
  bottom: 0,
  heightt: 3,
  margin: theme.spacing(1),
  "& .MuiSlider-rail": {
    backgroundColor: `${colors.secondary}40`,
    opacity: 1,
  },
  "& .MuiSlider-track": {
    backgroundColor: colors.secondary,
    border: 0,
  },
  "& .MuiSlider-thumb": {
    width: 15,
    height: 15,
    "&:hover": {
      boxShadow: `0px 0px 0px 8px ${colors.secondary}40`,
    },
  },
})
