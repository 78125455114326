import { useCallMobile } from "@/hooks/utils/useDimension"
import { cmp } from "@/types"
import { handleKeyRandom } from "@/utils/functions"
import {
  Chip, Grid2, Paper, Typography
} from "@mui/material"
import parse from "html-react-parser"
import { useId, useRef } from "react"
import { useDraggable } from "react-use-draggable-scroll"

export const BoxParams = ({ ...props }) => {
  const {
    chips, mobile, desk, open, width, handleInsert, param, colors
  } = props
  const keyId = useId()
  const ref = useRef(null)
  const { events } = useDraggable(ref)
  const { MOBILE } = useCallMobile(`tablet`, mobile, 900)
  return (
    <>
      <Typography cmptype={cmp.texts} texcolor={colors.darkGrey} font={15} weight={700} lineHeight={1.5} mb={0} sx={{ paddingRight: 3 }}>
        {parse(`${param}:`)}
      </Typography>
      <Paper cmptype={cmp.boxChips} elevation={0}
        {...{
          mobile: MOBILE.toString(),
          desk: desk.toString(),
          open,
          width
        }} {...events}
        ref={ref} sx={{
          width: `inherit !important`,
          height: mobile ? `35px` : `inherit`,
          paddingBottom: 1.5
        }}>
        {chips?.map((x, i) => (
          <Grid2

            key={handleKeyRandom(keyId, i)}
            sx={{
              marginTop: 0.5,
              marginRight: 0.5,
              height: 26,
            }}
          >
            <Chip cmptype={cmp.chipBoxSearch} label={x?.toUpperCase()}
              fill={600} hgt={26}
              onClick={() => handleInsert(x)} />
          </Grid2>
        ))}
      </Paper>
    </>
  )
}
