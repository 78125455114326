import { createTheme } from "@mui/material/styles"
import { colors, constants } from "@/utils/global"
import { cmp, types } from "@/types"
import { func } from "."
const theme = createTheme()
const { GRID_MENU, ICON_SIZE_MOBILE } = constants

export const gridTheme = createTheme({
  components: {
    MuiGrid: {
      defaultProps: ({ ownerState }) => ({
        ...ownerState.cmptype === cmp.gridMenuBody && {
          component: `button`,
        },
      }),
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...ownerState.cmptype === cmp.gridMenuContainer && func.grid.gridMenuContainer(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.gridSubMenuBody && func.grid.gridSubMenuBody(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.gridMenuBody && func.grid.gridMenuBody(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accordionSumModCookie && func.grid.accordionSumModCookie(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.gridHome && func.grid.gridHome(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.gridContainerHome && func.grid.gridContainerHome(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.gridContainerLogin && func.grid.gridContainerLogin(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.gridContainerItemHome && func.grid.gridContainerItemHome(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.gridItemHome && func.grid.gridItemHome(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.homeFooter && func.grid.homeFooter(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.motoBoxSearch && func.grid.motoBoxSearch(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.gridItemFlex && func.grid.gridItemFlex(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.gridItemLogin && func.grid.gridItemLogin(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.headerTips && func.grid.headerTips(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.gridAccMenu && func.grid.gridAccMenu(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.gridAccItem && func.grid.gridAccItem(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.gridLegal && func.grid.gridLegal(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.gridsItem && func.grid.gridsItem(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.gridCookie && func.grid.gridCookie(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.gridFilter && func.grid.gridFilter(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
  },
})
