const TWDrawer = ({ className, children, cartDrawerOpen, toggleCartDrawer, width = `auto`, ...props }) => {
  const preventClose = e => e.stopPropagation()
  return (
    <div id="drawer-wrapper"
      className={`right-sidebar ${cartDrawerOpen ? `opacity-100 visible` : `opacity-0 invisible`}`}
      onClick={() => toggleCartDrawer && toggleCartDrawer(false)}
    >
      <div id="tw-drawer" { ...props }
        style={{ width }}
        className={`${className} right-sidebar-content ${cartDrawerOpen ? `translate-x-0` : `translate-x-full`}`}
        onClick={preventClose}
      >
        {children}
      </div>
    </div>
  )
}
export default TWDrawer
