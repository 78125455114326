import { createTheme } from "@mui/material"
import { colors, constants, fontQuicksand } from "@/utils/global"
import { cmp, types } from "@/types"
import { func } from "."
const theme = createTheme()

export const buttonTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        className: fontQuicksand.className,
        disableElevation: true,
        disableRipple: true,
        disableTouchRipple: true,
        size: `small`,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          ...ownerState.cmptype === cmp.selectLang && func.button.selectLang(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.metaMotoButton && func.button.metaMotoButton(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.msgCookies && func.button.msgCookies(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.dialogButtonCookie && func.button.dialogButtonCookie(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.metaMotoLink && func.button.metaMotoLink(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.defButton && func.button.defButton(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accButton && func.button.accButton(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accDfltBtn && func.button.accDfltBtn(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.selectBtn && func.button.selectBtn(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.filterBtn && func.button.filterBtn(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.filterClr && func.button.filterClr(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...ownerState.cmptype === cmp.filterClr && func.buttonGroup.filterClr(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        className: fontQuicksand.className,
        disableRipple: true,
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          padding: 0,
        }),
      },
    },
    MuiIconButton: {
      defaultProps: ({ ownerState }) => ({
        color: `inherit`,
        disableRipple: true,
      }),
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: 0,
          width: constants.ICON_SIZE,
          marginRight: theme.spacing(1.5),
          padding: 0,
          ...ownerState.cmptype === cmp.closeCookies && func.iconButton.closeCookies(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.closeMobileMenu && func.iconButton.closeMobileMenu(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.forwardMenu && func.iconButton.forwardMenu(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accDfltBtn && func.iconButton.accDfltBtn(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
  },
})
