import { createTheme } from "@mui/material/styles"
import { deepmerge } from "@mui/utils"
import {
  accordionTheme, buttonTheme, cardTheme, drawerTheme, gridTheme, lightTheme, listTheme, paperTheme, tableTheme, textTheme
} from "./styles"
const a = createTheme(deepmerge(paperTheme, accordionTheme))
const b = createTheme(deepmerge(a, drawerTheme))
const c = createTheme(deepmerge(b, gridTheme))
const d = createTheme(deepmerge(c, cardTheme))
const e = createTheme(deepmerge(d, buttonTheme))
const f = createTheme(deepmerge(e, textTheme))
const g = createTheme(deepmerge(f, listTheme))
const h = createTheme(deepmerge(g, tableTheme))
const i = createTheme(deepmerge(h, lightTheme))
export const eurobikesTheme = createTheme(i)
