/* eslint-disable default-param-last */
import { types } from "@/types"
import { initObj } from "./Obj"
export const loadingReducer = (state = initObj, action) => {
  switch (action.type) {
  case types.tools.linkLoading:
    return {
      ...state,
      linkLoading: action.payload,
    }

  default:
    return state
  }
}
