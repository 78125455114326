import { createTheme } from "@mui/material"
import { colors, constants, fontQuicksand } from "@/utils/global"
import { cmp, types } from "@/types"
import { func } from "."
const theme = createTheme()
export const textTheme = createTheme({
  components: {
    MuiChip: {
      defaultProps: {
        className: fontQuicksand.className,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          height: 40,
          width: `fit-content`,
          cursor: `pointer`,
          ...ownerState.cmptype === cmp.chipMenu && func.chip.chipMenu(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.chipCat && func.chip.chipCat(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.chipBoxSearch && func.chip.chipBoxSearch(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.chipRes && func.chip.chipRes(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accUser && func.chip.accUser(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accChip && func.chip.accChip(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.formChip && func.chip.formChip(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.historyChip && func.chip.historyChip(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.textMore && func.chip.textMore(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.chipPanelBuy && func.chip.chipPanelBuy(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.chipText && func.chip.chipText(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.chipLink && func.chip.chipLink(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiAutocomplete: {
      defaultProps: {},
      styleOverrides: {
        root: ({ ownerState }) => ({
          "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        className: fontQuicksand.className,
        fullWidth: true,
        autoFocus: false,
        variant: `outlined`,
        autoComplete: `off`,
        size: `small`,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          ...ownerState.cmptype === cmp.searchBar && func.textfield.searchBar(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.searchBarModal && func.textfield.searchBarModal(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.searchBarBrand && func.textfield.searchBarBrand(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.listPopper && func.textfield.listPopper(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.formInputs && func.textfield.formInputs(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.contactField && func.textfield.contactField(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.unitCounter && func.textfield.unitCounter(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accForm && func.textfield.accForm(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        className: fontQuicksand.className,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          textDecoration: `none`,
          color: colors.color,
          fontWeight: 500,
          fontSize: theme.typography.pxToRem(ownerState.font || 16),
          "& span.info": {
            textDecoration: `underline`,
            color: colors.main,
            fontSize: theme.typography.pxToRem(13),
            fontWeight: 500,
            cursor: `pointer`,
          },
          ...ownerState.cmptype === cmp.forwardMenu && func.typography.forwardMenu(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.schedule && func.typography.schedule(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.fooInfo && func.typography.fooInfo(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.ourShops && func.typography.ourShops(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.msgCookies && func.typography.msgCookies(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accordionSumModCookie && func.typography.accordionSumModCookie(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.hometext && func.typography.hometext(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.homeInfoTitle && func.typography.homeInfoTitle(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.homeInfoText && func.typography.homeInfoText(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.cardHomeSection && func.typography.cardHomeSection(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.productCardInfo && func.typography.productCardInfo(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.labelsTexts && func.typography.labelsTexts(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.texts && func.typography.texts(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.errorPay && func.typography.errorPay(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.headerTips && func.typography.headerTips(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.gridAccMenu && func.typography.gridAccMenu(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accDesc && func.typography.accDesc(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accUser && func.typography.accUser(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.formTexts && func.typography.formTexts(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.orderTexts && func.typography.orderTexts(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.legalTitle && func.typography.legalTitle(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.legalText && func.typography.legalText(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.legalSubTitle && func.typography.legalSubTitle(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.supportSubTitle && func.typography.supportSubTitle(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.legalSubDoubleTitle && func.typography.legalSubDoubleTitle(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.textQuestion && func.typography.textQuestion(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.textHistory && func.typography.textHistory(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.textStore && func.typography.textStore(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.textTitleH && func.typography.textTitleH(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.breadcrumb && func.typography.breadcrumb(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.filterBtn && func.typography.filterBtn(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.filterPrc && func.typography.filterPrc(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.textSelect && func.typography.textSelect(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.bikeCard && func.typography.bikeCard(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.emptyText && func.typography.emptyText(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.lineText && func.typography.lineText(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.stepCartTitle && func.typography.stepCartTitle(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.errorMsg && func.typography.errorMsg(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiFormControlLabel: {
      defaultProps: {},
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          alignItems: `center`,
          marginTop: 0,
          marginLeft: 0,
          ...ownerState.cmptype === cmp.checkLabel && {
            marginBottom: theme.spacing(2),
            "& a": {
              color: colors.color,
            },
          },
          ...ownerState.cmptype === cmp.accCheck && {
            fontWeight: 700,
          },
        }),
      },
    },
  },
})
