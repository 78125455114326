import { constructScript, removeScript } from "@/utils/functions/scripts"
import { useEffect } from "react"

export const MicroOrganization = ({ hostname }) => {
  const { business, domain } = hostname
  const { thumb, alias, webUrl } = business
  const data = JSON.stringify({
    "@context": `http://www.schema.org`,
    "@type": `Organization`,
    url: `${webUrl}${domain}`,
    logo: {
      "@type": `ImageObject`,
      contentUrl: `${thumb}/image/favicon.png`,
      datePublished: `2022-10-14`,
      description: `${alias} Logo`,
      name: alias,
    },
  })

  useEffect(() => {
    const name = `micro-organization`
    constructScript(`script`, name, data)

    return () => {
      removeScript(name)
    }
  }, [data])

  return null
}
