import { createTheme } from "@mui/material/styles"
import { colors, constants, fontQuicksand } from "@/utils/global"
import { cmp, types } from "@/types"
import { func } from "."
const theme = createTheme()
export const tableTheme = createTheme({
  components: {
    MuiTableRow: {
      defaultProps: {},
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          "&:nth-of-type(odd)": {
            backgroundColor: `${colors.lightGrey}`,
          },
          "&:nth-of-type(even)": {
            backgroundColor: colors.white,
          },
          ...ownerState.cmptype === cmp.styleRow && func.table.styleRow(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiTableCell: {
      defaultProps: {},
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          ...ownerState.cmptype === cmp.stickyCell && func.table.stickyCell(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.stickyCellCookie && func.table.stickyCellCookie(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.styleCell && func.table.styleCell(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
  },
})
