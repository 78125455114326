/* eslint-disable max-lines */
import { createTheme } from "@mui/material/styles"
import { colors as muicolor } from "@mui/material"
import { colors, constants, fontQuicksand } from "@/utils/global"
import { cmp, types } from "@/types"
import { func } from "."
const theme = createTheme()
const {
  DRAWER,
  DESKTOP_MENU: { TOP, BOTTOM, ICON_SIZE },
} = constants

export const lightTheme = createTheme({
  palette: {
    mode: `light`,
    primary: {
      main: colors.color,
    },
    custom: {
      main: colors.secondary,
    },
    eurobikes: {
      main: colors.main,
    },
    secondary: {
      main: colors.lightGrey3,
    },
    info: {
      main: colors.white,
    },
    action: {
      disabled: colors.lightGrey3,
      disabledBackground: colors.color,
    },
  },
  divider: colors.lightGrey2,
  components: {
    MuiPopper: {
      defaultProps: {
        role: undefined,
        disablePortal: true,
      },
      styleOverrides: {
        root: {
          zIndex: theme.zIndex.drawer + 5,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        className: fontQuicksand.className,
        underline: `none`,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          ...ownerState.cmptype === cmp.chipLabel && func.link.chipLabel(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.footerLinks && func.link.footerLinks(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.msgCookies && func.link.msgCookies(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.links && func.link.links(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.navLink && func.link.navLink(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.linker && func.link.linker(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.cartLink && func.link.cartLink(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
        position: `fixed`,
        width: `calc(100% - ${DRAWER}px)`,
      },
      styleOverrides: {
        root: {
          backgroundColor: colors.main,
          height: `auto`,
          zIndex: theme.zIndex.drawer + 1,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...ownerState.cmptype === cmp.filterClr && {
            boxShadow: theme.shadows[1],
            fontSize: theme.typography.pxToRem(14),
          },
        }),
      },
    },
    MuiToolbar: {
      default: {},
      styleOverrides: {
        root: ({ ownerState }) => ({
          minHeight: `calc(${ownerState?.mdmenu === `true` ? TOP : 0}px + ${BOTTOM}px)!important`,
          height: `calc(${ownerState?.mdmenu === `true` ? TOP : 36}px + ${BOTTOM}px)`,
          ...ownerState.cmptype === cmp.infoMenu && {
            height: ownerState?.ismobile === `true` ? `auto` : TOP,
            background: ownerState.background,
            color: ownerState.color,
            minHeight: `${ownerState.height}px!important`,
            fontSize: theme.typography.pxToRem(14),
            display: `flex`,
            alignItems: `center`,
            paddingRight: ownerState.ismobile === `true` ? 0 : `inherit`,
            "& div": {
              display: `flex`,
              alignItems: `center`,
            },
            "& .menu-left": {
              marginRight: `auto`,
            },
            "& .menu-center": {
              marginLeft: `auto`,
            },
            "& .menu-right": {
              marginLeft: `auto`,
              width: ownerState.ismobile === `true` ? 110 : `inherit`,
              justifyContent: ownerState.ismobile === `true` ? `space-around` : `inherit`,
            },
            "& .svg-class-logo": {
              height: `calc(${BOTTOM}px - ${ownerState.xs === `true` ? 16 : 12}px)`,
              marginRight: 10,
              color: colors.white,
            },
            [`.svg-class-${types._menu.menuOpen},
						.svg-class-${types._menu.menuClose}`]: {
              width: ICON_SIZE,
              marginRight: theme.spacing(1.5),
            },
          },
        }),
      },
    },

    // BADGE
    MuiBadge: {
      defaultProps: {
        className: fontQuicksand.className,
        max: constants.BADGE.max,
        showZero: false,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          ...ownerState.cmptype &&
            cmp.bikeCard && {
            "& .MuiBadge-badge": {
              right: 48,
              top: 25,
              height: `30px`,
              borderRadius: `8px`,
              textAlign: `center`,
              border: `none`,
              padding: `8px`,
              backgroundColor: colors.main,
            },
          },
        }),
      },
    },
    MuiDivider: {
      defaultProps: {
        variant: `middle`,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderColor: colors.white,
          borderWidth: 1,
          ...ownerState.cmptype === cmp.notHelmet && {
            margin: theme.spacing(1.5, 1),
            borderColor: `${colors.darkGrey2}55`,
            borderWidth: 1,
          },
          ...ownerState.cmptype === cmp.notHelmet2 && {
            margin: theme.spacing(ownerState.mobile === `false` ? 0 : 1.5, `auto`),
            marginBottom: ownerState.mb || `inherit`,
            borderColor: `${colors.darkGrey2}55`,
            borderWidth: 1,
            width: ownerState.mobile === `false` ? `auto` : ownerState.width || `100%`,
          },
          ...ownerState.cmptype === cmp.notHelmet3 && {
            margin: theme.spacing(0, `auto`),
            marginBottom: ownerState.mb || `inherit`,
            borderColor: colors.lightGrey2,

            // borderColor: `${colors.darkGrey2}55`,
            borderWidth: 1,
            width: ownerState.mobile === `false` ? `auto` : ownerState.width || `100%`,
          },
          ...ownerState.cmptype === cmp.notHelmet4 && {
            margin: `${theme.spacing(ownerState.my, ownerState.mx)}!important`,
            marginBottom: ownerState.mb || `inherit`,
            borderWidth: ownerState.bw,
            width: ownerState.wd || `100%`,
            borderColor: ownerState.bdc || colors.white,
          },
          ...ownerState.cmptype === cmp.footerDivider && {
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(1.5),
            borderColor: `${colors.darkGrey2}55`,
            borderWidth: 1,
          },
          ...ownerState.cmptype === cmp.dividerHelmet && {
            width: ownerState.orientation !== `vertical` ? `100%` : `auto`,
            margin: theme.spacing(1.5, 0),
            alignItems: ownerState.flex,
            padding: `0 20px`,
            "& .svg-class-helmet": {
              background: `transparent`,
              width: ownerState.size,
              height: ownerState.size,
              display: `flex`
            },
            "& .st0": {
              fill: `${ownerState.color || colors.lightGrey2}`,
            },
            "&::before, ::after": {
              borderColor: ownerState.color || colors.lightGrey2,
              width: `inherit`,
            },
          },
        }),
      },
    },
    MuiDialog: {
      defaultProps: {},
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...ownerState.cmptype === cmp.modal && func.dialog.modal(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.pickupModal && func.dialog.pickupModal(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.payModal && func.dialog.payModal(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        className: fontQuicksand.className,
        color: colors.color,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          ...ownerState.cmptype === cmp.dialogTitleCookie && func.dialog.dialogTitleCookie(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.pickupTitleModal && func.dialog.pickupTitleModal(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiDialogContent: {
      defaultProps: {
        className: fontQuicksand.className,
        color: colors.color,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          ...ownerState.cmptype === cmp.dialogContentCookie && func.dialog.dialogContentCookie(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.dialogSubContentCookie && func.dialog.dialogSubContentCookie(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.pickupContentModal && func.dialog.pickupContentModal(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.payContentModal && func.dialog.payContentModal(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: ownerState.checked ? colors.secondary : colors.darkGrey,
          padding: theme.spacing(0.5, 1.12),
          "& .MuiSvgIcon-root": { fontSize: theme.typography.pxToRem(16) },
          "&.Mui-checked": {
            color: colors.secondary,
          },
          ...ownerState.cmptype === cmp.checkLegal && {
            color: ownerState.checked ? colors.secondary : muicolor.red[`A700`],
          },
          ...ownerState.cmptype === cmp.filterBtn && {
            padding: theme.spacing(0.5, 0.2),
            color: colors.color,
            "& .MuiSvgIcon-root": { fontSize: theme.typography.pxToRem(14) },
            "&.Mui-checked": {
              color: colors.secondary,
            },
          },
        }),
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...ownerState.cmptype === cmp.paperSlider && func.slider.paperSlider(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiPagination: {
      defaultProps: {},
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          ...ownerState.cmptype === cmp.page && {
            "& button": {
              "&:hover": {
                backgroundColor: colors.pagination.deactivated.background,
                color: colors.pagination.deactivated.color,
              },
              "&.Mui-selected": {
                backgroundColor: `${colors.pagination.active.background}!important`,
                color: `${colors.pagination.active.color}!important`,
              },
            },
          },
        }),
      },
    },
    MuiRating: {
      defaultProps: {},
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          "& .MuiRating-iconEmpty": {
            color: colors.gold,
          },
        }),
      },
    },
  },
})
