'use client'
import { MobileContext } from "@/context"
import { LoadingContext } from "@/context/tools/loading"
import { ToolsContext } from "@/context/tools/tools"
import { getControlledSections } from "@/utils/functions"
import Link from "next/link"
import { useContext, useMemo } from "react"
import { Currency } from "../select/Currency"
import Lang from "../select/Lang"

const Info = ({
  hostname, xsQuery = false, closeMenu, ...props
}) => {
  const { mobile, webPages, host, events } = hostname
  const controlledSections = getControlledSections(webPages, `infoBar`)
  const { tools: { colors, texts }} = useContext(ToolsContext)
  const main_layout = useMemo(() => texts.main_layout, [texts])
  const infoTexts = useMemo(() => !!main_layout && main_layout.info.filter(elem => controlledSections[elem.url.replace(`/`, ``)]), [main_layout])
  const isMobile = useMemo(() => mobile || xsQuery, [ mobile, xsQuery ])
  const { modules } = useContext(LoadingContext)
  const { toggleOpenSwipMobile } = useContext(MobileContext)

  return !!main_layout && (
    <>
      {/* // TODO - añadir eventos */}
      {/* {!!events && events[types.events.info] && (
        <div className={`text-sm flex items-center ${isMobile ? `p-1` : `p-0`}`}>
          {parse(web?.nav.info.info.text0 || ``)}
          <span>
            {parse(web?.nav.info.info.text1 || ``)}
          </span>
        </div>
      )} */}
      {infoTexts.map((infoElem, i) => {
        return (
          <div key={`menu_info_${i}`} className={`flex items-center mr-5 ${isMobile ? `p-1` : `p-0`} ${i !== 0 || isMobile ? `` : `ml-auto`} 
          text-sm font-normal `}
          style={{ height: `20px`, marginTop: isMobile && `5px` }} >
            <Link
              className="no-underline text-neutral-600 font-medium text-sm tablet:text-sm hover:text-black"
              onClick={() => {
                toggleOpenSwipMobile(false)
                modules.handleLinkLoadingPath(infoElem.url)
              }}
              aria-label="open-landing-shop"
              href={host + infoElem.url}
              passHref
              scroll={true}
              style={{ textDecoration: `none` }}
            >
              {/* <p className="" onClick={() => isMobile && closeMenu(false)} style={{ height: `auto !important`, margin: `2px 10px !important` }} >
              </p> */}
              {infoElem.text}
            </Link>
          </div>
        )
      })}
      {!!controlledSections?.language && <div className={`flex items-center ${isMobile ? `p-1` : `p-0`} mr-2`}>
        <Lang {...{
          infoLang: main_layout.lang,
          colors,
          mobile,
          hostname
        }} />
      </div> }
      {!mobile && !xsQuery && !!controlledSections?.currency && <Currency {...{
        hostname,
        colors,
        textcolor: colors.darkGrey
      }} />}
    </>
  )
}

export default Info
