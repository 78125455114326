'use client'
import { useMediaQuerys } from "@/hooks/utils/useMediaQuerys"
import { handleKeyRandom } from "@/utils/functions"
import parse from "html-react-parser"
import { Suspense, useContext, useEffect, useId, useMemo, useState } from "react"
import { DropDownArrows } from "../../../basket/subcomponent/components/subcomponents/DropDownArrows"
import { ToolsContext } from "@/context/tools/tools"

export const OurShops = ({ colors }) => {
  const { tools: { texts }} = useContext(ToolsContext)
  const main_layout = useMemo(() => texts.main_layout, [texts])
  const idKey = useId()
  const { xsQuery } = useMediaQuerys()
  const [ showInfo, setShowInfo ] = useState(xsQuery)
  const fieldType = {
    tlf: `tel`,
    mail: `mailto`,
  }

  useEffect(() => {
    setShowInfo(xsQuery)
  }, [xsQuery])

  return (
    <Suspense fallback={<div></div>}>
      <div style={{ color: colors.footer.color }} className="w-52 tablet:w-auto">
        <span className={`flex items-center cursor-pointer tablet:cursor-default`} onClick={() => setShowInfo(!showInfo)}>
          <p className="tablet:text-base font-bold uppercase py-1 px-0" style={{ color: colors.footer.color }}>
            {parse(main_layout?.footer_stores_title || ``)}
          </p>
          <DropDownArrows style={{ color: colors.footer.color }} open={showInfo} className="tablet:hidden" />
        </span>
        <span className={showInfo ? `block` : `hidden`}>
          {main_layout?.footer_stores.map((store, i) => (
            <div key={handleKeyRandom(idKey, i)} className="bg-transparent px-1 mb-1">
              <p className={`font-bold py-0 px-1 uppercase`} style={{ color: colors.footer.color }}>
                {parse(store.name)}
              </p>
              {Object.entries(store).filter(([field]) => field !== `name`).
                map(([ field, value ]) => value.length > 0 && <p key={`store_elem_${store.name}_${field}`} className={`font-normal py-0 pl-2 tablet:pl-1 m-0`} style={{ color: colors.footer.color }} >
                  {fieldType[field] ? <a style={{ color: colors.footer.color }} href={`${fieldType[field]}:${value}`}>{parse(value)}</a> : parse(value)}
                </p>)}
            </div>
          ))}
        </span>
      </div>
    </Suspense>
  )
}
