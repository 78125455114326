'use client'
import { MicroLocalBusiness } from "./components/MicroLocalBusiness"
import { MicroOrganization } from "./components/MicroOrganization"
import { MicroWebSite } from "./components/MicroWebSite"

export const MicroDataGlobal = ({ ...props }) => {
  return (
    <>
      <MicroWebSite {...props}/>
      <MicroLocalBusiness {...props}/>
      <MicroOrganization {...props}/>
    </>
  )
}
