// export const example = (ownerState, theme, colors, constants) => ()
export const chipLabel = (
  ownerState, theme, colors, constants
) => ({
  background: `transparent`,
  width: ownerState.label === `` ? 35 : ownerState.wd || `auto`,
})
export const footerLinks = (
  ownerState, theme, colors, constants
) => ({
  textDecoration: `none`,
  color: colors.footer.color,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 400,
  padding: theme.spacing(
    0, 0, 0.5, 0
  ),
})
export const msgCookies = (
  ownerState, theme, colors, constants
) => ({
  fontSize: theme.typography.pxToRem(ownerState.font),
  fontWeight: ownerState.weight,
  textDecoration: `none`,
  color: colors.footer.link,
})
export const links = (
  ownerState, theme, colors, constants
) => ({
  marginBottom: 5,
  fontSize: theme.typography.pxToRem(14),
  color: colors.color,
  fontWeight: 400,
})
export const navLink = (
  ownerState, theme, colors, constants
) => ({
  marginBottom: 12,
  [theme.breakpoints.up(`md`)]: {
    fontSize: theme.typography.pxToRem(16),
    textAlign: `left`,
  },
  fontSize: theme.typography.pxToRem(15),
  color: ownerState.textcolor || colors.secondary,
  textDecoration: `none`,
})
export const linker = (
  ownerState, theme, colors, constants
) => ({
  fontSize: theme.typography.pxToRem(ownerState.font || 14),
  fontWeight: ownerState.weight || 400,
  color: ownerState.texcolor || colors.footer.link,
  textDecoration: ownerState.deco || `inherit`,
  "&:hover": {
    color: `${ownerState.texcolor} !important`,
    textDecoration: `${ownerState.deco || `inherit`} !important`,
  },
  "&.tild:hover": {
    color: `${ownerState.texcolor || colors.footer.link} !important`,
  },
})
export const cartLink = (
  ownerState, theme, colors, constants
) => ({
  textDecoration: `none`,
  background: colors.lightGrey2,
  display: `flex`,
  alignItems: `center`,
  marginTop: theme.spacing(1.5),
  padding: theme.spacing(0.5, 2),
  borderRadius: `10px`,
})
