'use client'
import { ToolsContext } from "@/context/tools/tools"
import { memo, useContext, useMemo } from "react"
import { IoCheckmark } from "react-icons/io5"

const HeaderTips = () => {
  const { tools: { colors, texts }} = useContext(ToolsContext)
  const globalTexts = useMemo(() => texts.generic, [texts])
  return globalTexts ? <>
    <div className="tablet:hidden flex w-full flex-wrap items-center justify-around bg-slate-100 p-1">
      <div key={`tip_mobile`} className={`flex items-center`}>
        <IoCheckmark style={{ color: colors.secondary }}/>
        <p style={{ color: colors.secondary }} className="font-bold ml-2 uppercase whitespace-nowrap">{globalTexts.tips[globalTexts.tips.length - 1]}</p>
      </div>
    </div>
    <div className="hidden tablet:flex w-full flex-wrap items-center justify-around bg-slate-100 p-1">
      {globalTexts.tips.map((x, i) => (
        <div key={`tip_${i}`} className={`flex items-center mx-1`}>
          <IoCheckmark style={{ color: colors.secondary }}/>
          <p style={{ color: colors.secondary }} className="font-bold ml-2 uppercase whitespace-nowrap">{x}</p>
        </div>
      ))}
    </div>
  </> : <div id="header-tips" className="hidden tablet:flex w-full flex-wrap items-center justify-around bg-slate-100 p-1 h-8" />
}

export default memo(HeaderTips)

