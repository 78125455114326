'use client'
import CartDrawer from '@/app/(web)/_components/basket/CartDrawer'
import TWLabel from '@/components/tailwind/Label'
import { CartContext } from '@/context'
import { LoadingContext } from '@/context/tools/loading'
import { types } from '@/types'
import { getLinesTotalAmount } from '@/utils/functions/cart'
import { constants } from '@/utils/global'
import IconsSVG from "@/utils/icons/IconsSVG"
import Link from 'next/link'
import React, { useContext, useEffect, useRef, useState } from 'react'

const NavLinker = ({ tcl, ...props }) => {
  const { hostname: { mobile, colors }} = props
  const [ badgeContent, setBadgeContent ] = useState(0)
  const bskRef = useRef()
  const { cart, toggleCartDrawer } = useContext(CartContext)
  const { cartDrawerOpen, basket } = cart
  const { lines } = basket
  const { modules } = useContext(LoadingContext)
  useEffect(() => {
    getLinesTotalAmount(lines, setBadgeContent)
  }, [lines])

  const buttonClass = `text-nowrap rounded-none bg-inherit text-sm font-bold ${mobile ? `mr-0` : `p-0 pr-2 mr-3 tablet:mr-0`}`

  return (
    <>
      {props.name !== types._menu.cart ? (
        <Link
          onClick={() => modules.handleLinkLoadingPath(props.href)}
          aria-label="navLinker"
          href={props.href}
          passHref
          scroll={true}
          style={{
            textDecoration: `none`,
            background: `transparent`,
            width: `auto`
          }}
        >
          <button aria-label={props.name} id={props.name} style={{ color: tcl }} className={buttonClass} >
            <IconsSVG path={`/img/client-icons/web-${props.name}.svg`} name={props.name} fillcolor={tcl} size={constants.ICON_SIZE} />
            {props.label}
          </button>
        </Link>
      ) : (
        <>
          <div ref={bskRef} onClick={() => toggleCartDrawer(true)}>
            <button aria-label={props.name} id={props.name} style={{ color: tcl }} className={`${buttonClass} relative`} >
              {badgeContent > 0 && <TWLabel {...{ colors }} bgColor={colors.tild} minW className={badgeContent < 10 ? `px-2` : `px-1`} rounded posTop="-7px" posLeft={`10px`} >
                {badgeContent >= 99 ? `+99` : badgeContent}
              </TWLabel>}
              <IconsSVG path={`/img/client-icons/web-${props.name}.svg`} name={props.name} fillcolor={tcl} size={16 + (mobile ? 8 : 0)} m={badgeContent > 0 ? `0 15px 0 0` : `0 5px`}/>
              {props.label}
            </button>
          </div>
          <CartDrawer {...props} {...{ cartDrawerOpen, toggleCartDrawer }} />
        </>
      )}
    </>
  )
}

export default NavLinker
