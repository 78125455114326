"use client"

import {
  CartProvider,
  CatalogueProvider,
  CookiesProvider,
  FiltersProvider,
  MenuProvider,
  MobileProvider,
  ProductsProvider,
  ToastifyProvider
} from "@/context"
import { BasketProvider } from "@/context/basket/BasketProvider"
import { CartStepsProvider } from "@/context/cart-steps/CartStepsProvider"
import { SearchProvider } from "@/context/search"
import { LabelsProvider } from "@/context/tools/labels"
import { LoadingProvider } from "@/context/tools/loading"
import { ToolsProvider } from "@/context/tools/tools"
import { useEffectOnce } from "@/hooks/utils/useEffectOnce"
import { eurobikesTheme } from "@/themes"
import { googleCookies } from "@/utils/functions/google"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { useRouter } from "next/navigation"
import { useEffect, useMemo } from "react"

export const Providers = ({ children, hostname }) => {
  const router = useRouter()
  const paypal = useMemo(() => hostname?.arrPaymethodsTypes?.includes(4), [hostname])
  const optionPaypal = paypal ? {
    "client-id": process.env.PAYPAL || process.env.NEXT_PUBLIC_PAYPAL,
    currency: `EUR`,
    intent: `capture`,
    components: `buttons,marks,funding-eligibility,messages`,
  } : {}

  const ipInfo = {
    code: `ES`,
    country: `Spain`,
    region: `Madrid`,
  }
  const data = {
    hostname,
    ipInfo
  }

  useEffect(() => {
    googleCookies()
  })

  useEffectOnce(() => {
    if (window.top !== window.self) {
      window.top.location.href = document.location.href
    }
    if (process.env.NEXT_PUBLIC_BUILD === `pro` && !window.location.host.includes(`www.`)) {
      router.replace(hostname.business.webUrl + hostname.domain + window.location.pathname + window.location.search)
    }
  }, [])

  const getProviders = prop => (
    <ToolsProvider {...data}>
      <CartProvider {...data} >
        <BasketProvider >
          <CartStepsProvider {...data}>
            <ThemeProvider theme={eurobikesTheme}>
              <CssBaseline />
              <CookiesProvider {...data}>
                <LoadingProvider {...data}>
                  <LabelsProvider {...data}>
                    <MenuProvider {...data}>
                      <SearchProvider {...data}>
                        <MobileProvider {...data}>
                          <ToastifyProvider {...data}>
                            <CatalogueProvider {...data}>
                              <FiltersProvider {...data}>
                                <ProductsProvider {...data}>
                                  {prop}
                                </ProductsProvider>
                              </FiltersProvider>
                            </CatalogueProvider>
                          </ToastifyProvider>
                        </MobileProvider>
                      </SearchProvider>
                    </MenuProvider>
                  </LabelsProvider>
                </LoadingProvider>
              </CookiesProvider>
            </ThemeProvider>
          </CartStepsProvider>
        </BasketProvider>
      </CartProvider>
    </ToolsProvider>
  )
  return (
    <>
      {paypal ? <PayPalScriptProvider options={optionPaypal} forceReRender={optionPaypal}>
        {getProviders(children)}
      </PayPalScriptProvider> : getProviders(children)}
    </>
  )
}
