// export const example = (ownerState, theme, colors, constants) => ()
export const modal = (
  ownerState, theme, colors, constants
) => ({
  top: 0,
  "& .MuiBackdrop-root": {
    backgroundColor: `rgba(0, 0, 0, 0.1)`,
  },
  "& .MuiDialog-paper": {
    padding: theme.spacing(
      2, 2, 2, 2
    ),
    borderRadius: theme.spacing(
      1, 1, 1, 1
    ),
    backgroundColor: colors.lightGrey,
    color: colors.color,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
  },
  "& .MuiDialog-container": {
    alignItems: `baseline`,
  },
})
export const dialogTitleCookie = (
  ownerState, theme, colors, constants
) => ({
  marginBottom: theme.spacing(2),
  paddingTop: 0,
  paddingBottom: 0,
  fontWeight: ownerState.weight,
  fontSize: theme.typography.pxToRem(ownerState.font),
})
export const dialogContentCookie = (
  ownerState, theme, colors, constants
) => ({
  textAlign: `justify`,
  fontSize: theme.typography.pxToRem(ownerState.font),
  padding: theme.spacing(
    1, ownerState.ismobile === `false` ? 5 : 2.5, 1, ownerState.ismobile === `false` ? 5 : 2.5
  ),
})
export const dialogSubContentCookie = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: colors.lightGrey,
  textAlign: `justify`,
  fontSize: theme.typography.pxToRem(10),
  padding: `${theme.spacing(1.5)}!important`,
  "& .cookies": {
    paddingTop: 5,
    textTransform: `capitalize`,
    fontWeight: 700,
    "& .li": {
      paddingLeft: theme.spacing(3),
    },
    "& li": {
      fontWeight: `400`,
      paddingTop: theme.spacing(1),
    },
  },
})
export const pickupModal = (
  ownerState, theme, colors, constants
) => ({
  top: 0,
  "& .MuiBackdrop-root": {
    backgroundColor: `rgba(0, 0, 0, 0.1)`,
  },
  "& .MuiDialog-paper": {
    minWidth: ownerState.mobile === `true` ? 500 : `100vw`,
    maxWidth: ownerState.mobile === `true` ? 1000 : `100vw`,
    padding: theme.spacing(ownerState.mobile === `true` ? 2 : 0),
    borderRadius: theme.spacing(ownerState.mobile === `true` ? 1 : 0),
    backgroundColor: colors.white,
    color: colors.color,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    overflowX: `hidden`,
  },
  "& .MuiDialog-container": {
    alignItems: `center`,
    width: `100vw`,
  },
})
export const pickupTitleModal = (
  ownerState, theme, colors, constants
) => ({
  padding: theme.spacing(0.25, ownerState.mobile === `true` ? 0 : 1),
  height: `auto`,
})
export const pickupContentModal = (
  ownerState, theme, colors, constants
) => ({
  padding: theme.spacing(ownerState.mobile === `true` ? 0 : 1),
  display: `flex`,
  flexDirection: ownerState.mobile === `true` ? `row` : `column`,
  height: ownerState.mobile === `true` && `auto`,
  justifyContent: `space-evenly`,
})

export const payModal = (
  ownerState, theme, colors, constants
) => ({
  top: 0,
  "& .MuiBackdrop-root": {
    backgroundColor: `rgba(0, 0, 0, ${ownerState.backdrop === `true` ? 0.40 : 0.30})`,
  },
  "& .MuiDialog-paper": {
    minWidth: ownerState.mobile === `true` ? 500 : `100vw`,
    padding: theme.spacing(ownerState.mobile === `true` ? 0 : 0),
    borderRadius: theme.spacing(ownerState.mobile === `true` ? 1 : 0),
    backgroundColor: colors.white,
    color: colors.color,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    overflowX: `hidden`,
  },
  "& .MuiDialog-container": {
    alignItems: `center`,
    width: `100vw`,
  },
})

export const payContentModal = (
  ownerState, theme, colors, constants
) => ({
  padding: theme.spacing(0),
  height: `auto`,
})
