/* eslint-disable max-lines */

import { types } from "@/types"
import { fontQuicksand } from "@/utils/global"

// export const example = (ownerState, theme, colors, constants) => ()
export const paperLang = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: colors.lightGrey,
  borderRadius: theme.spacing(
    0, 0, 1, 1
  ),
})
export const accMenu = (
  ownerState, theme, colors, constants
) => ({
  height: `100%`,
  width: `100%`,
  minWidth: constants.DRAWER + 75,
  backgroundColor: colors.backGrey,
  borderRadius: 10,
})
export const mobileMenu = (
  ownerState, theme, colors, constants
) => ({
  width: `82.5vw`,
  borderRadius: 0,
  display: `flex`,
  flexDirection: `column`,
  "& .svg-class-logo-mobile": {
    height: `32px`,
    marginTop: theme.spacing(0.5),
    "& .st0": {
      fill: colors.main,
    },
  },
})
export const sectionMenu = (
  ownerState, theme, colors, constants
) => ({
  display: `flex`,
  flexDirection: `row`,
  alignItems: `center`,

  // borderBottom: `1px solid ${colors.darkGrey2}40`,
  position: `sticky`,
  top: 0,
  backgroundColor: colors.lightGrey,

  // zIndex: theme.zIndex.drawer + 1,
  height: constants.DESKTOP_MENU.BOTTOM,
})
export const forwardMenu = (
  ownerState, theme, colors, constants
) => ({
  display: `flex`,
  flexDirection: `row`,
  alignItems: `center`,
  width: `100%`,
  backgroundColor: `transparent`,
  [`& .svg-class-${types.nav.dynamic}`]: {
    width: constants.DESKTOP_MENU.ICON_SIZE + 0,
    "& .st0": {
      fill: colors.main,
    },
  },
})
export const FooterMain = (
  ownerState, theme, colors, constants
) => ({
  width: `100%`,
  backgroundColor: colors.footer.background,
  minHeight: constants.FOOTER_HEIGHT,
  "& .svg-class-footer-icons": {
    width: `25px`,
    "& .st0, .cls-1": {
      fill: colors.footer.subColor,
    },
  },
  "& .svg-class-logo": {
    height: 45,
    "& .st0, .cls-1": {
      fill: colors.footer.color,
    },
  },
})
export const ourShops = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: `transparent`,
  padding: theme.spacing(
    0, 3, 2, 0
  ),
})
export const msgCookies = (
  ownerState, theme, colors, constants
) => ({
  color: colors.cookies.title,
  backgroundColor: colors.white,
  width: ownerState.mobile === `true` ? `100%` : `auto`,
  marginLeft: `0%`,
  marginRight: `0%`,
  padding: theme.spacing(3),
  zIndex: theme.zIndex.drawer + 100,
  transition: theme.transitions.create(`bottom`, {
    easing: theme.transitions.easing.sharp,
    duration: `1s`,
  }),
})
export const bannerHomeImg = (
  ownerState, theme, colors, constants
) => ({
  width: `100%`,
  height: ownerState.height || 225,
  backgroundImage: `url(${ownerState.path})`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: ownerState.mobile === `true` ? `center ${ownerState.align || `center`}` : `center`,
  backgroundSize: ownerState.cover || `cover`,
  display: `flex`,
  padding: theme.spacing(0),
  borderRadius: 0,
})
export const sectionHome = (
  ownerState, theme, colors, constants
) => ({
  alignSelf: `baseline`,
  width: `calc(${ownerState.mobile === `true` ? `98vw` : `92vw`} - ${ownerState.mobile === `true` ? 0 : constants.DRAWER}px)`,
  display: `flex`,
  justifyContent: ownerState.desk === `true` ? `center` : `flex-start`,
  flexWrap: `nowrap`,
  overflowX: `auto`,
  overflowY: `hidden`,
  minHeight: 100,
  maxHeight: ownerState.height,
})
export const cardHomeSection = (
  ownerState, theme, colors, constants
) => ({
  width: `${ownerState.width}px` || `100%`,
  height: `${ownerState.height}px` || `225px`,
  backgroundImage: `url(${ownerState.path})`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: ownerState.mobile === `true` ? `center ${ownerState.align || `right`}` : `center`,
  backgroundSize: ownerState.cover || `cover`,
  display: `flex`,
  padding: theme.spacing(0),
  borderRadius: 15,
})
export const brandLink = (
  ownerState, theme, colors, constants
) => ({
  width: 100,
  height: 100,
  backgroundColor: colors.white,
  filter: `grayscale(1)`,
  margin: theme.spacing(1),
  borderRadius: 15,
  backgroundImage: `url(${ownerState.path})`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: `center`,
  backgroundSize: `contain`,
  "&:hover": {
    filter: `grayscale(0)`,
  },
})
export const motoBoxSearch = (
  ownerState, theme, colors, constants
) => ({
  minHeight: 95,
  display: `flex`,
  width: `100%`,
  justifyContent: `center`,
  backgroundColor: `transparent`,
  position: ownerState.mobile === `true` ? `inherit` : `relative`,
  top: ownerState.mobile === `true` ? 0 : -45,
  zIndex: theme.zIndex.drawer,
})
export const paperPopper = (
  ownerState, theme, colors, constants
) => ({
  zIndex: theme.zIndex.drawer + 5,
  marginTop: theme.spacing(0.8),
  backgroundColor: ownerState.bg || colors.white,
  borderRadius: theme.spacing(ownerState.br || 1),
  width: ownerState.wd || `inherit`,
  border: ownerState.bd || `inherit`,
  padding: theme.spacing(
    0.5, 0, 0, 0
  ),
})
export const productCard = (
  ownerState, theme, colors, constants
) => ({
  margin: theme.spacing(0),
  borderRadius: theme.spacing(2),
  display: `flex`,
  justifyContent: `center`,
  alignItems: `start`,
  "& img": {
    borderRadius: theme.spacing(2),
    objectFit: `contain !important`,
    width: `${ownerState.mobile === `true` ? 164 : 170 + ownerState.plus}px!important`,
    height: `${ownerState.mobile === `true` ? 164 : 170 + ownerState.plus}px!important`
  },
})
export const productCardInfo = (
  ownerState, theme, colors, constants
) => ({
  padding: theme.spacing(1, ownerState.mobile === `false` ? 1.5 : 2.5),
  backgroundColor: colors.white,
})
export const paperRating = (
  ownerState, theme, colors, constants
) => ({
  display: `flex`,
  padding: theme.spacing(0, 0, 0),
  alignItems: `center`,
  height: 25,
})
export const accButton = (
  ownerState, theme, colors, constants
) => ({
  maxHeight: ownerState.height,
  overflowX: `hidden`,
  width: `90vw`,
})
export const accBox = (
  ownerState, theme, colors, constants
) => ({
  border: `1px solid ${colors.filter}`,
  width: `100%`,
  borderRadius: `10px`,
  padding: `10px`,
  height: `100%`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
})
export const addrItem = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: colors.white,
  textAlign: `center`,
  display: `flex`,
})
export const addrBtnText = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: ownerState.background,
  borderRadius: 50,
  color: ownerState.textcolor,
  fontWeight: `600`,
  width: `fit-content`,
  display: `flex`,
  alignItems: `center`,
  padding: `0 10px`,
  textTransform: ownerState.capit,
})
export const itemOrder = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: colors.white,
  textAlign: `center`,
  display: `flex`,
})
export const formTexts = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: `transparent`,
  textAlign: ownerState.align || `left`,
  fontWeight: ownerState.weight || 700,
  fontSize: theme.typography.pxToRem(ownerState.font || 16),
  color: ownerState.textcolor || colors.dark,
  textTransform: ownerState.capit || `inherit`,
  overflow: `hidden`,
  whiteSpace: ownerState.wrap || `nowrap`,
  textOverflow: `ellipsis`,
  marginRight: `auto`,
  marginBottom: theme.spacing(1.2),
  "& a": {
    color: colors.secondary,
    fontWeight: 600,
    textDecoration: `none`,
  },
  "& .list": {
    lineHeight: 1,
    margin: `5px auto`,
  },
})
export const infoDiv = (
  ownerState, theme, colors, constants
) => ({
  display: `flex`,
  padding: `10px`,
  margin: ownerState.mrgn || `20px 0`,
  marginRight: `10px`,
  borderRadius: ownerState.radius,
  alignItems: `center`,
  backgroundColor: ownerState.back,
  "&:hover": {
    boxShadow: `0 0 0 1px ${ownerState.hvr}`,
  },
  fontWeight: `500`,
  fontFamily: `Quicksand`,
  cursor: `default`,
  [`& .svg-class-${ownerState.icon}`]: {
    display: `flex`,
    margin: `5px`,
    height: `20px`,
    "& .st0": {
      fill: colors.secondary,
    },
  },
})
export const textDescription = (
  ownerState, theme, colors, constants
) => ({
  fontFamily: fontQuicksand.style.fontFamily,
  height: ownerState.more === `false` ? 125 : `auto`,
  overflow: `hidden`,
  maskImage: ownerState.show === `true` && ownerState.more === `false` ? `linear-gradient(rgb(255, 255, 255)-100%, rgb(255, 255, 255), rgba(255, 255, 255, 0)100%)` : `inherit`,
  "& p": {
    marginTop: 6,
    marginBottom: ownerState.font ? `inherit` : 12,
    height: `auto`,
    fontSize: theme.typography.pxToRem(ownerState.font),
    color: colors.color,
    textDecoration: `none`,
    fontWeight: ownerState.weight || `inherit`,
  },
  "& a": {
    fontSize: theme.typography.pxToRem(ownerState.font),
    textDecoration: `none`,
    color: colors.secondary,
    fontWeight: ownerState.weight || 600,
  },
})
export const boxChips = (
  ownerState, theme, colors, constants
) => ({
  borderRadius: `0 !important`,
  alignSelf: `baseline`,
  width: `calc(${ownerState.mobile === `true` ? 88 : 90}vw - ${ownerState.mobile === `true` ? 2 : constants.DRAWER + 60}px)`,
  display: ownerState.mobile === `false` ? `flex` : ownerState.open ? `flex` : ownerState.desk === `true` ? `flex` : `none`,
  flexWrap: ownerState.mobile === `false` ? `wrap` : `nowrap`,
  overflowY: ownerState.mobile === `true` ? `hidden` : `none`,
  height: ownerState.mobile === `true` ? ownerState.open ? ownerState.width || 50 : ownerState.desk === `true` ? ownerState.width || `inherit` : 0 : `inherit`,
})
export const filterBox = (
  ownerState, theme, colors, constants
) => ({
  alignSelf: `baseline`,
  maxWidth: ownerState.mobile === `true` ? `100%` : `max-content`,
  display: ownerState.mobile === `true` ? `inherit` : `flex`,
  flexWrap: `wrap`,
  height: ownerState.mobile === `true` ? `calc(80vh - 130px)` : `inherit`,
})
export const paperSlider = (
  ownerState, theme, colors, constants
) => ({
  height: ownerState.height || 65,
  display: `flex`,
  justifyContent: `center`,
  overflowX: `hidden`,
  "& .rs-slider": {
    width: `80%`,
    top: 40,
    height: `fit-content`,
    "& .rs-slider-bar, & .rs-slider-bar:hover": {
      backgroundColor: `${colors.secondary}55 !important`,
      "& .rs-slider-progress-bar": {
        backgroundColor: `${colors.secondary}!important`,
      },
    },
  },
  "& .rs-slider-handle": {
    position: `relative`,
    top: -9,
  },
  "& .rs-slider-handle::before": {
    borderColor: `${colors.secondary}!important`,
  },
  "& .rs-slider-tooltip": {
    zIndex: 1230,
  },
})
export const whislist = (
  ownerState, theme, colors, constants
) => ({
  display: `flex`,
  margin: theme.spacing(2, 0),
  cursor: `pointer`,
  width: `fit-content`,
  height: `auto`,
  justifyContent: `center`,
  alignItems: `center`,
  backgroundColor: `transparent`,
  "& .svg-class-wishlist": {
    width: ownerState.wh,
    height: ownerState.wh,
    marginRight: theme.spacing(1),
    "& .st0": {
      fill: `${colors.secondary}!important`,
    },
  },
  "&.catalogue": {
    // width: `auto`,
    height: `auto`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    borderRadius: 50,
    position: `relative`,
    margin: 0,
    top: `-${ownerState.mobile === `true` ? 43 : ownerState.plus < 0 ? 50 : 38}%`,
    right: ownerState.plus < 0 ? `-85%` : `-${ownerState.mobile === `true` ? 84 : 88}%`,

    // left: `45%`,
    // zIndex: theme.zIndex.drawer,
    "& div": {
      display: `flex`,

      // width: `100%`,
    },
    "& .svg-class-wishlist": {
      marginRight: theme.spacing(0),
      marginLeft: `auto`,
      width: 20,
      height: 20,
      "& .st0": {
        fill: colors.secondary,
      },
    },
  },
})
export const couponList = (
  ownerState, theme, colors, constants
) => ({
  display: `flex`,
  margin: theme.spacing(2, 0),
  cursor: `pointer`,
  width: `fit-content`,
  height: 25,
  justifyContent: `center`,
  alignItems: `center`,
  backgroundColor: `transparent`,
  "& .svg-class-couponList": {
    width: ownerState.wh,
    height: ownerState.wh,
    marginRight: theme.spacing(1),
    "& .st0": {
      fill: `#D32F2F`,
    },
  },
  "&.catalogue": {
    // width: `auto`,
    height: 25,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    borderRadius: 7,
    position: `relative`,
    margin: 0,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    top: `-${ownerState.mobile === `true` ? 50 : ownerState.plus < 0 ? 59 : 44}%`,
    right: ownerState.plus < 0 ? `-2%` : `-${ownerState.mobile === `true` ? 2 : 2}%`,

    // left: `45%`,
    // zIndex: theme.zIndex.drawer,
    "& div": {
      display: `flex`,

      // width: `100%`,
    },
    "& .svg-class-couponList": {
      marginRight: theme.spacing(0),
      marginLeft: `auto`,
      width: 25,
      height: 25,
      "& .st0": {
        fill: `#D32F2F`,
      },
    },
  },
})
export const productCardC = (
  ownerState, theme, colors, constants
) => ({
  padding: 0,
  width: ownerState.mobile === `false` ? 200 + ownerState.plus : ownerState.xs === `true` ? 164 : 170,
  height: ownerState.mobile === `false` ? 325 + (ownerState.plus < 0 ? ownerState.plus - 20 : ownerState.plus) : 325,
  marginRight: theme.spacing(ownerState.mobile === `false` ? 1 : 0.25),
  marginLeft: theme.spacing(ownerState.mobile === `false` ? 1 : 0.25),
  marginBottom: 16,
  textDecoration: `none`,
  textAlign: `left`,
  borderRadius: `10px`,
  backgroundColor: colors.white,
  border: `1px solid transparent`,
  overflow: `unset`,
  "& a": {
    textDecoration: `none`,
    "& .contrast": {
      padding: theme.spacing(
        2, 2, 2, 2
      ),
      backgroundColor: colors.white,
      filter: `contrast(0.925)`,
      transition: theme.transitions.create([ `filter`, `border` ], {
        easing: theme.transitions.easing.sharp,
        duration: 100,
      }),
    },
  },
  "&:hover": {
    "& .contrast": {
      filter: ownerState.mobile === `false` ? `contrast(0.970)` : `contrast(0.925)`,
      transition: theme.transitions.create([ `filter`, `border` ], {
        easing: theme.transitions.easing.sharp,
        duration: 100,
      }),
    },

    filter: ownerState.mobile === `false` ? `contrast(1.1)` : `inherit`,
    transition: theme.transitions.create([ `filter`, `border` ], {
      easing: theme.transitions.easing.sharp,
      duration: 100,
    }),
    border: `${ownerState.mobile === `false` ? 1 : 0}px solid ${colors.borderProduct}`,
  },
  "& .MuiCardActionArea-focusHighlight": {},
})

export const brandCard = (
  ownerState, theme, colors, constants
) => ({
  width: ownerState.mobile === `false` ? 200 : 150,
  height: ownerState.mobile === `false` ? 200 : 150,
  backgroundColor: colors.white,
  textDecoration: `none`,
  filter: `contrast(0.925)`,
  margin: theme.spacing(1),
  borderRadius: 15,
  backgroundImage: `url(${ownerState.path})`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: `center`,
  backgroundSize: `contain`,
  "&:hover": {
    filter: `contrast(1.1)`,
  },
})

export const banner404Img = (
  ownerState, theme, colors, constants
) => ({
  width: `100%`,
  height: `75vh`,
  backgroundImage: `url(${ownerState.path})`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: ownerState.mobile === `true` ? `center ${ownerState.align || `center`}` : `center`,
  backgroundSize: ownerState.cover || `cover`,
  display: `flex`,
  padding: theme.spacing(0),
  borderRadius: 0,
})
export const paperBody = (
  ownerState, theme, colors, constants
) => ({
  width: ownerState.w || `100%`,
  minHeight: ownerState.h || `35px`,
  maxHeight: ownerState.mh,
  background: colors.white,
  justifyContent: `flex-start`,
  borderRadius: ownerState.br || `inherit`,
  padding: theme.spacing(1.5, ownerState.px || 0.5),
  flexDirection: `column`,
  overflowY: `auto`,
  marginBottom: theme.spacing(1.5),
})
export const paperSumaryBox = (
  ownerState, theme, colors, constants
) => ({
  padding: theme.spacing(
    1, 2, 0.5, 2
  ),
  display: `flex`,
  flexDirection: ownerState.direction || `row`,
  alignItems: `flex-start`,
  width: `100%`,
  backgroundColor: ownerState.bg || `inherit`,
})
export const paperBoxTitle = (
  ownerState, theme, colors, constants
) => ({
  padding: theme.spacing(2),
  display: `flex`,
  flexDirection: ownerState.direction || `row`,
  alignItems: ownerState.alitms || `flex-start`,
  justifyContent: ownerState.alitms || `inherit`,
  width: `100%`,
  minHeight: `65px`,
  "& div": {
    display: `flex`,
  },
  "& .svg-class-notick": {
    width: 25,
    marginRight: theme.spacing(1.3),
    "& .st0": {
      fill: colors.lightGrey2,
    },
  },
  "& .svg-class-tick": {
    width: 25,
    marginRight: theme.spacing(1.3),
    "& .st0": {
      fill: colors.greenBtn,
    },
  },
})
export const paperBodyBox = (
  ownerState, theme, colors, constants
) => ({
  width: ownerState.w || `100%`,
  minHeight: ownerState.h || `35px`,
  maxHeight: ownerState.mh,
  background: colors.white,
  justifyContent: `flex-start`,
  padding: theme.spacing(1.5, ownerState.px || 0.5),
  flexDirection: `column`,
  overflowY: `auto`,
  marginBottom: theme.spacing(1.5),
})

export const paperBottomBox = (
  ownerState, theme, colors, constants
) => ({
  display: `flex`,
  alignItems: `center`,
  height: 55,
  padding: theme.spacing(1, 2),
  width: `100%`,
  borderTop: `1px solid ${colors.lightGrey}`,
  backgroundColor: `transparent`,
  "& .link": {
    textUnderlineOffset: `3px`,
    color: colors.darkGrey,
    display: `flex`,
    alignItems: `center`,
    fontSize: theme.typography.pxToRem(ownerState.font),
    fontWeight: 600,
    width: `fit-content`,
  },
})

export const payIcon = (
  ownerState, theme, colors, constants
) => ({
  justifyContent: `center`,
  padding: `20px`,
  display: `flex`,
  flexDirection: ownerState.direction || `row`,
  "& .svg-class-payment": {
    background: colors.white,
    width: 80,
    height: 80,
    display: `flex`
  },
  "& .st0": {
    fill: `${ownerState.color}`,
  },
})
