'use client'
import { MenuContext, MobileContext } from "@/context"
import { useMediaQuerys } from "@/hooks/utils/useMediaQuerys"
import { types } from "@/types"
import { useContext, useEffect } from "react"
import Info from "../global/Info"
import SubMenu from "../global/SubMenu"
import { MobileBody } from "./MobileBody"
import { CloseSubMenu, OpenSubMenu } from "./MobileTitle"
import { ToolsContext } from "@/context/tools/tools"

const MobileMenu = ({ ...props }) => {
  const { colors, hostname } = props
  const { business, mobile, webPages, host } = hostname
  const {
    level1, level2, level3, expandedCategory, expandedSubCategory, toggleExpanded, toggleExpandedCategory, toggleExpandedSubCategory
  } = useContext(MenuContext)
  const {
    [types.mobileMenu.index]: index,
    [types.mobileMenu.category]: category,
    [types.mobileMenu.openCategory]: openCategory,
    [types.mobileMenu.openMenuMobile]: openSwip, toggleOpenSwipMobile,
    toggleIndex,
    toggleCategory,
    toggleOpenCategory,
  } = useContext(MobileContext)
  const handleChangeCategory = (panel, l, isExpanded) => {
    switch (l) {
    case 2:
      toggleExpandedCategory(!isExpanded ? panel : 0)
      toggleExpandedSubCategory(0)
      const level2open = level2.find(x => x.info.id === panel).info
      level2open.search_category !== null && (level2open.child_category?.length > 0 && level2open.child_category[0] === level2open.id) && toggleOpenSwipMobile(false)
      break
    case 3:
      toggleExpandedSubCategory(!isExpanded ? panel : 0)
      toggleOpenSwipMobile(false)
      break
    default:
      break
    }
  }
  const handleOpenCategory = async(x, i) => {
    await toggleExpanded(x.id)
    await toggleCategory(x)
    await toggleIndex(i)
    await toggleOpenCategory(!openCategory)
  }
  useEffect(() => {
    !openSwip && toggleOpenCategory(false)
  }, [openSwip])
  return (
    <>
      <div style={{ backgroundColor: colors.main }} className="flex items-center sticky top-0 h-[50px]">
        {!openCategory ?
          <CloseSubMenu {...{
            toggleOpenSwip: toggleOpenSwipMobile,
            business,
            colors,
            webPages
          }} /> :
          <OpenSubMenu {...{
            toggleOpenSwip: toggleOpenSwipMobile,
            openCategory,
            toggleOpenCategory,
            category,
            colors,
            webPages
          }} />}
      </div>
      <div style={{ height: `calc(100dvh - 210px)` }} className={`${openCategory ? `block` : `grid grid-cols-2`} overflow-x-hidden overflow-y-auto`}>
        {!openCategory ? (
          <MobileBody {...{ level1, handleOpenCategory }} {...props}/>
        ) : (
          <SubMenu
            menu={level2}
            lev={2}
            {...{
              level3,
              mobile,
              expandedCategory,
              expandedSubCategory,
              handleChange: handleChangeCategory,
              colors,
              host
            }}
          />
        )}
      </div>
    </>
  )
}

export const Mobile = ({ ...props }) => {
  const { mobile, styles } = props.hostname

  const { toggleOpenSwip, toggleOpenSwipMobile } = useContext(MobileContext)
  const { tools: { colors, texts }} = useContext(ToolsContext)
  const { xsQuery } = useMediaQuerys()
  return (
    <div id="menu-mobile" className={`w-[83dvw] sm:w-[50dvw] md:w-[30dvh] flex flex-col ${styles?.font ?? `font-quicksand`}`}>
      <MobileMenu {...props} {...{ mobile, colors, texts }} />
      <div className={`${mobile ? `p-2 h-[160px]` : `h-5 hidden tablet:flex`} bg-slate-100 text-[${colors.color}]`} style={{ height: !mobile && `20px` }}><Info {...props} xsQuery={!xsQuery} closeMenu={toggleOpenSwipMobile}/></div>
    </div>
  )
}
