// export const example = (ownerState, theme, colors, constants) => ({})

export const filterClr = (
  ownerState, theme, colors, constants
) => ({
  width: ownerState.w ? `100%` : `auto`,
  height: ownerState.w || 115,
  borderRadius: 0,
  display: `flex`,
  flexWrap: `wrap`,
  justifyContent: `start`,
  alignItems: `start`,
})
