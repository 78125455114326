import { types } from "@/types"

// export const example = (ownerState, theme, colors, constants) => ()
export const chipMenu = (
  ownerState, theme, colors, constants
) => ({
  padding: ownerState.ismobile === `false` ? 0 : 10,
  borderRadius: 0,
  background: `inherit`,
  "& .MuiChip-icon": {
    marginLeft: 0,
    marginRight: 0,
  },
  "&:hover": {
    background: `inherit`,
  },
  [`&.${types._menu.login},&.${types._menu.logout}, &.${types._menu.cart}`]: {
    color: ownerState.tcl || colors.white,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    marginRight: ownerState.ismobile === `true` ? 10 : 0,
    "& .MuiChip-label": {
      display: ownerState.ismobile === `true` ? `none` : ownerState.label === `` ? `none` : `inherit`,
    },
  },
  [`& .svg-class-${types._menu.login}, .svg-class-${types._menu.logout},
	.svg-class-${types._menu.cart}`]: {
    width: constants.ICON_SIZE,
    "& .st0": {
      fill: ownerState.tcl || colors.white,
    },
  },
  "& .cart span": {
    right: 0,
    top: 0,
    border: `2px solid transparent`,
    padding: `0px 2px`,
    color: ownerState.tcl || colors.white,
    fontWeight: 600,
    background: colors.tild,
  },
})
export const chipCat = (
  ownerState, theme, colors, constants
) => ({
  fontSize: theme.typography.pxToRem(14),
  color: ownerState.fill ? colors.white : colors.color,
  backgroundColor: ownerState.fill ? colors.darkGrey2 : `${colors.darkGrey2}35`,
  border: ownerState.fill ? `inherit` : `1px solid ${colors.color}`,
  textTransform: ownerState.fill ? `inherit` : `capitalize`,
  fontWeight: ownerState.fill || 500,
  height: ownerState.hgt || 40,
  "&:hover": {
    backgroundColor: `${colors.darkGrey2}75`,
    color: ownerState.fill ? colors.darkGrey : colors.color,
  },
  "&.active": {
    color: colors.white,
    backgroundColor: colors.secondary,
    "&:hover": {
      backgroundColor: colors.secondary,
      color: colors.white,
    },
  },
})
export const chipBoxSearch = (
  ownerState, theme, colors, constants
) => ({
  fontSize: theme.typography.pxToRem(14),
  color: colors.mainContrast,
  backgroundColor: colors.white,
  border: `1px solid ${colors.secondary}`,
  textTransform: `inherit`,
  fontWeight: ownerState.fill || 500,
  height: ownerState.hgt || 40,
  "&:hover": {
    backgroundColor: `${colors.secondary}35`,
  },
  "&.active": {
    backgroundColor: `${colors.secondary}35`,
    "&:hover": {
      backgroundColor: `${colors.secondary}35`,
    },
  },
})
export const chipRes = (
  ownerState, theme, colors, constants
) => ({
  fontSize: theme.typography.pxToRem(13),
  color: colors.darkGrey2,
  backgroundColor: colors.lightGrey,
  fontWeight: 500,
  height: ownerState.hgt || 40,
  margin: theme.spacing(
    0.5, 0.5, 0.5, 0
  ),
})
export const accUser = (
  ownerState, theme, colors, constants
) => ({
  border: `none`,
  width: `100%`,
  justifyContent: `flex-start`,
  marginBottom: `5px`,
  height: `auto`,
  "& .MuiChip-label": {
    whiteSpace: `break-spaces`,
  },
})
export const accChip = (
  ownerState, theme, colors, constants
) => ({
  border: `none`,
  justifyContent: `flex-start`,
  marginRight: `10px`,
  [`.svg-class-${ownerState.svgclass}`]: {
    width: `20px`,
    display: `flex`,
    "& .st0": {
      transition: theme.transitions.create([`fill`], {
        easing: theme.transitions.easing.sharp,
        duration: 300,
      }),
      fill: `inherit`,
    },
  },
})
export const formChip = (
  ownerState, theme, colors, constants
) => ({
  border: `none`,
  width: `100%`,
  justifyContent: `flex-start`,
  marginBottom: `5px`,
  cursor: `default`,
  height: `auto`,
  "& .MuiChip-label": {
    whiteSpace: `break-spaces`,
  },
})
export const historyChip = (
  ownerState, theme, colors, constants
) => ({
  textAlign: `center`,
  fontSize: theme.typography.pxToRem(14),
  width: `fit-content`,
  backgroundColor: colors.white,
  color: colors.secondary,
  fontWeight: 700,
  cursor: `inherit`,
})
export const textMore = (
  ownerState, theme, colors, constants
) => ({
  height: `auto`,
  fontSize: theme.typography.pxToRem(ownerState.font),
  color: ownerState.textcolor || colors.color,
  textDecoration: `none`,
  fontWeight: ownerState.weight || `inherit`,
  lineHeight: ownerState.line || `inherit`,
  "&.more-info:hover,&.more-info.active:hover": {
    backgroundColor: colors.white,
    borderColor: colors.secondary,
    color: colors.secondary,
    "& svg": {
      color: colors.secondary,
    },
  },
  "&.more-info.active": {
    backgroundColor: colors.secondary,
    borderColor: colors.secondary,
    color: colors.white,
    "& svg": {
      color: colors.white,
    },
  },
})
export const chipPanelBuy = (
  ownerState, theme, colors, constants
) => ({
  height: `auto`,
  width: `fit-content`,
  cursor: `pointer`,
  fontSize: theme.typography.pxToRem(ownerState.font || 14),
  color: ownerState.textcolor || colors.white,
  backgroundColor: ownerState.backgroundcolor || colors.secondary,
  border: `1px solid ${ownerState.backgroundcolor || colors.secondary}`,
  textTransform: ownerState.upper || `inherit`,
  fontWeight: ownerState.weight || 600,
})
export const chipText = (
  ownerState, theme, colors, constants
) => ({
  height: `auto`,
  width: `fit-content`,
  fontSize: theme.typography.pxToRem(ownerState.font || 14),
  color: ownerState.textcolor || colors.white,
  backgroundColor: ownerState.backgroundcolor || colors.secondary,
  border: `1px solid ${ownerState.backgroundcolor || colors.secondary}`,
  textTransform: ownerState.upper || `inherit`,
  fontWeight: ownerState.weight || 600,
  paddingLeft: `0px`,
})

export const chipLink = (
  ownerState, theme, colors, constants
) => ({
  background: `inherit`,
  fontWeight: 500,
  fontSize: theme.typography.pxToRem(14),
  "&:hover": {
    background: `inherit`,
  },
  "& div": {
    display: `flex`,
  },
  [`& .svg-class-cartLinks`]: {
    width: 22,
    "& .st0": {
      fill: colors.color,
    },
  },
})
