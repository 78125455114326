import { cmp } from "@/types"
import { colors, constants } from "@/utils/global"
import { createTheme } from "@mui/material"
import { func } from "."

const theme = createTheme()
export const cardTheme = createTheme({
  components: {
    MuiCard: {
      defaultProps: {
        variant: `outlined`,
        elevation: 0,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...ownerState.cmptype === cmp.productCard && func.card.productCardC(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.bikeCard && func.card.bikeCard(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.lineCard && func.card.lineCard(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiCardActionArea: {
      defaultProps: {
        disableTouchRipple: true,
        disableRipple: true,
        focusRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...ownerState.cmptype === cmp.productCard && func.card.productCardCaa(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.bikeCardArea && func.card.bikeCardArea(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.lineCardAction && func.card.lineCardAction(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.lineCardActionDelete && func.card.lineCardActionDelete(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiCardContent: {
      defaultProps: {},
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...ownerState.cmptype === cmp.productCard && func.card.productCardCc(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.bikeCardContent && func.card.bikeCardContent(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.addrItem && func.card.addrItem(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
  },
})
