// export const example = (ownerState, theme, colors, constants) => ()
export const closeCookies = (
  ownerState, theme, colors, constants
) => ({
  height: constants.DESKTOP_MENU.ICON_SIZE,
  marginLeft: theme.spacing(1),
  "& svg": {
    width: `100%`,
    height: `100%`,
    color: colors.darkGrey2,
  },
  "&:hover": {
    backgroundColor: `transparent`,
  },
})
export const closeMobileMenu = (
  ownerState, theme, colors, constants
) => ({
  height: constants.DESKTOP_MENU.ICON_SIZE,
  marginLeft: theme.spacing(1),
  "& svg": {
    width: `100%`,
    height: `100%`,
    color: colors.color,
  },
})
export const forwardMenu = (
  ownerState, theme, colors, constants
) => ({
  borderRadius: 0,
  padding: 0,
  width: constants.DESKTOP_MENU.ICON_SIZE,
  marginLeft: ownerState.close === `true` ? `auto` : theme.spacing(1),
  marginRight: theme.spacing(1.5),
  "& svg": {
    color: colors.main,
  },
})
export const accDfltBtn = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: colors.white,
  width: 40,
  height: 40,
  margin: 0,
  borderRadius: 50,
  "&:hover": {
    textDecoration: `underline`,
    backgroundColor: colors.white,
    textUnderlineOffset: `3px`,

    color: ownerState.hvr,

    [`.svg-class-${ownerState.svgclass}`]: {
      width: `25px`,
      display: `flex`,
      "& .st0": {
        transition: theme.transitions.create([`fill`], {
          easing: theme.transitions.easing.sharp,
          duration: 300,
        }),
        fill: ownerState.hvr,
      },
    },
  },

  [`.svg-class-${ownerState.svgclass}`]: {
    width: `25px`,
    display: `flex`,
    "& .st0": {
      fill: colors.lightGrey3,
    },
  },
})
