'use client'
import { cleanPath, handleKey } from "@/utils/functions"
import { constants } from "@/utils/global"
import Head from "next/head"
import Link from "next/link"
import { usePathname, useRouter } from "next/navigation"
const readHostname = () => {
  const { origin } = window.location
  const domain = origin?.split(`.`).slice(0, -1).
    join(`.`)
  return domain
}
export const HrefPagesStatic = props => {
  const pathname = usePathname()
  const domain = readHostname()
  return (
    <Head>
      {Object.values(constants.DOMAINS).map((x, i) => (
        <link
          key={handleKey(`hrefLangPages-${x}`, i)}
          rel="alternate"

          hrefLang={x === `com` ? `es` : x === `net` ? `de` : x === `uk` ? `en` : x}
          href={`${domain}.${x}${cleanPath(pathname)}`}
        />
      ))}
    </Head>
  )
}
export const HrefPagesDynamic = props => {
  const { hreflang, slash } = props

  const domain = readHostname()
  return (
    <Head>
      {/* <Link  prefetch={false} rel="alternate" hrefLang="x-default" href={`${window.location.origin}${slash}${defaultHref?.href}/`} /> */}
      {hreflang?.map((x, i) => ![ 7, 8 ].includes(x.langId) && (
        <Link prefetch={false} key={handleKey(`hrefLang-${x.lang}`, i)} rel="alternate" hrefLang={x.lang} href={`${domain}.${constants.DOMAINS[x.langId]}${slash}${cleanPath(x.href)}`} />
      ))}
    </Head>
  )
}
