/* eslint-disable max-lines */
export const forwardMenu = (
  ownerState, theme, colors, constants
) => ({
  fontSize: theme.typography.pxToRem(20),
  paddingLeft: theme.spacing(1.5),
  color: colors.main,
  fontWeight: 600,
})
export const schedule = (
  ownerState, theme, colors, constants
) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 400,
  color: colors.footer.color,
  padding: theme.spacing(
    2, 3, 1, 0
  ),
})
export const fooInfo = (
  ownerState, theme, colors, constants
) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 700,
  color: colors.footer.color,
  padding: theme.spacing(2, 0),
  textTransform: `uppercase`,
})
export const ourShops = (
  ownerState, theme, colors, constants
) => ({
  color: colors.footer.color,
  fontWeight: ownerState.weight,
  textTransform: ownerState.transform ? ownerState.transform : `inherit`,
  padding: theme.spacing(
    ownerState.paddt, ownerState.paddr, ownerState.paddb, ownerState.paddl
  ),
})
export const msgCookies = (
  ownerState, theme, colors, constants
) => ({
  color: colors.footer.color,
  padding: theme.spacing(0),
  fontWeight: ownerState.weight,
})
export const accordionSumModCookie = (
  ownerState, theme, colors, constants
) => ({
  color: colors.main,
  textTransform: `uppercase`,
  fontWeight: 700,
  fontSize: theme.typography.pxToRem(12),
  "& .MuiSwitch-colorSecondary.Mui-checked": {
    color: colors.main,
  },
  "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
    backgroundColor: colors.main,
  },
})
export const hometext = (
  ownerState, theme, colors, constants
) => ({
  textAlign: ownerState.align || `left`,
  fontSize: theme.typography.pxToRem(ownerState.font || 28),
  fontWeight: ownerState.weight || `600`,
  lineHeight: 1.2,
  marginTop: theme.spacing(ownerState.mt || 3),
  color: colors.color,
  "& .eurobikes": {
    color: colors.main,
  },
})
export const homeInfoTitle = (
  ownerState, theme, colors, constants
) => ({
  textAlign: ownerState.align || `left`,
  fontSize: theme.typography.pxToRem(ownerState.font || 18),
  fontWeight: ownerState.weight || `600`,
  lineHeight: 1.2,
  textTransform: ownerState.textTransform || `capitalize`,
  color: colors.infoHomeText,
  "& .tild": {
    color: colors.main,
  },
})
export const homeInfoText = (
  ownerState, theme, colors, constants
) => ({
  marginTop: ownerState.mt || `inherit`,
  marginBottom: `${ownerState.mb}px` || `12px`,
  height: `auto`,
  maxWidth: !ownerState.mobile === `false` ? 325 : `100%`,
  paddingLeft: theme.spacing(!ownerState.pl === `false` ? 0 : 2),
  textTransform: ownerState.upper === `true` ? `uppercase` : `inherit`,
  color: ownerState.color || colors.infoHomeText,
  textDecoration: `none`,
  fontWeight: ownerState.weight,
  "& .tild": {
    color: colors.secondary,
    textDecoration: `none`,
    fontWeight: 600,
  },
  "& .eurobikes": {
    color: colors.main,
  },
  "& .bold": {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(22),
  },
})
export const cardHomeSection = (
  ownerState, theme, colors, constants
) => ({
  position: `relative`,
  top: ownerState.t,
  left: 10,
  backgroundColor: colors.white,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 700,
  padding: theme.spacing(0.8),
  borderRadius: 10,
  maxWidth: 280,
  overflow: `hidden`,
  textOverflow: `ellipsis`,
  display: `-webkit-box !important`,
  WebkitLineClamp: 1,
  WebkitBoxOrient: `vertical`,
  color: `${colors.color}!important`,
  height: `fit-content`,
  textDecoration: `none !important`,
  "& .tild": {
    color: `${colors.secondary}!important`,
  },
})
export const productCardInfo = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: `inherit`,
  fontWeight: ownerState?.weight || 700,
  textAlign: ownerState?.align || `left`,
  color: ownerState?.textcolor || `inherit`,
  lineHeight: ownerState?.line || `inherit`,
  overflow: `hidden`,
  textOverflow: `ellipsis`,
  display: `-webkit-box !important`,
  WebkitLineClamp: 2,
  WebkitBoxOrient: `vertical`,
  marginTop: ownerState?.mt ? theme.spacing(ownerState?.mt) : `inherit`,
})
export const labelsTexts = (
  ownerState, theme, colors, constants
) => ({
  top: ownerState.params.seeker === `true` ? `-18px` : `5px`,
  left: `5px`,
  position: ownerState.position ?? `absolute`,
  zIndex: 1,
  borderRadius: `5px`,
  padding: theme.spacing(0.5, 1),
  backgroundColor: ownerState.params.backgroundcolor,
  color: colors.white,
  fontWeight: ownerState.params.weight,
  minWidth: 80,
  textAlign: `center`,
  fontSize: theme.typography.pxToRem(ownerState.params.font || 13),
  lineHeight: ownerState.params.seeker === `true` ? 1.8 : 1.3,
})
export const texts = (
  ownerState, theme, colors, constants
) => ({
  marginTop: ownerState.mt || `inherit`,
  marginBottom: ownerState.mb || 12,
  height: ownerState.height || `auto`,
  lineHeight: ownerState.line || `inherit`,
  fontSize: theme.typography.pxToRem(ownerState.font || 16),
  color: ownerState.texcolor || colors.color,
  textDecoration: `none`,
  fontWeight: ownerState.weight,
  textTransform: ownerState.upper || `inherit`,
  "& .tild": {
    color: colors.secondary,
  },
  "& a.tild": {
    cursor: `pointer`,
  },
  "& a.tild:hover": {
    color: `${colors.secondary} !important`,
  },
  "& .eurobikes": {
    color: colors.main,
  },
  "& .bold": {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(22),
  },
  "& span": {
    fontSize: theme.typography.pxToRem(ownerState.font || 16),
    textTransform: `none`,
    "&:hover": {

      backgroundColor: `transparent`,
    },
    "&::first-letter": {
      textTransform: `uppercase`,
    }
  }
})
export const headerTips = (
  ownerState, theme, colors, constants
) => ({
  height: `auto`,
  fontSize: theme.typography.pxToRem(14),
  color: ownerState.colors || colors.main,
  fontWeight: 700,
  textTransform: `uppercase`,
  textDecoration: `none`,
  paddingLeft: theme.spacing(1),
})
export const gridAccMenu = (
  ownerState, theme, colors, constants
) => ({
  textAlign: `left`,
  fontWeight: ownerState.weight,
  fontSize: theme.typography.pxToRem(ownerState.font),
  color: ownerState.colors || colors.color,
})
export const accDesc = (
  ownerState, theme, colors, constants
) => ({
  textAlign: `left`,
  fontSize: theme.typography.pxToRem(ownerState.font),
  fontWeight: ownerState.weight,
  color: ownerState.colors,
  marginBottom: ownerState.mb,
})
export const accUser = (
  ownerState, theme, colors, constants
) => ({
  textAlign: `left`,
  fontWeight: `600`,
  fontSize: theme.typography.pxToRem(16),
  color: ownerState.textcolor || colors.lightGrey2,
  width: ownerState.wdth || `75px`,
  whiteSpace: ownerState.wrap || `break-spaces`,
})
export const formTexts = (
  ownerState, theme, colors, constants
) => ({
  textAlign: ownerState.align || `left`,
  fontWeight: ownerState.weight || 700,
  fontSize: theme.typography.pxToRem(ownerState.font || 16),
  color: ownerState.textcolor || colors.dark,
  textTransform: ownerState.capit || `inherit`,
  overflow: `hidden`,
  whiteSpace: ownerState.wrap || `nowrap`,
  textOverflow: `ellipsis`,
  marginRight: `auto`,
  "& a": {
    color: colors.secondary,
    fontWeight: 600,
    textDecoration: `none`,
  },
  "& .eurobikes": {
    color: colors.main,
  },
  "& .tild": {
    color: colors.main,
  },
  "& .bold": {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(22),
  },
  "&.error": {
    color: colors.muiErr,
  },
})
export const orderTexts = (
  ownerState, theme, colors, constants
) => ({
  color: ownerState.textcolor || (ownerState.state && [ `paid`, `sent` ].includes(ownerState.state) ? colors.GreenAv : ownerState.state === `unpaid` ? colors.YellowAv : colors.darkGrey2),
  fontWeight: ownerState.weight || 700,
  fontSize: theme.typography.pxToRem(ownerState.fontht),
  textTransform: ownerState.capit || `uppercase`,
  whiteSpace: ownerState.wrap && `nowrap`,
  marginRight: ownerState.mr || `auto`,
  textAlign: ownerState.align || `inherit`,
  "&.addr": {
    width: ownerState.wd,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    paddingLeft: theme.spacing(1.5),
    alignSelf: `self-end`,
    marginLeft: `auto`,
    marginRight: 0,
    lineHeight: 1.7,
  },
  "&.ship": {
    width: ownerState.wd,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    paddingLeft: theme.spacing(1.5),
    alignSelf: `self-end`,
    marginRight: `auto`,
    marginLeft: 0,
    lineHeight: 1.7,
  },
  "&.addr-sumary": {
    width: ownerState.wd,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    paddingLeft: theme.spacing(0),
    alignSelf: `self-end`,
    marginRight: `auto`,
    marginLeft: 0,
    lineHeight: 1.7,
  },
})
export const legalTitle = (
  ownerState, theme, colors, constants
) => ({
  [theme.breakpoints.up(`md`)]: {
    fontSize: theme.typography.pxToRem(32),
    textAlign: `left`,
  },
  fontSize: theme.typography.pxToRem(28),
  fontWeight: `700`,
  textAlign: `center`,
  color: colors.color,
})
export const legalText = (
  ownerState, theme, colors, constants
) => ({
  marginBottom: 12,
  height: `auto`,
  [theme.breakpoints.up(`md`)]: {
    fontSize: theme.typography.pxToRem(16),
    textAlign: `left`,
  },
  fontSize: theme.typography.pxToRem(15),
  color: colors.color,
  textDecoration: `none`,
})
export const legalSubTitle = (
  ownerState, theme, colors, constants
) => ({
  [theme.breakpoints.up(`md`)]: {
    fontSize: theme.typography.pxToRem(22),
    textAlign: `left`,
  },
  fontWeight: `600`,
  fontSize: theme.typography.pxToRem(17),
  color: colors.color,
  textAlign: `center`,
})
export const supportSubTitle = (
  ownerState, theme, colors, constants
) => ({
  padding: `10px`,
  [theme.breakpoints.up(`lg`)]: {
    fontSize: theme.typography.pxToRem(17),
  },
  textAlign: `left`,
  fontWeight: `600`,
  fontSize: theme.typography.pxToRem(16),
  color: colors.color,
})
export const legalSubDoubleTitle = (
  ownerState, theme, colors, constants
) => ({
  [theme.breakpoints.up(`md`)]: {
    fontSize: theme.typography.pxToRem(17),
    textAlign: `left`,
  },
  fontWeight: `500`,
  fontSize: theme.typography.pxToRem(15),
  color: colors.color,
  textAlign: `center`,
})
export const textQuestion = (
  ownerState, theme, colors, constants
) => ({
  marginBottom: 12,
  height: `auto`,
  textAlign: `center`,
  fontSize: theme.typography.pxToRem(20),
  color: colors.secondary,
  fontWeight: 600,
  textDecoration: `none`,
})
export const textHistory = (
  ownerState, theme, colors, constants
) => ({
  marginBottom: 0,
  height: `auto`,
  fontSize: theme.typography.pxToRem(12),
  color: colors.color,
  textDecoration: `none`,
  textAlign: `left`,
  "&.tild": {
    fontWeight: 700,
    color: colors.secondary,
  },
})
export const textStore = (
  ownerState, theme, colors, constants
) => ({
  [theme.breakpoints.up(`lg`)]: {
    fontSize: theme.typography.pxToRem(17),
  },
  textAlign: `left`,
  fontWeight: `500`,
  fontSize: theme.typography.pxToRem(16),
  color: colors.color,
  "& a": {
    textDecoration: `none`,
    fontWeight: `700`,
    color: colors.secondary,
  },
})
export const textTitleH = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: ownerState.background || `inherit`,
  fontSize: theme.typography.pxToRem(ownerState.font),
  fontWeight: ownerState.weight,
  textAlign: ownerState.align || `left`,
  color: ownerState.textcolor || colors.color,
})
export const breadcrumb = (
  ownerState, theme, colors, constants
) => ({
  height: `auto`,
  fontSize: theme.typography.pxToRem(ownerState.font),
  color: ownerState.textcolor || colors.color,
  textDecoration: `none`,
  fontWeight: ownerState.weight || `inherit`,
  lineHeight: ownerState.line || `inherit`,
  "& .link": {
    // color: colors.secondary,
    fontWeight: ownerState.weight || 400,
    textDecoration: `none`,
  },
})
export const filterBtn = (
  ownerState, theme, colors, constants
) => ({
  marginBottom: ownerState.font ? `inherit` : 12,
  height: `auto`,
  fontSize: ownerState.font ? theme.typography.pxToRem(ownerState.font) : theme.typography.pxToRem(ownerState.ismobile === `true` ? 16 : 15),
  color: ownerState.textcolor || colors.color,
  textDecoration: `none`,
  fontWeight: ownerState.weight || `inherit`,
  lineHeight: ownerState.line || `inherit`,
  "& a": {
    color: colors.secondary,
    fontWeight: ownerState.weight || 600,
    textDecoration: `none`,
  },
  "&.more-info:hover": {
    "& svg": {
      color: colors.secondary,
    },
    color: colors.secondary,
  },
})
export const filterPrc = (
  ownerState, theme, colors, constants
) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  textTransform: `none`,
  color: colors.color,
})
export const textSelect = (
  ownerState, theme, colors, constants
) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  textTransform: ownerState.cap || `capitalize`,
  backgroundColor: colors.lightGrey,
  color: ownerState.fill === `true` ? colors.color : !ownerState.open ? colors.darkGrey2 : ownerState.ismobile === `false` ? colors.color : colors.darkGrey2,
  boxShadow: 0,
  borderRadius: ownerState.ismobile === `true` ? ownerState.left === `false` ? theme.spacing(
    0, 50, 50, 0
  ) : theme.spacing(
    50, 0, 0, 50
  ) : 50,
  border: `${ownerState.fill === `true` ? `0` : `1`}px solid ${
    !ownerState.open ? `${colors.darkGrey2}${ownerState.ismobile === `true` ? `55` : ``}` : ownerState.ismobile === `false` ? colors.color : `${colors.darkGrey2}55`
  }`,
  minWidth: ownerState.ismobile === `true` ? `100%` : 125,
  padding: theme.spacing(0, ownerState.ismobile === `true` ? 0 : 0.5),
  height: ownerState.ismobile === `true` ? ownerState.fill === `true` ? `auto` : 32 : 22,
  justifyContent: ownerState.ismobile === `true` ? ownerState.fill === `true` ? `space-between` : `center` : `space-between`,
  "&.is-pinned": {
    borderRadius: `0px !important`,
    height: 40,
  },
  "&:hover": {
    backgroundColor: colors.lightGrey,
    border: `${ownerState.fill === `true` ? `0` : `1`}px solid ${colors.color}`,
    color: colors.color,
  },
})
export const bikeCard = (
  ownerState, theme, colors, constants
) => ({
  marginTop: 0,
  backgroundColor: `inherit`,
  fontSize: theme.typography.pxToRem(ownerState.font),
  fontWeight: ownerState.weight || 700,
  textAlign: ownerState.align || `left`,
  color: ownerState.textcolor || colors.color,
  lineHeight: ownerState.line || `inherit`,
})
export const emptyText = (
  ownerState, theme, colors, constants
) => ({
  textAlign: `left`,
  marginLeft: theme.spacing(1.5),
  backgroundColor: ownerState.bg || colors.white,
  padding: theme.spacing(0.15, 1),
  borderRadius: `8px`,
  whiteSpace: `nowrap`,
  height: ownerState.height || `auto`,
  lineHeight: ownerState.line || `inherit`,
  fontSize: theme.typography.pxToRem(ownerState.font || 16),
  color: ownerState.texcolor || colors.color,
  textDecoration: `none`,
  fontWeight: ownerState.weight,
  textTransform: ownerState.upper || `inherit`,
  "& .tild": {
    color: colors.secondary,
  },
  "& a.tild": {
    cursor: `pointer`,
  },
  "& a.tild:hover": {
    color: `${colors.secondary} !important`,
  },
  "& .eurobikes": {
    color: colors.main,
  },
  "& .bold": {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(22),
  },
})
export const lineText = (
  ownerState, theme, colors, constants
) => ({
  height: `auto`,
  whiteSpace: ownerState.wr && `nowrap`,
  lineHeight: ownerState.ln || `inherit`,
  fontSize: theme.typography.pxToRem(ownerState.fn || 16),
  color: ownerState.tcl || colors.color,
  textDecoration: `none`,
  fontWeight: ownerState.wht,
  "& .tild": {
    color: colors.secondary,
  },
  "& .eurobikes": {
    color: colors.main,
  },
  "& .bold": {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(22),
  },
  "&.option": {
    textAlign: `left`,
    width: ownerState.wd,
  },
  "&.elipsis": {
    overflow: `hidden`,
    whiteSpace: `nowrap`,
    textOverflow: `ellipsis`,
    marginRight: `auto`,
  },
})
export const stepCartTitle = (
  ownerState, theme, colors, constants
) => ({
  marginBottom: ownerState.mb || 12,
  textAlign: ownerState.align || `left`,
  fontSize: theme.typography.pxToRem(ownerState.font || 28),
  fontWeight: ownerState.weight || 600,
  lineHeight: 1.2,
  color: ownerState.textcolor || colors.color,
  "&.eurobikes": {
    color: colors.main,
  },
})

export const errorMsg = (
  ownerState, theme, colors, constants
) => ({
  color: colors.redBtn,
  "&.fixed": {
    position: `fixed`,
    backgroundColor: colors.redBtn,
    color: colors.white,
    bottom: 40,
    width: `100%`,
    borderRadius: 0,
    textAlign: `center`,
    zIndex: theme.zIndex.drawer + 5,
  },
})

export const errorPay = (
  ownerState, theme, colors, constants
) => ({
  margin: `auto`,
  marginTop: ownerState.mt || `inherit`,
  marginBottom: ownerState.mb || 12,
  height: ownerState.height || `auto`,
  lineHeight: ownerState.line || `inherit`,
  fontSize: theme.typography.pxToRem(ownerState.font || 16),
  color: ownerState.texcolor || colors.color,
  textDecoration: `none`,
  fontWeight: ownerState.weight,
  textTransform: ownerState.upper || `inherit`,
  width: ownerState.w,
  border: `2px solid ${colors.RedAv}`,
  borderRadius: 10,
  padding: theme.spacing(1.5, 1),
  backgroundColor: `${colors.RedAv}4f`,
  "& .tild": {
    color: colors.secondary,
  },
  "& a.tild": {
    cursor: `pointer`,
  },
  "& a.tild:hover": {
    color: `${colors.secondary} !important`,
  },
  "& .eurobikes": {
    color: colors.main,
  },
  "& .bold": {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(22),
  },
})
