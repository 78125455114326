// export const example = (ownerState, theme, colors, constants) => ()
export const styleRow = (
  ownerState, theme, colors, constants
) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: `${colors.lightGrey}aa`,
  },
})
export const stickyCell = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: `inherit`,
  minWidth: `50px`,
  left: 0,
  position: `sticky`,
  zIndex: theme.zIndex.appBar + 1,
  fontSize: theme.typography.pxToRem(14),
  border: 0,
  fontWeight: 700,
  width: `20%`,
})
export const stickyCellCookie = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: colors.lightGrey,
  minWidth: `50px`,
  left: 0,
  position: `sticky`,
  zIndex: theme.zIndex.appBar + 1,
  [theme.breakpoints.up(`md`)]: {
    fontSize: theme.typography.pxToRem(16),
  },
  fontSize: theme.typography.pxToRem(13),
  border: 0,
  "&.oney": {
    display: `flex`,
    justifyContent: `center`,
  },
  [`& .svg-class-checkedOney`]: {
    width: 45,
    height: 45,
    margin: `auto`,
    "& .st0": {
      transition: theme.transitions.create([`fill`], {
        easing: theme.transitions.easing.sharp,
        duration: 300,
      }),
      fill: ownerState.hvr,
    },
  },
})
export const styleCell = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: `inherit`,
  fontSize: theme.typography.pxToRem(14),
  border: 0,
  width: `auto`,
})
