// export const example = (ownerState, theme, colors, constants) => ()

const openedMixin = (theme, constants) => ({
  width: `calc(${theme.spacing(7)} + ${constants.DRAWER}px)`,
  transition: theme.transitions.create(`width`, {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: `hidden`,
})

const closedMixin = theme => ({
  transition: theme.transitions.create(`width`, {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: `hidden`,
  width: `calc(${theme.spacing(7)} + 1px)`,
})
export const menuDrawer = (
  ownerState, theme, colors, constants
) => ({
  width: constants.DRAWER,
  flexShrink: 0,
  whiteSpace: `nowrap`,
  boxSizing: `border-box`,
  "& .MuiDrawer-paper": { boxSizing: `border-box` },
  ...ownerState.open && {
    ...openedMixin(theme, constants),
    "& .MuiDrawer-paper": openedMixin(theme, constants),
  },
  ...!ownerState.open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  },
})
