'use client'

import { ToastifyContext } from "@/context"
import styled from "@emotion/styled"
import { createTheme } from "@mui/material"
import { useContext, useEffect, useMemo } from "react"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
const theme = createTheme()
const Toast = styled(ToastContainer)(() => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
}))
export const Toastify = props => {
  const {
    show, error, msg, info, rmvErrorToastify
  } = useContext(ToastifyContext)
  const options = useMemo(() => ({
    position: `bottom-right`,
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: `colored`,
  }),
  [])
  useEffect(() => {
    if (show) {
      !error ? info ? toast.info(msg, options) : toast.success(msg, options) : toast.error(msg, options)
      rmvErrorToastify()
    }
  }, [msg])
  return <Toast newestOnTop={true} pauseOnFocusLoss rtl={false} pauseOnHover {...options} />
}
