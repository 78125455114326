'use client'
import { DropDownArrows } from "@/app/(web)/_components/basket/subcomponent/components/subcomponents/DropDownArrows"
import { CartContext } from "@/context"
import { ToolsContext } from "@/context/tools/tools"
import { handleKeyRandom } from "@/utils/functions"
import {
  ClickAwayListener, Grow,
  Popper
} from "@mui/material"
import parse from "html-react-parser"
import {
  useContext, useEffect, useId, useRef, useState
} from "react"

export const Currency = ({
  hostname, textcolor = `white`
}) => {
  const { tools: { currencies, colors }} = useContext(ToolsContext)
  const anchorRef = useRef()
  const { cart, handleCurrency } = useContext(CartContext)
  const { currency } = cart
  const idRef = useId()
  const [ open, setOpen ] = useState(false)
  const [ textSearch, setTextSearch ] = useState(``)
  const { mobile } = hostname
  const [ options, setOptions ] = useState([])

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
    setTextSearch(``)
    setOptions(currencies)
  }
  const handleTextSearch = e => {
    e.preventDefault()
    setTextSearch(e.target.value)
  }

  useEffect(() => {
    setOptions(currencies)
  }, [currencies])

  useEffect(() => {
    if (currencies?.length > 0 && textSearch.length > 0) {
      const filtered = currencies.filter(x => x.translate[hostname?.lang.toLowerCase()].toLowerCase().includes(textSearch.toLowerCase()))
      setOptions(filtered)
    }
  }
  , [textSearch])

  return currencies?.length > 0 && !!hostname?.business?.webUrl.includes(`eurobikes`) && (
    <div className="flex">
      <button
        style={{ color: textcolor, fontSize: mobile && `24px` }}
        className="capitalize w-auto tablet:w-16"
        ref={anchorRef}
        aria-controls={open ? `split-button-menu` : undefined}
        aria-expanded={open ? `true` : undefined}
        aria-label="select-web-domain"
        aria-haspopup="menu"
        onClick={handleToggle}
      >
        {parse(currency?.symbol || ``)}
        <DropDownArrows {...{ open }} white={hostname?.mobile ? true : undefined} />
      </button>
      <Popper open={open} anchorEl={anchorRef.current} transition={true}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === `bottom` ? `center top` : `center bottom`,
            }}
          >
            <div style={{ color: colors.color }} className="z-50 mt-1 bg-white rounded-md p-1 text-sm cursor-pointer ">
              <ClickAwayListener onClickAway={handleClose}>
                <div className="flex flex-col">
                  <input
                    type="text"
                    className="w-full p-2 flex outline-none border border-gray-300 rounded-md "
                    id={`input-filter-${currency?.translate[hostname?.lang.toLowerCase()]}`}
                    onChange={handleTextSearch}
                    autoFocus={open}
                    value={textSearch} />
                  <div id={idRef} className="h-52 overflow-x-hidden overflow-y-auto py-1 ">
                    {options?.map((x, i) => (
                      <p
                        className={`${currency?.id === x.id ? `bg-slate-200` : `bg-inherit`} hover:bg-neutral-100 py-1 px-2 rounded`}
                        key={handleKeyRandom(idRef, i)}
                        onClick={() => handleCurrency(x)}>
                        {parse(`${x.symbol} - ${x.translate[hostname?.lang.toLowerCase()]}`)}
                      </p>
                    ))}
                  </div>
                </div>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
