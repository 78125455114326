import { apiCallElk } from "@/middleware/api"
import { useState } from "react"

export const useSearch = () => {
  const [ list, setList ] = useState({ load: false, data: [], total: 0 })
  const [ ctr, setCtr ] = useState()
  const [ load, setLoad ] = useState(false)
  const [ labels, setLabels ] = useState([])

  const handleSearchInit = async props => {
    if (ctr) {
      await ctr.abort()
    }
    await setLoad(x => true)
    const response = await apiCallElk({ url: `/search-init`, type: `POST`, encrypt: props })
    const { result, controller } = await response
    await setCtr(controller)
    await setLoad(x => false)
    await setList(x => ({ ...result }))
  }
  const handleSearchParams = async props => {
    const { result } = await apiCallElk({ url: `/synonyms`, type: `POST`, encrypt: props })
  }

  const handleLabels = async props => {
    const { result } = await apiCallElk({ url: `/labels`, type: `POST`, encrypt: props })
    await setLabels(x => result)
  }
  return {
    list,
    handleSearchInit,
    handleSearchParams,
    labels,
    handleLabels,
    load
  }
}
