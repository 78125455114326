'use client'
import { TWDivider } from "@/components/tailwind/Divider"
import { ToolsContext } from "@/context/tools/tools"
import IconsSVG from "@/utils/icons/IconsSVG"
import parse from "html-react-parser"
import moment from "moment"
import Link from "next/link"
import { Suspense, useContext, useMemo } from "react"

export const CopyrightSocial = ({ colors }) => {
  const { tools: { texts }} = useContext(ToolsContext)
  const main_layout = useMemo(() => texts.main_layout, [texts])
  return (
    <Suspense fallback={<div></div>}>
      <div className="col-span-1 1200:col-span-2">
        <TWDivider size={100} color={colors.footer?.color} />
        <div className="grid grid-cols-1 1200:grid-cols-2 justify-items-center text-center 1200:text-left 1200:justify-items-stretch">
          <div>
            <p className="font-semibold text-center tablet:text-left" style={{ color: colors.footer?.subColor }}>
              {parse(main_layout?.copyright.replace(`{year}`, moment().format(`YYYY`)) || ``)}
            </p>
          </div>
          <div className="flex justify-start 1200:justify-end p-2 1200:p-0">
            {main_layout?.rrss?.map((social, i) => (
              <Link prefetch={false} key={`${social.alt}_${i}`} href={social.href} passHref scroll={true}>
                <IconsSVG name="footer-icons" path={social.img} fillcolor={colors.footer.subColor} />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Suspense>
  )
}
