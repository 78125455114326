// export const example = (ownerState, theme, colors, constants) => ()
export const listPopper = (
  ownerState, theme, colors, constants
) => ({
  fontSize: theme.typography.pxToRem(13),
  fontWeight: 500,
  textTransform: `capitalize`,
  backgroundColor: `transparent`,
  color: colors.color,
  width: 200,
  padding: theme.spacing(
    0.5, 2.5, 0.5, 1
  ),
  cursor: `pointer`,
  transition: theme.transitions.create([ `color`, `background-color` ], {
    easing: theme.transitions.easing.sharp,
    duration: 225,
  }),
  "&:hover": {
    color: colors.secondary,
    backgroundColor: `${colors.darkGrey2}20 !important`,
    transition: theme.transitions.create([ `color`, `background-color` ], {
      easing: theme.transitions.easing.sharp,
      duration: 225,
    }),
  },
})

export const pickupListItem = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: ownerState.bg,
  color: ownerState.tcl,
  maxWidth: `400px`,
  borderBottom: ownerState.bdb,
  "&:hover": {
    backgroundColor: ownerState.bgh,
    "& .MuiListItemText-primary": {
      fontWeight: 700,
      color: ownerState.tclh
    },
    "& .MuiListItemText-secondary": {
      color: ownerState.tclh,
      fontWeight: 500
    },
  },
  "& .MuiListItemText-primary": {
    fontWeight: 700,
    color: ownerState.tild
  },
  "& .MuiListItemText-secondary": {
    color: ownerState.tcl,
    fontWeight: 500
  },
})
