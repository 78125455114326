import { BasketContext } from "@/context/basket/BasketContext"
import {
  ClickAwayListener, Grow,
  Popper
} from "@mui/material"
import { useContext } from "react"

export const MbOpt = props => {
  const { mbOptRef } = props

  const { show, handleShowMenu } = useContext(BasketContext)
  return (
    <Popper id="pop-menu-account" open={show.menu}
      anchorEl={mbOptRef.current} transition={true}
      sx={{
        left: `-15px!important`,
        top: `3px!important`
      }}>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === `bottom` ? `center top` : `center bottom`,
          }}
        >
          <div className="z-[99] mt-1 bg-white rounded-lg w-48 p-2 border border-neutral-300">
            <ClickAwayListener onClickAway={() => handleShowMenu(false)}>
              <div className="flex flex-col w-full">{props.children}</div>
            </ClickAwayListener>
          </div>
        </Grow>
      )}
    </Popper>
  )
}
