/* eslint-disable max-lines */

import { types } from "@/types"

// export const example = (ownerState, theme, colors, constants) => ()
export const accClass = (
  ownerState, theme, colors, constants
) => ({
  borderBottom: `1px solid ${colors.lightGrey}`,
})
export const accordionModCookieA = (
  ownerState, theme, colors, constants
) => ({
  boxShadow: 1,
  "&.Mui-expanded": { margin: theme.spacing(1, 0) },
})
export const questionSupportA = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: colors.lightGrey,
  color: colors.color,
  borderRadius: 5,
  margin: theme.spacing(1, 0),
  "&:before": {
    height: 0,
  },
  "&.Mui-expanded": {},
})
export const helpSupportA = (
  ownerState, theme, colors, constants
) => ({
  backgroundColor: colors.infoBackground,
  color: colors.color,
  borderRadius: 5,
})
export const summaryNavHeader = (
  ownerState, theme, colors, constants
) => ({
  minHeight: `25px !important`,
  flexBasis: `90%`,
  flexShrink: 0,
  overflow: `hidden`,
  textOverflow: `ellipsis`,
  padding: theme.spacing(0, 1.5),
  "& .opened:hover": {
    color: `${colors.secondary}!important`,
  },
  "&:hover": {
    "& .opened": {
      color: `${colors.secondary} !important`,
    },
    "& svg": {
      color: `${colors.secondary} !important`,
      "& path": {
        fill: `${colors.secondary} !important`,
      },
    },
  },
  "&::before": {
    opacity: 0,
  },
  "& .closed": {
    opacity: 0,
    width: 0,
    height: `25px !important`,
    marginLeft: `${theme.spacing(0)}!important`,
  },
  "& .opened": {
    transition: theme.transitions.create([ `opacity`, `width` ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
      delay: 175,
    }),
  },
  "& p, a": {
    [`&.${types.nav.blackFriday}`]: {
      color: colors.white,
      marginLeft: `${theme.spacing(2)}`,
    },
    textDecoration: `none`,
    marginLeft: `${theme.spacing(2)}`,
    color: colors.color,
    fontSize: theme.typography.pxToRem(16),
    textTransform: `uppercase`,
    fontWeight: 700,
    width: `100%`,
  },
  "& .icon": {
    color: colors.color,
  },
  "& .MuiAccordionSummary-content": {
    "& div div": {
      alignItems: `center`,
      display: `flex`,
    },
    alignItems: `center`,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    width: 35,
    height: 35,
  },
  "&.Mui-expanded::before": {
    opacity: `1 !important`,
  },
  "&.Mui-expanded": {
    [`& .svg-class-${types.nav.dynamic}`]: {
      "& path": {
        fill: colors.main,
      },
    },
    "& p, a, .icon": {
      color: colors.main,
    },
    [`& .${types.nav.outlet}, .${types.nav.brand}, .${types.nav.blackFriday}, .${types.nav.b2b}`]: {
      color: `${colors.secondary}!important`,
    },
    [`& .svg-class-${types.nav.outlet},  .svg-class-${types.nav.brand}, .svg-class-${types.nav.blackFriday}, .svg-class-${types.nav.b2b}`]: {
      "& path": {
        fill: `${colors.secondary} !important`,
      },
    },
    "& .MuiAccordionSummary-content": {
      margin: `${theme.spacing(1.5, 0)}!important`,
    },
    [`&.${types.events.special}`]: {
      backgroundColor: ownerState.specialcolor,
      "& a": { color: `${colors.white} !important` },
      [`& .svg-class-${types.events.special}`]: {
        "& path": {
          fill: `${colors.white}!important`,
        },
      },
    },
  },
  [`& .svg-class-${types.nav.blackFriday}, 
	.svg-class-${types.nav.b2b},
	.svg-class-${types.events.special},
	.svg-class-${types.nav.outlet},
	.svg-class-${types.nav.brand},
	.svg-class-${types.nav.dynamic}`]: {
    width: constants.DESKTOP_MENU.ICON_SIZE,
  },
  [`&.${types.nav.blackFriday}`]: {
    background: colors.dark,
    [`&.${types.nav.blueDay}`]: {
      background: `${colors.main}!important`,
    },
  },
  [`& .svg-class-${types.nav.blackFriday}`]: {
    "& path": {
      fill: colors.white,
    },
  },
  [`& .svg-class-${types.nav.outlet}, .svg-class-${types.nav.brand}, .svg-class-${types.nav.dynamic}, .svg-class-${types.nav.b2b}`]: {
    "& path": {
      fill: colors.color,
    },
  },
  [`&.${types.events.special}`]: {
    "& a": { color: `${ownerState.specialcolor} !important` },
    [`& .svg-class-${types.events.special}`]: {
      "& path": {
        fill: `${ownerState.specialcolor}`,
      },
    },
  },
})
export const summaryNavSubHeader = (
  ownerState, theme, colors, constants
) => ({
  minHeight: `${ownerState.ismobile === `false` ? 25 : 45}px !important`,
  whiteSpace: `break-spaces`,
  backgroundColor: `${colors.white}90`,
  padding: theme.spacing(
    0, ownerState.ismobile === `false` ? 1.5 : 2.5, 0, ownerState.ismobile === `false` ? 6.5 : 4
  ),
  "& .MuiAccordionSummary-content": {
    margin: `${theme.spacing(ownerState.ismobile === `false` ? 0.5 : 1, 0)}!important`,
    color: colors.color,
    "&:hover": {
      color: colors.secondary,
    },
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    width: ownerState.icon === `true` ? 35 : 0,
    height: ownerState.icon === `true` ? 35 : 0,
  },
  "&.Mui-expanded": {
    margin: `${theme.spacing(0, 0)}!important`,
    minHeight: `${ownerState.ismobile === `false` ? 25 : 40}px !important`,
    "& .MuiAccordionSummary-content": {
      margin: `${theme.spacing(!ownerState.ismobile === `false` ? 0.5 : 1, 0)}!important`,
    },
    "&.menuOpen::before": {
      opacity: `0 !important`,
    },
  },
  "& a": {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    color: `inherit`,
    textDecoration: `none`,
    width: `100%`,
    "&:hover": {
      color: colors.secondary,
    },
    "&.selected": {
      fontWeight: 700,
      color: colors.secondary,
    },
  },
  "& .icon": {
    color: colors.darkGrey2,
  },
  "&.subHeading_level_2": {
    minHeight: `${ownerState.ismobile === `false` ? 25 : 35}px !important`,
    padding: `${theme.spacing(
      0, 2, 0, ownerState.ismobile === `false` ? 8 : 6.5
    )}!important`,
    "& .MuiAccordionSummary-content": {
      margin: `${theme.spacing(0.5, 0)}!important`,
      color: colors.darkGrey,
      "&:hover": {
        color: colors.secondary,
      },
    },
    "& a": {
      fontSize: `${theme.typography.pxToRem(ownerState.ismobile === `false` ? 16 : 15)} !important`,
      fontWeight: 500,
      color: `inherit`,
      textDecoration: `none`,
      width: `100%`,
      "&:hover": {
        color: colors.secondary,
      },
      "&.selected": {
        fontWeight: 600,
        color: colors.secondary,
      },
    },
  },
})
export const accordionModCookieAs = (
  ownerState, theme, colors, constants
) => ({
  minHeight: `45px`,
  padding: 0,
  "&.Mui-expanded": {
    minHeight: `45px`,
  },
  "& .Mui-expanded": {
    margin: theme.spacing(
      1, 1, 1, 1
    ),
  },
  "& > *": {
    margin: theme.spacing(
      0, 1, 0, 1
    ),
  },
})
export const questionSupportAs = (
  ownerState, theme, colors, constants
) => ({
  display: `flex`,
  "& .MuiAccordionSummary-content": {
    alignItems: `center`,
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
  "&.Mui-disabled": {
    opacity: 1,
  },
  "&.Mui-expanded": {
    height: `max-content`,
  },
})
export const helpSupportAs = (
  ownerState, theme, colors, constants
) => ({
  display: `flex`,
  "& .MuiAccordionSummary-content": {
    alignItems: `center`,
    [theme.breakpoints.down(`xl`)]: {
      height: 50,
    },
  },
  "&.Mui-disabled": {
    opacity: 1,
  },
  "&.Mui-expanded": {
    height: `max-content`,
  },
  "& .svg-class-support": {
    width: 20,
    marginRight: theme.spacing(1.5),
    "& path": {
      fill: colors.color,
    },
  },
})
export const detailsNav = (
  ownerState, theme, colors, constants
) => ({
  padding: `${theme.spacing(
    0, 0, 0, 0
  )}!important`,
  flexDirection: `column`,
})
export const questionSupportAd = (
  ownerState, theme, colors, constants
) => ({
  [theme.breakpoints.down(`xl`)]: {
    minHeight: 225,
  },
  minHeight: 200,
  paddingLeft: theme.spacing(ownerState.tablet === `false` ? 3 : 5),
  paddingRight: theme.spacing(ownerState.tablet === `false` ? 3 : 7),
})
export const helpSupportAd = (
  ownerState, theme, colors, constants
) => ({
  [theme.breakpoints.down(`xl`)]: {
    minHeight: 225,
  },
  minHeight: 200,
})
