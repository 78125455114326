// export const example = (ownerState, theme, colors, constants) => ()
import { colors as muicolor } from "@mui/material"
export const selectItem = (
  ownerState, theme, colors, constants
) => ({
  transition: theme.transitions.create([ `color`, `background-color` ], {
    easing: theme.transitions.easing.sharp,
    duration: 225,
  }),
  "&:hover": {
    color: colors.secondary,
    backgroundColor: `${colors.darkGrey2}20 !important`,
    transition: theme.transitions.create([ `color`, `background-color` ], {
      easing: theme.transitions.easing.sharp,
      duration: 225,
    }),
  },
})
export const accButton = (
  ownerState, theme, colors, constants
) => ({
  color: ownerState.svgclass === `logout` ? muicolor.red[400] : ownerState.selected ? ownerState.tcl || colors.secondary : colors.color,
  fontWeight: `700`,
  backgroundColor: `transparent`,
  fontSize: theme.typography.pxToRem(15),
  marginBottom: ownerState.small === `true` ? 0 : 10,
  padding: theme.spacing(ownerState.pv || 0.5, ownerState.ph || 0),
  display: `flex`,
  alignItems: `center`,
  "&:hover": {
    backgroundColor: `transparent`,
    [`.svg-class-${ownerState.svgclass}`]: {
      "& .st0": {
        fill: ownerState.svgclass === `logout` ? `currentcolor` : colors.secondary,
      },
      "& path": {
        fill: ownerState.svgclass === `logout` ? `currentcolor` : colors.secondary,
      },
    },
  },
  "&.Mui-selected": {
    backgroundColor: `transparent`,
    "&:hover": {
      backgroundColor: `transparent`,
    },
  },
  [`.svg-class-${ownerState.svgclass}`]: {
    width: constants.ICON_SIZE + 6,
    marginRight: theme.spacing(ownerState.nm || 1.5),
    display: `flex`,
    "& .st0": {
      fill: ownerState.svgclass === `logout` ? muicolor.red[400] : ownerState.selected ? ownerState.tcl || colors.secondary : colors.color,
    },
    "& path": {
      fill: ownerState.svgclass === `logout` ? muicolor.red[400] : ownerState.selected ? ownerState.tcl || colors.secondary : colors.color,
    },
  },
})
export const filterBtn = (
  ownerState, theme, colors, constants
) => ({
  fontSize: theme.typography.pxToRem(ownerState.font || 12),
  fontWeight: 500,
  textTransform: ownerState.transform || `uppercase`,
  backgroundColor: `transparent`,
  width: `100%`,
  "& .text": {
    width: `100%`,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    whiteSpace: `nowrap`,
  },
  minHeight: 30,
  color: colors.color,
  "&.active": {
    color: colors.secondary,
  },
  padding: theme.spacing(
    0.5, 2.5, 0.5, 1
  ),
  "&:hover": {
    "& svg": {
      color: colors.secondary,
    },
    color: colors.secondary,
    backgroundColor: `${colors.lightGrey}90 !important`,
  },
})
