'use client'

import { LoadingContext } from '@/context/tools/loading'
import { config } from '@/middleware/config/config'
import { cmp } from '@/types'
import IconsSVG from '@/utils/icons/IconsSVG'
import { ArrowBackIosNew, Clear } from '@mui/icons-material'
import {
  Box, Grid2, IconButton, InputAdornment, TextField
} from '@mui/material'
import { hasCookie } from 'cookies-next'
import Link from "next/link"
import { useRouter, useSearchParams } from 'next/navigation'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { IoIosArrowUp } from 'react-icons/io'
import { BoxChips } from './components'

const {
  COOKIES: { LAST_SEARCH, COOKIES_POLICY },
} = config

export const TopSearch = props => {
  const {
    text, setText, handleText, handleSynonyms, value, chips, setChips, handleCloseSearchModal, hostname
  } = props
  const { business, colors, mobile } = hostname
  const router = useRouter()
  const pageParams = useSearchParams()
  const { modules } = useContext(LoadingContext)
  const { img, src } = pageParams
  const aceptedCookies = useMemo(() => hasCookie(COOKIES_POLICY), [])
  const handleClear = useCallback(async(itemVal, start, end) => {
    const rep = await text.replace(`${itemVal}`, ``).trim()
    await setChips(x => x.filter(b => rep?.split(` `).includes(itemVal)))
    aceptedCookies && await localStorage.setItem(LAST_SEARCH, rep)
    await setText(x => rep)
  })

  const handleReplace = useCallback((
    oldValue, newValue, start, end
  ) => {
    const rep = text.replace(`${!start ? ` ` : ``}${oldValue}${!end ? ` ` : ``}`, `${!start ? ` ` : ``}${newValue}${!end ? ` ` : ``}`).trim()
    setText(x => rep)
    aceptedCookies && localStorage.setItem(LAST_SEARCH, rep)
  })

  const handleReplaceAll = useCallback(async(oldValue, newValue, flag = false) => {
    if (text === `` || flag) {
      await setChips(x => [
        {
          idx: null,
          label: newValue,
          referer: newValue,
          save: false
        }
      ])
      await setText(newValue.trim())
    } else {
      await setText(x => x.replace(oldValue.trim(), newValue.trim()))
    }
    const tmp = await text?.split(` `)
    handleSynonyms(tmp)
  })

  const handleEnter = useCallback(e => {
    e.preventDefault()

    if (e.keyCode === 13 && chips?.length > 0) {
      const search = chips.map(x => x.label.toLowerCase()).join(`+`)
      modules.handleLinkLoading(true)
      handleCloseSearchModal()
      router.push(`${globalThis.window.location.origin}/search?s=${search}`)
    }
  })
  useEffect(() => {
    if (value !== ``) {
      const tmp = value.trim()?.split(` `)
      handleSynonyms(tmp, false)
    } else {
      setChips([])
    }
  }, [value])

  return (
    <>
      {mobile && <Grid2 size={{ xs: 2 }}
        sx={{
          display: mobile && `flex`,
          alignContent: `center`,
          justifyContent: `center`
        }}>
        {mobile ?
          <IconButton onClick={handleCloseSearchModal} ><ArrowBackIosNew /></IconButton> :
          <Link href="/" passHref >
            <IconsSVG name="logo" path={business?.logo} fillcolor={colors.main} wdth={200} hght={50} />
          </Link>}
      </Grid2>}
      <Grid2 size={{
        xs: mobile ? 10 : 12,
        md: mobile ? 10 : 12
      }}
      >
        <Box sx={{
          width: `100%`,
          display: `flex`
        }}>
          <TextField
            inputRef={input => {
              if (open && input !== null && src !== `2`) {
                input.focus()
              }
            }}
            cmptype={cmp.searchBarModal}
            id={`input-search`}
            type="search-modal"
            value={text}
            onChange={handleText}
            onKeyUp={handleEnter}
            InputProps={{
              endAdornment: text !== `` && (
                <InputAdornment position="end" onClick={() => {
                  setText(``)
                  aceptedCookies && localStorage.setItem(LAST_SEARCH, ``)
                  setChips([])
                }}>
                  <Clear sx={{
                    color: colors.darkGrey2,
                    cursor: `pointer`
                  }} />
                </InputAdornment>
              ),
            }}
            ismobile={mobile.toString()}
            variant={mobile ? `standard` : `outlined`}
          />
          {!mobile && <IconButton onClick={handleCloseSearchModal} sx={{
            width: 45,
            marginRight: 0,
            marginLeft: 1,
            "&:hover": { background: `transparent` }
          }}><IoIosArrowUp /></IconButton>}
        </Box>
        <BoxChips mobile={mobile} desk={!mobile}
          width={mobile ? 100 : 80} {...{
            chips,
            open,
            handleClear,
            handleReplace,
            handleReplaceAll,
            colors
          }} />
      </Grid2>
    </>
  )
}
