import { cmp } from "@/types"
import { colors, constants } from "@/utils/global"
import { createTheme } from "@mui/material"
import { func } from "."
const theme = createTheme()
export const paperTheme = createTheme({
  components: {
    MuiPaper: {
      defaultProps: {},
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...ownerState.cmptype === cmp.paperLang && func.paper.paperLang(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accMenu && func.paper.accMenu(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.mobileMenu && func.paper.mobileMenu(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.sectionMenu && func.paper.sectionMenu(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.forwardMenu && func.paper.forwardMenu(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.FooterMain && func.paper.FooterMain(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.ourShops && func.paper.ourShops(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.msgCookies && func.paper.msgCookies(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.bannerHomeImg && func.paper.bannerHomeImg(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.sectionHome && func.paper.sectionHome(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.cardHomeSection && func.paper.cardHomeSection(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.brandLink && func.paper.brandLink(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.motoBoxSearch && func.paper.motoBoxSearch(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.paperPopper && func.paper.paperPopper(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.productCard && func.paper.productCard(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.productCardC && func.paper.productCardC(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.productCardInfo && func.paper.productCardInfo(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.paperRating && func.paper.paperRating(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accButton && func.paper.accButton(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accBox && func.paper.accBox(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.addrItem && func.paper.addrItem(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.addrBtnText && func.paper.addrBtnText(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.itemOrder && func.paper.itemOrder(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.formTexts && func.paper.formTexts(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.infoDiv && func.paper.infoDiv(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.textDescription && func.paper.textDescription(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.boxChips && func.paper.boxChips(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.filterBox && func.paper.filterBox(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.paperSlider && func.paper.paperSlider(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.whislist && func.paper.whislist(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.couponList && func.paper.couponList(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.brandCard && func.paper.brandCard(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.banner404Img && func.paper.banner404Img(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.paperBody && func.paper.paperBody(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.paperSumaryBox && func.paper.paperSumaryBox(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.paperBoxTitle && func.paper.paperBoxTitle(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.paperBodyBox && func.paper.paperBodyBox(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.paperBottomBox && func.paper.paperBottomBox(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.payIcon && func.paper.payIcon(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.ticket && func.box.chatTicket(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.response && func.box.chatResponse(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
  },
})
