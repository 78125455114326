import { constructScript, removeScript } from "@/utils/functions/scripts"
import { useEffect } from "react"

export const MicroWebSite = ({ hostname }) => {
  const { business, domain } = hostname
  const data = JSON.stringify({
    "@context": `http://www.schema.org`,
    "@type": `WebSite`,
    name: business.alias,
    alternateName: business.metaTitle,
    url: `${business.webUrl}${domain}`,
    potentialAction: {
      "@type": `SearchAction`,
      target: `${business.webUrl}${domain}?s={search_term_string}`,
      "query-input": `required name=search_term_string`,
    },
  })

  useEffect(() => {
    const name = `micro-web-site`
    constructScript(`script`, name, data)

    return () => {
      removeScript(name)
    }
  }, [data])

  return null
}
