/* eslint-disable default-param-last */
import { types } from "@/types"
export const searchInitialState = {
  open: false,
}
export const searchReducer = (state = searchInitialState, action) => {
  switch (action.type) {
  case types.searchCatalogue.toggle:
    return {
      ...state,
      open: !state.open,
    }
  case types.searchCatalogue.open:
    return {
      ...state,
      open: true,
    }
  case types.searchCatalogue.close:
    return {
      ...state,
      open: false,
    }

  default:
    return state
  }
}
