'use client'
import { TWButton } from "@/components/tailwind/Button"
import { CookiesContext } from "@/context"
import { ToolsContext } from "@/context/tools/tools"
import { config } from "@/middleware/config/config"
import { types } from "@/types"
import { googleCookies } from "@/utils/functions/google"
import { fontQuicksand } from "@/utils/global"
import Switch from '@mui/material/Switch'
import clsx from "clsx"
import parse from "html-react-parser"
import Cookies from "js-cookie"
import { useContext, useState } from "react"
import { IoMdClose } from "react-icons/io"

const {
  COOKIES: { COOKIES_POLICY, COOKIES_AD_USER_DATA, COOKIES_AD_PERSONALIZATION },
} = config
export const CookieModal = ({ cookiesTexts, hostname }) => {
  const { business: { alias }, styles } = hostname

  const { [types.cookies.preference.preferences]: open, toggleCookiePreferences, toggleViewMsgCookie } = useContext(CookiesContext)
  const { tools: { colors }} = useContext(ToolsContext)
  const [ checked1, setChecked1 ] = useState(true)
  const [ checked2, setChecked2 ] = useState(true)
  const [ expanded, setExpanded ] = useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(expanded === panel ? false : panel)
  }

  const handleChangeChecked = () => {
    setChecked1(!checked1)
  }
  const handleChangeChecked2 = () => {
    setChecked2(!checked2)
  }

  const handleSubmit = async type => {
    const cookiesTypes = [ COOKIES_POLICY, COOKIES_AD_USER_DATA, COOKIES_AD_PERSONALIZATION ]
    const actions = {
      submit: () => cookiesTypes.forEach(x => Cookies.set(x, 1, { expires: 90 })),
      confirm: () => {
        Cookies.set(COOKIES_POLICY, 1, { expires: !checked1 && !checked2 ? 1 : 90 })
        Cookies.set(COOKIES_AD_USER_DATA, checked1 ? 1 : 0, { expires: checked1 ? 90 : 1 })
        Cookies.set(COOKIES_AD_PERSONALIZATION, checked2 ? 1 : 0, { expires: checked2 ? 90 : 1 })
      },
      refuse: () => cookiesTypes.forEach((x, idx) => Cookies.set(x, idx === 0 ? 1 : 0, { expires: 1 }))
    }

    actions[type]?.()
    await toggleViewMsgCookie()
    await toggleCookiePreferences()
    await googleCookies()
  }

  return (
    <div onClick={() => toggleCookiePreferences()} className="fixed inset-0 flex items-center justify-center bg-neutral-900 bg-opacity-50 z-[100]">
      <div onClick={e => e.stopPropagation()} className={`bg-white tablet:w-[80%] max-w-[600px] max-h-[90dvh] tablet:max-h-[80dvh] rounded shadow-lg overflow-y-auto ${styles?.font ?? `font-quicksand`}`}>
        <div className="sticky bg-white w-full top-0 p-2">
          <div className="justify-self-end pt-3 pr-3">
            <button aria-label="close-modal" onClick={toggleCookiePreferences} size="small">
              <IoMdClose />
            </button>
          </div>
          <div className="pb-2" >
            <h1 className="text-center font-[500]">
              {parse(cookiesTexts.info.preferences.toUpperCase() || ``)}
            </h1>
          </div>
        </div>
        <div className="overflow-y-scroll">
          <div className="px-8 py-1 text-sm text-justify font-[200]">
            {parse(cookiesTexts.why[0].replace(`$alias`, alias) || ``)}
          </div>
          <div className="px-8 py-1 text-sm text-justify font-[200]">
            {parse(cookiesTexts.info.message[2])}
          </div>
          <div className="flex justify-evenly mt-4">
            <TWButton {...{ colors }} onClick={() => handleSubmit(`refuse`)} className="text-base w-[40%] py-[6px] font-[400]" aria-label="accept-cookie">
              {cookiesTexts.info.remove}
            </TWButton>
            <TWButton {...{ colors }} onClick={() => handleSubmit(`submit`)} className="text-base w-[40%] py-[6px] font-[400]" aria-label="decline-cookie">
              {cookiesTexts.info.accept}
            </TWButton>
          </div>
          <div className="mx-8 mt-4 mb-2 shadow-sm shadow-neutral-400 divide-y">
            <div className="py-3">
              <div className="flex">
                <button className={`grow rounded-none p-2 justify-start`}
                  onClick={handleChange(`tecnicas`)}
                >
                  <p className="text-lg ml-4 uppercase">
                    {parse(cookiesTexts.types.purpose.cookies[0].type || ``)}
                  </p>
                </button>
                <div style={{ color: colors.main }} className="uppercase font-[700] text-[12px] w-20">
                  {parse(cookiesTexts.info.active)}
                </div>
              </div>
              { expanded === `tecnicas` &&
              <div className="px-3 pt-2">
                <div className="bg-neutral-100 align-middle p-6">
                  <div className="text-sm mb-3">
                    {parse(cookiesTexts.types.purpose.cookies[0].text || ``)}
                  </div>
                  <div className={clsx(`cookies`, fontQuicksand.className)}>
                    <span className="font-[800] text-[13px] capitalize">
                      {`${parse(cookiesTexts.info.name)}:`}
                    </span>
                    <ul className={clsx(`li`, fontQuicksand.className)}>
                      {cookiesTexts.info.cookies.map((x, i) => [ 1, 2, 3 ].includes(i) && (
                        <li key={`${cookiesTexts.info.name}_${i}`}>
                          {x.name.replace(`$cookie`, config.COOKIES._WLYR_)} {`-`} {parse(x.service)}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              }
            </div>
            <div className="py-3">
              <div className="flex">
                <button className={`grow rounded-none p-2 justify-start`}
                  onClick={handleChange(`userData`)}
                >
                  <p className="text-lg ml-4 uppercase">
                    {parse(cookiesTexts.types.purpose.cookies[1].type || ``)}
                  </p>
                </button>
                <div className="flex align-bottom">
                  <Switch checked={checked1} onClick={handleChangeChecked} color="secondary" name="checkedA"/>
                </div>
              </div>
              { expanded === `userData` &&
              <div className="px-3 pt-2">
                <div className="bg-neutral-100 align-middle p-6">
                  <div className="text-sm mb-3">
                    {parse(cookiesTexts.types.purpose.cookies[1].text || ``)}
                  </div>
                  <div>
                    <span className="font-[800] text-[13px] capitalize">
                      {`${parse(cookiesTexts.info.name)}:`}
                    </span>
                    <ul className="text-sm font-[300]">
                      {cookiesTexts.info.cookies.map((x, i) => [
                        4, 5, 6, 7
                      ].includes(i) && (
                        <li key={`${cookiesTexts.info.name}_${i}`}>
                          {parse(x.name)} {`-`} {parse(x.service)}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              }
            </div>
            <div className="py-3">
              <div className="flex">
                <button className={`grow rounded-none p-2 justify-start`}
                  onClick={handleChange(`personalizated`)}
                >
                  <p className="text-lg ml-4 uppercase">
                    {parse(cookiesTexts.types.entity.cookies[2].type || ``)}
                  </p>
                </button>
                <div className="flex align-bottom">
                  <Switch checked={checked2} onClick={handleChangeChecked2} color="secondary" name="checkedB" inputProps={{ "aria-label": `primary checkbox` }} />
                </div>
              </div>
              { expanded === `personalizated` &&
              <div className="px-3 pt-2">
                <div className="bg-neutral-100 align-middle p-6">
                  <div className="text-sm mb-3">
                    {parse(cookiesTexts.types.entity.cookies[2].text || ``)}
                  </div>
                  <div className={clsx(`cookies`, fontQuicksand.className)}>
                    <span className="font-[800] text-[13px] capitalize">
                      {`${parse(cookiesTexts.info.name)}:`}
                    </span>
                    <ul className={clsx(`li`, fontQuicksand.className)}>
                      {cookiesTexts.info.cookies.map((x, i) => [8].includes(i) && (
                        <li key={`${cookiesTexts.info.name}_${i}`}>
                          {parse(x.name)} {`-`} {parse(x.service)}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
        <div className="sticky bg-white w-full bottom-0 py-4 px-8">
          <div className="justify-self-center tablet:justify-self-end w-[100%] tablet:w-60">
            <TWButton {...{ colors }} className="text-base w-full py-[6px] font-[400]" onClick={() => handleSubmit(`confirm`)} aria-label="confirm-cookie">
              {cookiesTexts.info.confirm.toUpperCase()}
            </TWButton>
          </div>
        </div>
      </div>
    </div>
  )
}
