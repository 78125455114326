'use client'
import { cmp } from "@/types"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Typography from "@mui/material/Typography"
import parse from "html-react-parser"
import Script from "next/script"

export const Recaptcha = props => {
  const urlSrc = `${process.env.NEXT_PUBLIC_RECAPTCHA}?render=${process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC}`
  return <Script src={urlSrc} strategy="lazyOnload" />
}

export const CheckRecaptcha = props => {
  const { checkedCaptcha, setCheckedCaptcha, robot, colors } = props
  const handleCheck = () => {
    setCheckedCaptcha(!checkedCaptcha)
  }
  return (
    <FormControlLabel
      cmptype={cmp.checkLabel}
      label={
        <Typography cmptype={cmp.texts} gutterBottom line={1.2} font={16} mb={0} weight={700} texcolor={checkedCaptcha ? colors.darkGrey : colors.redBtn}>
          {parse(`* ${robot}`)}
        </Typography>
      }
      control={<Checkbox cmptype={cmp.checkLegal} checked={checkedCaptcha} onChange={handleCheck} />}
    />
  )
}
