'use client'
import { SearchContext } from "@/context/search"
import { ToolsContext } from "@/context/tools/tools"
import IconsSVG from "@/utils/icons/IconsSVG"
import { useContext, useMemo } from "react"

export const SearchBar = ({
  ismobile, hostname, ...props
}) => {
  const { colors, texts: { main_layout }} = hostname
  const { toggleSearchOpen } = useContext(SearchContext)
  return (<div className="rounded-full flex w-0 bg-white 500:w-1/4 h-7 mx-2 cursor-pointer items-center p-[15px] justify-center 500:justify-between" onClick={() => toggleSearchOpen()}>
    <p style={{ color: colors.main }} className="hidden 500:block pr-2 m-0">{main_layout.search}</p>
    <IconsSVG id={`search`} name="search" path="/img/client-icons/web-search.svg" size={18} fillcolor={colors.main} onClick={() => toggleSearchOpen()} />
  </div>)
}
