// export const example = (ownerState, theme, colors, constants) => ()

import { colors as muicolor } from "@mui/material"

export const productCardC = (
  ownerState, theme, colors, constants
) => ({
  width: ownerState.mobile === `false` ? 200 + ownerState.plus : ownerState.xs === `true` ? 164 : 170,
  height: ownerState.mobile === `false` ? 325 + (ownerState.plus < 0 ? ownerState.plus - 20 : ownerState.plus) : 325,
  marginRight: theme.spacing(ownerState.mobile === `false` ? 1 : 0.25),
  marginLeft: theme.spacing(ownerState.mobile === `false` ? 1 : 0.25),
  marginBottom: 16,
  textDecoration: `none`,
  textAlign: `left`,
  borderRadius: `10px`,
  backgroundColor: colors.white,
  border: `1px solid transparent`,
  overflow: `unset`,
  "& .contrast": {
    padding: theme.spacing(
      2, 2, 2, 2
    ),
    backgroundColor: colors.white,
    filter: `contrast(0.925)`,
    transition: theme.transitions.create([ `filter`, `border` ], {
      easing: theme.transitions.easing.sharp,
      duration: 100,
    }),
  },
  "&:hover": {
    "& .contrast": {
      filter: ownerState.mobile === `false` ? `contrast(0.950)` : `contrast(0.925)`,
      transition: theme.transitions.create([ `filter`, `border` ], {
        easing: theme.transitions.easing.sharp,
        duration: 100,
      }),
    },

    filter: ownerState.mobile === `false` ? `contrast(1.1)` : `inherit`,
    transition: theme.transitions.create([ `filter`, `border` ], {
      easing: theme.transitions.easing.sharp,
      duration: 100,
    }),
    border: `${ownerState.mobile === `false` ? 1 : 0}px solid ${colors.borderProduct}`,
  },
  "& .MuiCardActionArea-focusHighlight": {},
})
export const productCardCaa = (
  ownerState, theme, colors, constants
) => ({
  height: `inherit`,
  display: `flex`,
  alignItems: `flex-start`,
  "& span": {
    width: `100%`,
  },
  "&:hover": {
    backgroundColor: `transparent !important`,
  },
})
export const productCardCc = (
  ownerState, theme, colors, constants
) => ({
  padding: theme.spacing(0),
  width: `100%`,
})
export const addrItem = (
  ownerState, theme, colors, constants
) => ({
  textAlign: `left`,
  paddingBottom: `0 !important`,
  padding: `0`,
  width: `100%`,
})
export const bikeCard = (
  ownerState, theme, colors, constants
) => ({
  marginBottom: 8,
  textDecoration: `none`,
  textAlign: `left`,
  borderRadius: `10px`,
  backgroundColor: colors.white,
  border: `1px solid ${colors.lightGrey}`,
  "& .contrast": {
    padding: theme.spacing(
      2, 2, 2, 2
    ),
    backgroundColor: colors.white,
    filter: `contrast(0.975)`,
    transition: theme.transitions.create([ `filter`, `border` ], {
      easing: theme.transitions.easing.sharp,
      duration: 100,
    }),
  },
  "&:hover": {
    "& .contrast": {
      filter: ownerState.mobile === `false` ? `contrast(0.950)` : `contrast(0.925)`,

      transition: theme.transitions.create([ `filter`, `border` ], {
        easing: theme.transitions.easing.sharp,
        duration: 100,
      }),
    },

    filter: ownerState.mobile === `false` ? `contrast(1.1)` : `inherit`,
    transition: theme.transitions.create([ `filter`, `border` ], {
      easing: theme.transitions.easing.sharp,
      duration: 100,
    }),
    boxShadow: `0 0 0 1px #00000045`,
  },
  "& .MuiCardActionArea-focusHighlight": {},
})
export const bikeCardArea = (
  ownerState, theme, colors, constants
) => ({
  height: `inherit`,
  display: `flex`,
  alignItems: `flex-start`,
  "& span": {
    width: `100%`,
  },
  "&:hover": {
    backgroundColor: `transparent !important`,
  },
})
export const bikeCardContent = (
  ownerState, theme, colors, constants
) => ({
  width: ownerState.mobile === `false` ? ownerState.small === `true` ? `100%` : 300 + ownerState.plus : ownerState.small === `true` ? `100%` : 300,
  height: ownerState.mobile === `false` ? ownerState.small === `true` ? `auto` : 320 + ownerState.plus : ownerState.small === `true` ? `auto` : 320,
  padding: theme.spacing(0),
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `space-between`,
})
export const lineCard = (
  ownerState, theme, colors, constants
) => ({
  width: ownerState.wd,
  height: ownerState.hg,
  textDecoration: `none`,
  textAlign: `left`,
  borderRadius: `10px`,
  backgroundColor: colors.white,
  border: `1px solid transparent`,
  "& .contrast": {
    padding: theme.spacing(0.5),
    backgroundColor: colors.white,
    filter: `contrast(${ownerState.ctr - 0.025})`,
    transition: theme.transitions.create([ `filter`, `border` ], {
      easing: theme.transitions.easing.sharp,
      duration: 100,
    }),
  },
  "&:hover": {
    "& .contrast": {
      filter: `contrast(${ownerState.ctr})`,
      transition: theme.transitions.create([ `filter`, `border` ], {
        easing: theme.transitions.easing.sharp,
        duration: 100,
      }),
    },

    filter: `contrast(${ownerState.ctr + 0.155})`,
    transition: theme.transitions.create([ `filter`, `border` ], {
      easing: theme.transitions.easing.sharp,
      duration: 100,
    }),
    border: `1px solid ${colors.borderProduct}`,
  },
  "& .MuiCardActionArea-focusHighlight": {},
})
export const lineCardAction = (
  ownerState, theme, colors, constants
) => ({
  display: `flex`,
  alignItems: `flex-start`,
  justifyContent: `flex-start`,
  height: `inherit`,
})
export const lineCardActionDelete = (
  ownerState, theme, colors, constants
) => ({
  display: `flex`,
  justifyContent: `center`,
  alignItems: `flex-start`,
  background: colors.white,
  height: ownerState.ht || 75,
  width: ownerState.wd,
  "& .svg-class-delete": {
    width: 20,
    "& .st0": {
      fill: colors.darkGrey2,
    },
  },
  "&:hover": {
    background: colors.white,
    "& .st0": { fill: muicolor.red[700] },
  },
})
