import { types } from "@/types"
import { IoMdArrowBack, IoMdClose } from "react-icons/io"

import IconsSVG from "@/utils/icons/IconsSVG"
import Link from "next/link"
import { getControlledSections } from "@/utils/functions"

export const OpenSubMenu = ({ ...props }) => {
  const {
    toggleOpenSwip, openCategory, toggleOpenCategory, category, colors, webPages
  } = props
  const footerState = getControlledSections(webPages, `home`)
  return (
    <div className="flex items-center w-full bg-transparent">
      <button className="p-0 w-6 mx-1" onClick={() => toggleOpenCategory(!openCategory)}>
        <IoMdArrowBack style={{ color: colors.mainContrast }}/>
      </button>
      <IconsSVG name={types.nav.dynamic} path={category.icon} abbr={footerState.nativeLogo ? `native` : ``}/>
      <p style={{ color: colors.mainContrast }} className="text-xl pl-1 font-semibold">{category.h1}</p>
      <button className="p-0 w-6 mr-1 ml-auto" onClick={() => toggleOpenSwip(false)} >
        <IoMdClose style={{ color: colors.mainContrast }}/>
      </button>
    </div>
  )
}

export const CloseSubMenu = ({ ...props }) => {
  const { toggleOpenSwip, business, colors, webPages } = props
  const footerState = getControlledSections(webPages, `home`)
  const handleClose = () => toggleOpenSwip(false)
  return !!colors.logo && (
    <>
      <IoMdClose color={colors.white} className="size-6 ml-1 cursor-pointer" onClick={handleClose}/>
      <div className="flex w-full justify-center items-center" onClick={ handleClose}>
        <Link prefetch={false} href="/" passHref legacyBehavior shallow={false} scroll={true}>
          <IconsSVG name="logo" path={business?.logo} fillcolor={colors.logo.logoMain} wdth={170} hght={40} abbr={footerState.nativeLogo ? `native` : ``}/>
        </Link>
      </div>
    </>
  )
}
