import { createTheme } from "@mui/material/styles"
import { colors, constants, fontQuicksand } from "@/utils/global"
import { cmp } from "@/types"
import { func } from "."
const theme = createTheme()
export const accordionTheme = createTheme({
  components: {
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
        square: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          boxShadow: `none`,
          "&::before": {
            opacity: 0,
          },
          fontFamily: fontQuicksand.style.fontFamily,
          ...ownerState.cmptype === cmp.accClass && func.accordion.accClass(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accordionModCookie && func.accordion.accordionModCookieA(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.questionSupport && func.accordion.questionSupportA(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.helpSupport && func.accordion.helpSupportA(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          ...ownerState.cmptype === cmp.summaryNavHeader && func.accordion.summaryNavHeader(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.summaryNavSubHeader && func.accordion.summaryNavSubHeader(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.accordionModCookie && func.accordion.accordionModCookieAs(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.questionSupport && func.accordion.questionSupportAs(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.helpSupport && func.accordion.helpSupportAs(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fontQuicksand.style.fontFamily,
          ...ownerState.cmptype === cmp.detailsNav && func.accordion.detailsNav(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.questionSupport && func.accordion.questionSupportAd(
            ownerState, theme, colors, constants
          ),
          ...ownerState.cmptype === cmp.helpSupport && func.accordion.helpSupportAd(
            ownerState, theme, colors, constants
          ),
        }),
      },
    },
  },
})
