'use client'
import { MobileContext } from '@/context'
import { cmp, types } from '@/types'
import { SwipeableDrawer } from '@mui/material'
import React, { useContext } from 'react'

const Swipe = ({ children, ...props }) => {
  const { [types.mobileMenu.openMenuMobile]: openSwip, toggleOpenSwipMobile } = useContext(MobileContext)
  return (
    <SwipeableDrawer className="tablet:hidden" sx={{ zIndex: 1601 }} cmptype={cmp.drawer} open={openSwip} onClose={() => toggleOpenSwipMobile(false)} onOpen={() => toggleOpenSwipMobile(true)}>
      {children}
    </SwipeableDrawer>
  )
}

export default Swipe
